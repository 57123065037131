import { DEFAULT_COLOR_PARAMS } from '../helper';
import Background from './Background';

export default class MobileBackround extends Background {
  constructor(element, palette) {
    super(element, palette);
    this.baseProperty = 'mobile.background';
  }

  getColorProperty() {
    return this.getDesktopPropertyIfMobileTypeIsNull(DEFAULT_COLOR_PARAMS.COLOR);
  }

  getColor2Property() {
    return this.getDesktopPropertyIfMobileTypeIsNull(DEFAULT_COLOR_PARAMS.COLOR2);
  }

  getColoringTypeProperty() {
    return this.getDesktopPropertyIfMobileTypeIsNull(DEFAULT_COLOR_PARAMS.TYPE);
  }

  getLinearDirectionProperty() {
    return this.getDesktopPropertyIfMobileTypeIsNull(DEFAULT_COLOR_PARAMS.LINEAR_DIRECTION);
  }

  getImageIdProperty() {
    return this.getDesktopPropertyIfMobileTypeIsNull(DEFAULT_COLOR_PARAMS.IMAGE_ID);
  }

  getImageUrlProperty() {
    return this.getDesktopPropertyIfMobileTypeIsNull(DEFAULT_COLOR_PARAMS.IMAGE_URL);
  }

  getImagePositionProperty() {
    return this.getDesktopPropertyIfMobileTypeIsNull(DEFAULT_COLOR_PARAMS.IMAGE_POSITION);
  }

  getImageRepeatProperty() {
    return this.getDesktopPropertyIfMobileTypeIsNull(DEFAULT_COLOR_PARAMS.IMAGE_REPEAT);
  }

  getImageAlignProperty() {
    return this.getDesktopPropertyIfMobileTypeIsNull(DEFAULT_COLOR_PARAMS.IMAGE_ALIGN);
  }

  getImageHorizontalAlignProperty() {
    return this.getDesktopPropertyIfMobileTypeIsNull(DEFAULT_COLOR_PARAMS.IMAGE_HORIZONTAL_ALIGN);
  }

  getImageVerticalAlignProperty() {
    return this.getDesktopPropertyIfMobileTypeIsNull(DEFAULT_COLOR_PARAMS.IMAGE_VERTICAL_ALIGN);
  }

  setActiveGradientProperty(property) {
    this.activeGradientProperty = `${this.baseProperty}.${property}`;
  }

  setColoringType(type) {
    if (this.isUniqueMobileSettings() === false) {
      this.switchToUniqueMobileSettings();
    }
    super.setColoringType(type);
  }

  handleColorChange(value) {
    if (this.isUniqueMobileSettings() === false) {
      this.switchToUniqueMobileSettings();
    }
    super.handleColorChange(value);
  }

  setImage(imageId, url) {
    if (this.isUniqueMobileSettings() === false) {
      this.switchToUniqueMobileSettings();
    }
    super.setImage(imageId, url);
  }

  setLinearDirection(value) {
    if (this.isUniqueMobileSettings() === false) {
      this.switchToUniqueMobileSettings();
    }
    super.setLinearDirection(value);
  }

  setImagePosition(value) {
    if (this.isUniqueMobileSettings() === false) {
      this.switchToUniqueMobileSettings();
    }
    super.setImagePosition(value);
  }

  setImageRepeat(value) {
    if (this.isUniqueMobileSettings() === false) {
      this.switchToUniqueMobileSettings();
    }
    super.setImageRepeat(value);
  }

  setImageHorizontalAlign(value) {
    if (this.isUniqueMobileSettings() === false) {
      this.switchToUniqueMobileSettings();
    }
    super.setImageHorizontalAlign(value);
  }

  setImageVerticalAlign(value) {
    if (this.isUniqueMobileSettings() === false) {
      this.switchToUniqueMobileSettings();
    }
    super.setImageVerticalAlign(value);
  }

  switchToUniqueMobileSettings() {
    const desktopProperties = this.getBackgroundProperties();

    // copy desktop bg type to mobile
    const property = `${this.baseProperty}.${DEFAULT_COLOR_PARAMS.TYPE}`;
    this.setElementValue(property, this.getDesktopBackgroundTypeValue());

    const mobileProperties = this.getBackgroundProperties();

    // copy desktop settings to mobile
    for (const [index, desktopProperty] of desktopProperties.entries()) {
      const mobileProperty = mobileProperties[index];
      this.setElementValue(mobileProperty, this.getValueByProperty(desktopProperty));
    }
  }

  getDesktopBackgroundTypeValue() {
    return this.getValueByProperty(`${this.getDesktopBaseProperty()}.${DEFAULT_COLOR_PARAMS.TYPE}`);
  }

  getDesktopPropertyIfMobileTypeIsNull(param) {
    if (this.isUniqueMobileSettings()) {
      return `${this.baseProperty}.${param}`;
    }
    return `${this.getDesktopBaseProperty()}.${param}`;
  }

  isUniqueMobileSettings() {
    const mobileTypeProperty = `${this.baseProperty}.${DEFAULT_COLOR_PARAMS.TYPE}`;
    const mobileTypeValue = this.getValueByProperty(mobileTypeProperty);
    return !!mobileTypeValue;
  }
}
