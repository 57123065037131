import OmComponent from './OmComponent';
import { DesktopBackground } from '../coloring';

export default class OmScratchCard extends OmComponent {
  constructor(elements, property, palette) {
    super(elements, palette);

    switch (property) {
      case 'desktop.background.color':
      case 'desktop.background.color2':
        return this.createColorInstance(DesktopBackground);

      default:
        throw new Error(`OmScratchCard: unknown property ${property}`);
    }
  }
}
