<template lang="pug">
popper.ds-tooltip(
  trigger="hover"
  :options="{ placement }"
  :class="[classes, `ds-tooltip-${placement}`]"
  :visible-arrow="arrow"
  :force-show="forceShow"
  @created="popoverInstance = $event"
  :delay-on-mouse-over="delay"
)
  .popper(:style="`width: ${width}`")
    slot
  span.reference(slot="reference")
    slot(name="trigger")
      component.ds-tooltip-icon(
        :is="iconComponent"
        :icon="icon"
        :size="iconSize"
        :style="{ fill: color }"
      )
</template>

<script>
  import { UilInfoCircle, UilQuestionCircle } from '@iconscout/vue-unicons';
  import Popper from 'vue-popperjs';
  import iconComponent from '@/components/Elements/mixins/iconComponent';
  import designSystemMixin from '../mixins/designSystem';

  export default {
    components: {
      UilInfoCircle,
      UilQuestionCircle,
      Popper,
    },
    mixins: [iconComponent, designSystemMixin],
    props: {
      icon: {
        type: String,
        default: 'info-circle',
      },
      width: {
        type: String,
        default: 'auto',
      },
      iconSize: {
        type: String,
        default: '1.25rem',
      },
      color: {
        type: String,
        default: '#B9BEC6',
      },
      theme: {
        type: String,
        default: 'dark',
        options: ['dark', 'light'],
        validator: (value) => {
          return ['dark', 'light'].includes(value.toLowerCase());
        },
      },
      small: {
        type: Boolean,
        default: false,
      },
      large: {
        type: Boolean,
        default: false,
      },
      arrow: {
        type: Boolean,
        default: true,
      },
      forceShow: {
        type: Boolean,
        default: false,
      },
      delay: {
        type: Number,
        default: 0,
      },
      placement: {
        type: String,
        default: 'top',
        options: [
          'auto',
          'top',
          'right',
          'bottom',
          'left',
          'top-end',
          'top-start',
          'right-end',
          'right-start',
          'bottom-end',
          'bottom-start',
          'left-end',
          'left-start',
        ],
        validator: (value) => {
          return [
            'auto',
            'top',
            'right',
            'bottom',
            'left',
            'top-end',
            'top-start',
            'right-end',
            'right-start',
            'bottom-end',
            'bottom-start',
            'left-end',
            'left-start',
          ].includes(value.toLowerCase());
        },
      },
    },
    data() {
      return {
        popoverInstance: null,
      };
    },
    computed: {
      classes() {
        return {
          'ds-tooltip-dark': this.theme === 'dark',
          'ds-tooltip-light': this.theme === 'light',
          'ds-tooltip-small': this.small,
          'ds-tooltip-large': this.large,
          'ds-tooltip-without-arrow': !this.arrow,
          'ds-tooltip-force-show': this.forceShow,
          'design-system': this.designSystem,
        };
      },
    },
  };
</script>
<style lang="sass">
  @import 'tooltip.sass'
</style>
