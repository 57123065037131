const tags = require('./tags');

const DATA_ATTRIBUTES = {
  TAG: 'data-smart-tag',
  TAG_ID: 'data-smart-tag-id',
};

const createSmartTagOptions = () => {
  const options = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const [attributeName, attribute] of Object.entries(tags)) {
    options.push({
      key: attributeName.toLowerCase(),
      value: attribute,
      rawValue: attribute.slice(2, attribute.length - 2),
    });
  }
  return options;
};

const getTagKeyByValue = (value) => {
  return Object.keys(tags)
    .find((key) => tags[key] === value)
    .toLowerCase();
};

module.exports = {
  DATA_ATTRIBUTES,
  createSmartTagOptions,
  getTagKeyByValue,
};
