const LOCALE_STORAGE_KEY = 'om-design-system';

const applyDesignSystemOutline = () => {
  const isDesignSystemOn = localStorage[LOCALE_STORAGE_KEY] === 'true';
  const outlineStyle = isDesignSystemOn ? '4px outset magenta' : '';
  const designSystemElements = document.querySelectorAll('.design-system');
  for (let i = 0; i < designSystemElements.length; i++) {
    designSystemElements[i].style.outline = outlineStyle;
  }
};

const designSystemOn = () => {
  localStorage[LOCALE_STORAGE_KEY] = true;
  applyDesignSystemOutline();
};

const designSystemOff = () => {
  localStorage[LOCALE_STORAGE_KEY] = false;
  applyDesignSystemOutline();
};

const applyDesignSystemFunctionsToWindow = () => {
  window.designSystemOn = designSystemOn;
  window.designSystemOff = designSystemOff;
};

export { applyDesignSystemOutline, applyDesignSystemFunctionsToWindow };
