import { get as _get, setWith as _setWith } from 'lodash-es';
import { COLORING_TYPES, DEVICES, DEFAULT_COLOR_PARAMS } from '../helper';

export default class Base {
  constructor(element, palette) {
    this.element = element;
    this.palette = palette;
    this.showTabs = false;
    this.baseProperty = null;
    this.colorParam = DEFAULT_COLOR_PARAMS.COLOR;
  }

  setBaseProperty(baseProperty) {
    this.baseProperty = baseProperty;
    return this;
  }

  setColorParam(colorParam) {
    this.colorParam = colorParam;
    return this;
  }

  getColor() {
    const property = this.getColorProperty();
    return this.getValueByProperty(property);
  }

  getColorStyle() {
    const colorProperty = this.getColorProperty();
    const color = this.getValueByProperty(colorProperty);
    return `background-color: ${this.convertColorStyleValue(color)}`;
  }

  getColoringType() {
    return this.getDefaultColoringType();
  }

  getColorProperty() {
    return `${this.baseProperty}.${this.colorParam}`;
  }

  getDefaultColoringType() {
    return COLORING_TYPES.SOLID_FILL;
  }

  getValues() {
    const colorProperty = this.getColorProperty();
    return [
      {
        uid: this.element.uid,
        prop: colorProperty,
        value: this.getValueByProperty(colorProperty),
      },
    ];
  }

  isGlobalStyle() {
    return false;
  }

  isQuillOmColor() {
    return false;
  }

  getDesktopBaseProperty() {
    return this.baseProperty.replace(DEVICES.MOBILE, DEVICES.DESKTOP);
  }

  getMobileBaseProperty() {
    return this.baseProperty.replace(DEVICES.DESKTOP, DEVICES.MOBILE);
  }

  getValueByProperty(property, def = '') {
    return _get(this.element, property, def);
  }

  getElementType() {
    return this.element.type || null;
  }

  convertColorStyleValue(color) {
    if (Number.isInteger(color)) {
      return this.palette[color];
    }
    return color;
  }

  handleColorChange(color) {
    this.setColor(color);
  }

  setColor(color) {
    const property = `${this.baseProperty}.${this.colorParam}`;
    this.setElementValue(property, color);
  }

  setElementValue(property, value) {
    _setWith(this.element, property, value);
  }
}
