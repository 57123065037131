import { isDefined } from '@/editor/util';

export function getUserLang(store) {
  if (store?.getters?.isLoggedIn?.()) {
    return store.getters.getLocale;
  }
}

export function getLangParam(route) {
  if (isDefined(route?.params?.lang)) {
    return route.params.lang === 'hu' ? 'hu' : 'en';
  }
}

export function getLangQueryParam(route) {
  if (isDefined(route?.query?.lang)) {
    return route.query.lang === 'hu' ? 'hu' : 'en';
  }
}

export function guessLanguage(navigator, route, store) {
  let userLang = (navigator.language || navigator.userLanguage)?.split('-')?.[0];
  userLang = userLang === 'hu' ? 'hu' : 'en';
  return getUserLang(store) || getLangParam(route) || getLangQueryParam(route) || userLang;
}
