// TODO REFACTOR: extract this into a library
const countries = {
  hu: [
    {
      value: 1,
      text: 'Afganisztán',
      code: 'AF',
      euVatNumber: false,
    },
    {
      value: 2,
      text: 'Albánia',
      code: 'AL',
      euVatNumber: false,
    },
    {
      value: 3,
      text: 'Algéria',
      code: 'DZ',
      euVatNumber: false,
    },
    {
      value: 232,
      text: 'Amerika Virgin-szigetek',
      code: 'VI',
      euVatNumber: false,
    },
    {
      value: 223,
      text: 'Amerikai Egyesült Államok',
      code: 'US',
      euVatNumber: false,
    },
    {
      value: 224,
      text: 'Amerikai Egyesült Államok külszigetei',
      code: 'UM',
      euVatNumber: false,
    },
    {
      value: 4,
      text: 'Amerikai Szamoa',
      code: 'AS',
      euVatNumber: false,
    },
    {
      value: 5,
      text: 'Andorra',
      code: 'AD',
      euVatNumber: false,
    },
    {
      value: 6,
      text: 'Angola',
      code: 'AO',
      euVatNumber: false,
    },
    {
      value: 7,
      text: 'Anguilla',
      code: 'AI',
      euVatNumber: false,
    },
    {
      value: 8,
      text: 'Antarktika',
      code: 'AQ',
      euVatNumber: false,
    },
    {
      value: 9,
      text: 'Antigua és Barbuda',
      code: 'AG',
      euVatNumber: false,
    },
    {
      value: 10,
      text: 'Argentína',
      code: 'AR',
      euVatNumber: false,
    },
    {
      value: 12,
      text: 'Aruba',
      code: 'AW',
      euVatNumber: false,
    },
    {
      value: 13,
      text: 'Ausztrália',
      code: 'AU',
      euVatNumber: false,
    },
    {
      value: 14,
      text: 'Ausztria',
      code: 'AT',
      euVatNumber: true,
    },
    {
      value: 15,
      text: 'Azerbajdzsán',
      code: 'AZ',
      euVatNumber: false,
    },
    {
      value: 16,
      text: 'Bahama-szigetek',
      code: 'BS',
      euVatNumber: false,
    },
    {
      value: 17,
      text: 'Bahrein',
      code: 'BH',
      euVatNumber: false,
    },
    {
      value: 18,
      text: 'Banglades',
      code: 'BD',
      euVatNumber: false,
    },
    {
      value: 19,
      text: 'Barbados',
      code: 'BB',
      euVatNumber: false,
    },
    {
      value: 21,
      text: 'Belgium',
      code: 'BE',
      euVatNumber: true,
    },
    {
      value: 22,
      text: 'Belize',
      code: 'BZ',
      euVatNumber: false,
    },
    {
      value: 20,
      text: 'Beloruszia',
      code: 'BY',
      euVatNumber: false,
    },
    {
      value: 23,
      text: 'Benin',
      code: 'BJ',
      euVatNumber: false,
    },
    {
      value: 24,
      text: 'Bermuda',
      code: 'BM',
      euVatNumber: false,
    },
    {
      value: 91,
      text: 'Bissau-Guinea',
      code: 'GW',
      euVatNumber: false,
    },
    {
      value: 26,
      text: 'Bolívia',
      code: 'BO',
      euVatNumber: false,
    },
    {
      value: 27,
      text: 'Bosznia-Hercegovina',
      code: 'BA',
      euVatNumber: false,
    },
    {
      value: 28,
      text: 'Botswana',
      code: 'BW',
      euVatNumber: false,
    },
    {
      value: 29,
      text: 'Bouvet-sziget',
      code: 'BV',
      euVatNumber: false,
    },
    {
      value: 30,
      text: 'Brazília',
      code: 'BR',
      euVatNumber: false,
    },
    {
      value: 31,
      text: 'Brit Indiai-óceáni területek',
      code: 'IO',
      euVatNumber: false,
    },
    {
      value: 231,
      text: 'Brit Virgin-szigetek',
      code: 'VG',
      euVatNumber: false,
    },
    {
      value: 32,
      text: 'Brunei Szultanátus',
      code: 'BN',
      euVatNumber: false,
    },
    {
      value: 33,
      text: 'Bulgária',
      code: 'BG',
      euVatNumber: true,
    },
    {
      value: 34,
      text: 'Burkina Faso',
      code: 'BF',
      euVatNumber: false,
    },
    {
      value: 35,
      text: 'Burundi',
      code: 'BI',
      euVatNumber: false,
    },
    {
      value: 25,
      text: 'Bután',
      code: 'BT',
      euVatNumber: false,
    },
    {
      value: 39,
      text: 'Cape Verde',
      code: 'CV',
      euVatNumber: false,
    },
    {
      value: 43,
      text: 'Chile',
      code: 'CL',
      euVatNumber: false,
    },
    {
      value: 55,
      text: 'Ciprus',
      code: 'CY',
      euVatNumber: true,
    },
    {
      value: 48,
      text: 'Comoros',
      code: 'KM',
      euVatNumber: false,
    },
    {
      value: 50,
      text: 'Cook-szigetek',
      code: 'CK',
      euVatNumber: false,
    },
    {
      value: 51,
      text: 'Costa Rica',
      code: 'CR',
      euVatNumber: false,
    },
    {
      value: 42,
      text: 'Csád',
      code: 'TD',
      euVatNumber: false,
    },
    {
      value: 56,
      text: 'Cseh köztársaság',
      code: 'CZ',
      euVatNumber: true,
    },
    {
      value: 57,
      text: 'Dánia',
      code: 'DK',
      euVatNumber: true,
    },
    {
      value: 193,
      text: 'Dél Afrikai Köztársaság',
      code: 'ZA',
      euVatNumber: false,
    },
    {
      value: 194,
      text: 'Dél Georgia és Dél Sandwich Szigetek',
      code: 'GS',
      euVatNumber: false,
    },
    {
      value: 113,
      text: 'Dél-Koreai Köztársaság',
      code: 'KR',
      euVatNumber: false,
    },
    {
      value: 77,
      text: 'Déli Francia Területek',
      code: 'TF',
      euVatNumber: false,
    },
    {
      value: 59,
      text: 'Dominika',
      code: 'DM',
      euVatNumber: false,
    },
    {
      value: 60,
      text: 'Dominikai köztársaság',
      code: 'DO',
      euVatNumber: false,
    },
    {
      value: 58,
      text: 'Dzsibuti',
      code: 'DJ',
      euVatNumber: false,
    },
    {
      value: 62,
      text: 'Ecuador',
      code: 'EC',
      euVatNumber: false,
    },
    {
      value: 65,
      text: 'Egyenlítői Guinea',
      code: 'GQ',
      euVatNumber: false,
    },
    {
      value: 221,
      text: 'Egyesült Arab Emirátusok',
      code: 'AE',
      euVatNumber: false,
    },
    {
      value: 222,
      text: 'Egyesült Királyság',
      code: 'GB',
      euVatNumber: false,
    },
    {
      value: 242,
      text: 'Egyesült Királyság - Jersey',
      code: 'GJ',
      euVatNumber: false,
    },
    {
      value: 63,
      text: 'Egyiptom',
      code: 'EG',
      euVatNumber: false,
    },
    {
      value: 64,
      text: 'El Salvador',
      code: 'SV',
      euVatNumber: false,
    },
    {
      value: 52,
      text: 'Elefántcsontpart',
      code: 'CI',
      euVatNumber: false,
    },
    {
      value: 66,
      text: 'Eritrea',
      code: 'ER',
      euVatNumber: false,
    },
    {
      value: 112,
      text: 'Észak-Korea',
      code: 'KP',
      euVatNumber: false,
    },
    {
      value: 159,
      text: 'Észak-Mariana Szigetek',
      code: 'MP',
      euVatNumber: false,
    },
    {
      value: 67,
      text: 'Észtország',
      code: 'EE',
      euVatNumber: true,
    },
    {
      value: 68,
      text: 'Etiópia',
      code: 'ET',
      euVatNumber: false,
    },
    {
      value: 69,
      text: 'Falkland-szigetek',
      code: 'FK',
      euVatNumber: false,
    },
    {
      value: 70,
      text: 'Faroe-szigetek',
      code: 'FO',
      euVatNumber: false,
    },
    {
      value: 71,
      text: 'Fiji',
      code: 'FJ',
      euVatNumber: false,
    },
    {
      value: 72,
      text: 'Finnország',
      code: 'FI',
      euVatNumber: true,
    },
    {
      value: 75,
      text: 'Francia Guyana',
      code: 'GF',
      euVatNumber: false,
    },
    {
      value: 74,
      text: 'Francia Nemzetközösség',
      code: 'FX',
      euVatNumber: false,
    },
    {
      value: 76,
      text: 'Francia Polinézia',
      code: 'PF',
      euVatNumber: false,
    },
    {
      value: 73,
      text: 'Franciaország',
      code: 'FR',
      euVatNumber: true,
    },
    {
      value: 168,
      text: 'Fülöp-szigetek',
      code: 'PH',
      euVatNumber: false,
    },
    {
      value: 78,
      text: 'Gabon',
      code: 'GA',
      euVatNumber: false,
    },
    {
      value: 79,
      text: 'Gambia',
      code: 'GM',
      euVatNumber: false,
    },
    {
      value: 82,
      text: 'Ghana',
      code: 'GH',
      euVatNumber: false,
    },
    {
      value: 83,
      text: 'Gibraltár',
      code: 'GI',
      euVatNumber: false,
    },
    {
      value: 84,
      text: 'Görögország',
      code: 'GR',
      euVatNumber: true,
    },
    {
      value: 86,
      text: 'Grenada',
      code: 'GD',
      euVatNumber: false,
    },
    {
      value: 85,
      text: 'Grönland',
      code: 'GL',
      euVatNumber: false,
    },
    {
      value: 80,
      text: 'Grúzia',
      code: 'GE',
      euVatNumber: false,
    },
    {
      value: 87,
      text: 'Guadeloupe-szigetek',
      code: 'GP',
      euVatNumber: false,
    },
    {
      value: 88,
      text: 'Guam',
      code: 'GU',
      euVatNumber: false,
    },
    {
      value: 89,
      text: 'Guatemala',
      code: 'GT',
      euVatNumber: false,
    },
    {
      value: 90,
      text: 'Guinea',
      code: 'GN',
      euVatNumber: false,
    },
    {
      value: 92,
      text: 'Guyana',
      code: 'GY',
      euVatNumber: false,
    },
    {
      value: 93,
      text: 'Haiti',
      code: 'HT',
      euVatNumber: false,
    },
    {
      value: 94,
      text: 'Heard és McDonald szigetek',
      code: 'HM',
      euVatNumber: false,
    },
    {
      value: 151,
      text: 'Holland Antillák',
      code: 'AN',
      euVatNumber: false,
    },
    {
      value: 150,
      text: 'Hollandia',
      code: 'NL',
      euVatNumber: true,
    },
    {
      value: 95,
      text: 'Honduras',
      code: 'HN',
      euVatNumber: false,
    },
    {
      value: 96,
      text: 'Hong Kong',
      code: 'HK',
      euVatNumber: false,
    },
    {
      value: 53,
      text: 'Horvátország',
      code: 'HR',
      euVatNumber: true,
    },
    {
      value: 99,
      text: 'India',
      code: 'IN',
      euVatNumber: false,
    },
    {
      value: 100,
      text: 'Indonézia',
      code: 'ID',
      euVatNumber: false,
    },
    {
      value: 102,
      text: 'Irak',
      code: 'IQ',
      euVatNumber: false,
    },
    {
      value: 101,
      text: 'Irán',
      code: 'IR',
      euVatNumber: false,
    },
    {
      value: 103,
      text: 'Írország',
      code: 'IE',
      euVatNumber: true,
    },
    {
      value: 98,
      text: 'Izland',
      code: 'IS',
      euVatNumber: false,
    },
    {
      value: 104,
      text: 'Izrael',
      code: 'IL',
      euVatNumber: false,
    },
    {
      value: 106,
      text: 'Jamaika',
      code: 'JM',
      euVatNumber: false,
    },
    {
      value: 107,
      text: 'Japán',
      code: 'JP',
      euVatNumber: false,
    },
    {
      value: 235,
      text: 'Jemen',
      code: 'YE',
      euVatNumber: false,
    },
    {
      value: 108,
      text: 'Jordánia',
      code: 'JO',
      euVatNumber: false,
    },
    {
      value: 40,
      text: 'Kajmán-szigetek',
      code: 'KY',
      euVatNumber: false,
    },
    {
      value: 36,
      text: 'Kambodzsa',
      code: 'KH',
      euVatNumber: false,
    },
    {
      value: 37,
      text: 'Kamerun',
      code: 'CM',
      euVatNumber: false,
    },
    {
      value: 38,
      text: 'Kanada',
      code: 'CA',
      euVatNumber: false,
    },
    {
      value: 45,
      text: 'Karácsony-szigetek',
      code: 'CX',
      euVatNumber: false,
    },
    {
      value: 173,
      text: 'Katar',
      code: 'QA',
      euVatNumber: false,
    },
    {
      value: 109,
      text: 'Kazahsztán',
      code: 'KZ',
      euVatNumber: false,
    },
    {
      value: 61,
      text: 'Kelet-Timor',
      code: 'TP',
      euVatNumber: false,
    },
    {
      value: 110,
      text: 'Kenya',
      code: 'KE',
      euVatNumber: false,
    },
    {
      value: 44,
      text: 'Kína',
      code: 'CN',
      euVatNumber: false,
    },
    {
      value: 115,
      text: 'Kirgizisztán',
      code: 'KG',
      euVatNumber: false,
    },
    {
      value: 111,
      text: 'Kiribati',
      code: 'KI',
      euVatNumber: false,
    },
    {
      value: 46,
      text: 'Kókusz-sziget',
      code: 'CC',
      euVatNumber: false,
    },
    {
      value: 47,
      text: 'Kolumbia',
      code: 'CO',
      euVatNumber: false,
    },
    {
      value: 49,
      text: 'Kongó',
      code: 'CG',
      euVatNumber: false,
    },
    {
      value: 240,
      text: 'Koszovó',
      code: 'KO',
      euVatNumber: false,
    },
    {
      value: 41,
      text: 'Közép-Afrikai Köztársaság',
      code: 'CF',
      euVatNumber: false,
    },
    {
      value: 54,
      text: 'Kuba',
      code: 'CU',
      euVatNumber: false,
    },
    {
      value: 114,
      text: 'Kuwait',
      code: 'KW',
      euVatNumber: false,
    },
    {
      value: 116,
      text: 'Laosz',
      code: 'LA',
      euVatNumber: false,
    },
    {
      value: 170,
      text: 'Lengyelország',
      code: 'PL',
      euVatNumber: true,
    },
    {
      value: 119,
      text: 'Lesotho',
      code: 'LS',
      euVatNumber: false,
    },
    {
      value: 117,
      text: 'Lettország',
      code: 'LV',
      euVatNumber: true,
    },
    {
      value: 118,
      text: 'Libanon',
      code: 'LB',
      euVatNumber: false,
    },
    {
      value: 120,
      text: 'Libéra',
      code: 'LR',
      euVatNumber: false,
    },
    {
      value: 121,
      text: 'Líbia',
      code: 'LY',
      euVatNumber: false,
    },
    {
      value: 122,
      text: 'Liechtenstein',
      code: 'LI',
      euVatNumber: false,
    },
    {
      value: 123,
      text: 'Litvánia',
      code: 'LT',
      euVatNumber: true,
    },
    {
      value: 124,
      text: 'Luxemburg',
      code: 'LU',
      euVatNumber: true,
    },
    {
      value: 127,
      text: 'Madagaszkár',
      code: 'MG',
      euVatNumber: false,
    },
    {
      value: 97,
      text: 'Magyarország',
      code: 'HU',
      euVatNumber: false,
    },
    {
      value: 125,
      text: 'Makaó',
      code: 'MO',
      euVatNumber: false,
    },
    {
      value: 126,
      text: 'Makedónia',
      code: 'MK',
      euVatNumber: false,
    },
    {
      value: 129,
      text: 'Malajzia',
      code: 'MY',
      euVatNumber: false,
    },
    {
      value: 128,
      text: 'Malawi',
      code: 'MW',
      euVatNumber: false,
    },
    {
      value: 130,
      text: 'Maldív-szigetek',
      code: 'MV',
      euVatNumber: false,
    },
    {
      value: 131,
      text: 'Mali',
      code: 'ML',
      euVatNumber: false,
    },
    {
      value: 132,
      text: 'Málta',
      code: 'MT',
      euVatNumber: true,
    },
    {
      value: 144,
      text: 'Marokkó',
      code: 'MA',
      euVatNumber: false,
    },
    {
      value: 133,
      text: 'Marshall-szigetek',
      code: 'MH',
      euVatNumber: false,
    },
    {
      value: 134,
      text: 'Martinique',
      code: 'MQ',
      euVatNumber: false,
    },
    {
      value: 135,
      text: 'Mauritánia',
      code: 'MR',
      euVatNumber: false,
    },
    {
      value: 136,
      text: 'Mauritius',
      code: 'MU',
      euVatNumber: false,
    },
    {
      value: 137,
      text: 'Mayotte',
      code: 'YT',
      euVatNumber: false,
    },
    {
      value: 138,
      text: 'Mexikó',
      code: 'MX',
      euVatNumber: false,
    },
    {
      value: 146,
      text: 'Mianmar',
      code: 'MM',
      euVatNumber: false,
    },
    {
      value: 139,
      text: 'Mikronézia',
      code: 'FM',
      euVatNumber: false,
    },
    {
      value: 140,
      text: 'Moldova',
      code: 'MD',
      euVatNumber: false,
    },
    {
      value: 141,
      text: 'Monakó',
      code: 'MC',
      euVatNumber: false,
    },
    {
      value: 142,
      text: 'Mongólia',
      code: 'MN',
      euVatNumber: false,
    },
    {
      value: 241,
      text: 'Montenegró',
      code: 'ME',
      euVatNumber: false,
    },
    {
      value: 143,
      text: 'Montserrat',
      code: 'MS',
      euVatNumber: false,
    },
    {
      value: 145,
      text: 'Mozambik',
      code: 'MZ',
      euVatNumber: false,
    },
    {
      value: 147,
      text: 'Namíbia',
      code: 'NA',
      euVatNumber: false,
    },
    {
      value: 148,
      text: 'Nauru',
      code: 'NR',
      euVatNumber: false,
    },
    {
      value: 81,
      text: 'Németország',
      code: 'DE',
      euVatNumber: true,
    },
    {
      value: 149,
      text: 'Nepál',
      code: 'NP',
      euVatNumber: false,
    },
    {
      value: 154,
      text: 'Nicaragua',
      code: 'NI',
      euVatNumber: false,
    },
    {
      value: 155,
      text: 'Niger',
      code: 'NE',
      euVatNumber: false,
    },
    {
      value: 156,
      text: 'Nigéria',
      code: 'NG',
      euVatNumber: false,
    },
    {
      value: 157,
      text: 'Niue',
      code: 'NU',
      euVatNumber: false,
    },
    {
      value: 158,
      text: 'Norfolk-szigetek',
      code: 'NF',
      euVatNumber: false,
    },
    {
      value: 160,
      text: 'Norvégia',
      code: 'NO',
      euVatNumber: false,
    },
    {
      value: 234,
      text: 'Nyugat-Szahara',
      code: 'EH',
      euVatNumber: false,
    },
    {
      value: 105,
      text: 'Olaszország',
      code: 'IT',
      euVatNumber: true,
    },
    {
      value: 161,
      text: 'Omán',
      code: 'OM',
      euVatNumber: false,
    },
    {
      value: 11,
      text: 'Örményország',
      code: 'AM',
      euVatNumber: false,
    },
    {
      value: 176,
      text: 'Orosz Föderáció',
      code: 'RU',
      euVatNumber: false,
    },
    {
      value: 162,
      text: 'Pakisztán',
      code: 'PK',
      euVatNumber: false,
    },
    {
      value: 163,
      text: 'Palau',
      code: 'PW',
      euVatNumber: false,
    },
    {
      value: 164,
      text: 'Panama',
      code: 'PA',
      euVatNumber: false,
    },
    {
      value: 165,
      text: 'Pápua Új-Guinea',
      code: 'PG',
      euVatNumber: false,
    },
    {
      value: 166,
      text: 'Paraguay',
      code: 'PY',
      euVatNumber: false,
    },
    {
      value: 167,
      text: 'Peru',
      code: 'PE',
      euVatNumber: false,
    },
    {
      value: 169,
      text: 'Pitcairn-szigetek',
      code: 'PN',
      euVatNumber: false,
    },
    {
      value: 171,
      text: 'Portugália',
      code: 'PT',
      euVatNumber: true,
    },
    {
      value: 172,
      text: 'Puerto Rico',
      code: 'PR',
      euVatNumber: false,
    },
    {
      value: 174,
      text: 'Réunion-sziget',
      code: 'RE',
      euVatNumber: false,
    },
    {
      value: 175,
      text: 'Románia',
      code: 'RO',
      euVatNumber: true,
    },
    {
      value: 177,
      text: 'Ruanda',
      code: 'RW',
      euVatNumber: false,
    },
    {
      value: 178,
      text: 'Saint Kitts és Nevis',
      code: 'KN',
      euVatNumber: false,
    },
    {
      value: 179,
      text: 'Saint Lucia',
      code: 'LC',
      euVatNumber: false,
    },
    {
      value: 180,
      text: 'Saint Vincent és a Grenadine Szigetek',
      code: 'VC',
      euVatNumber: false,
    },
    {
      value: 191,
      text: 'Salamon-szigetek',
      code: 'SB',
      euVatNumber: false,
    },
    {
      value: 182,
      text: 'San Marino',
      code: 'SM',
      euVatNumber: false,
    },
    {
      value: 183,
      text: 'Sao Tome és Principe',
      code: 'ST',
      euVatNumber: false,
    },
    {
      value: 186,
      text: 'Seychelle-szigetek',
      code: 'SC',
      euVatNumber: false,
    },
    {
      value: 187,
      text: 'Sierra Leone',
      code: 'SL',
      euVatNumber: false,
    },
    {
      value: 195,
      text: 'Spanyolország',
      code: 'ES',
      euVatNumber: true,
    },
    {
      value: 196,
      text: 'Sri Lanka',
      code: 'LK',
      euVatNumber: false,
    },
    {
      value: 200,
      text: 'Surinam',
      code: 'SR',
      euVatNumber: false,
    },
    {
      value: 204,
      text: 'Svájc',
      code: 'CH',
      euVatNumber: false,
    },
    {
      value: 201,
      text: 'Svalbard és Jan Mayen Szigetek',
      code: 'SJ',
      euVatNumber: false,
    },
    {
      value: 203,
      text: 'Svédország',
      code: 'SE',
      euVatNumber: true,
    },
    {
      value: 181,
      text: 'Szamoa',
      code: 'WS',
      euVatNumber: false,
    },
    {
      value: 184,
      text: 'Szaud-Arábia',
      code: 'SA',
      euVatNumber: false,
    },
    {
      value: 185,
      text: 'Szenegál',
      code: 'SN',
      euVatNumber: false,
    },
    {
      value: 197,
      text: 'Szent Heléna',
      code: 'SH',
      euVatNumber: false,
    },
    {
      value: 236,
      text: 'Szerbia',
      code: 'RS',
      euVatNumber: false,
    },
    {
      value: 188,
      text: 'Szingapúr',
      code: 'SG',
      euVatNumber: false,
    },
    {
      value: 205,
      text: 'Szíria',
      code: 'SY',
      euVatNumber: false,
    },
    {
      value: 189,
      text: 'Szlovák köztársaság',
      code: 'SK',
      euVatNumber: true,
    },
    {
      value: 190,
      text: 'Szlovénia',
      code: 'SI',
      euVatNumber: true,
    },
    {
      value: 192,
      text: 'Szomália',
      code: 'SO',
      euVatNumber: false,
    },
    {
      value: 198,
      text: 'Szt. Pierre és Miquelon Szigetek',
      code: 'PM',
      euVatNumber: false,
    },
    {
      value: 199,
      text: 'Szudán',
      code: 'SD',
      euVatNumber: false,
    },
    {
      value: 202,
      text: 'Szváziföld',
      code: 'SZ',
      euVatNumber: false,
    },
    {
      value: 207,
      text: 'Tadzsikisztán',
      code: 'TJ',
      euVatNumber: false,
    },
    {
      value: 206,
      text: 'Tajvan',
      code: 'TW',
      euVatNumber: false,
    },
    {
      value: 208,
      text: 'Tanzánia',
      code: 'TZ',
      euVatNumber: false,
    },
    {
      value: 209,
      text: 'Thaiföld',
      code: 'TH',
      euVatNumber: false,
    },
    {
      value: 210,
      text: 'Togo',
      code: 'TG',
      euVatNumber: false,
    },
    {
      value: 211,
      text: 'Tokelau',
      code: 'TK',
      euVatNumber: false,
    },
    {
      value: 212,
      text: 'Tonga',
      code: 'TO',
      euVatNumber: false,
    },
    {
      value: 215,
      text: 'Törökország',
      code: 'TR',
      euVatNumber: false,
    },
    {
      value: 213,
      text: 'Trinidad és Tobago',
      code: 'TT',
      euVatNumber: false,
    },
    {
      value: 214,
      text: 'Tunézia',
      code: 'TN',
      euVatNumber: false,
    },
    {
      value: 216,
      text: 'Türkmenisztán',
      code: 'TM',
      euVatNumber: false,
    },
    {
      value: 217,
      text: 'Turks és Caicos Szigetek',
      code: 'TC',
      euVatNumber: false,
    },
    {
      value: 218,
      text: 'Tuvalu',
      code: 'TV',
      euVatNumber: false,
    },
    {
      value: 219,
      text: 'Uganda',
      code: 'UG',
      euVatNumber: false,
    },
    {
      value: 152,
      text: 'Új Kaledónia',
      code: 'NC',
      euVatNumber: false,
    },
    {
      value: 153,
      text: 'Új-Zéland',
      code: 'NZ',
      euVatNumber: false,
    },
    {
      value: 220,
      text: 'Ukrajna',
      code: 'UA',
      euVatNumber: false,
    },
    {
      value: 225,
      text: 'Uruguay',
      code: 'UY',
      euVatNumber: false,
    },
    {
      value: 226,
      text: 'Üzbégisztán',
      code: 'UZ',
      euVatNumber: false,
    },
    {
      value: 227,
      text: 'Vanuatu',
      code: 'VU',
      euVatNumber: false,
    },
    {
      value: 228,
      text: 'Vatikái városállam',
      code: 'VA',
      euVatNumber: false,
    },
    {
      value: 229,
      text: 'Venezuela',
      code: 'VE',
      euVatNumber: false,
    },
    {
      value: 230,
      text: 'Vietnám',
      code: 'VN',
      euVatNumber: false,
    },
    {
      value: 233,
      text: 'Wallis és Futuna Szigetek',
      code: 'WF',
      euVatNumber: false,
    },
    {
      value: 237,
      text: 'Zaire',
      code: 'ZR',
      euVatNumber: false,
    },
    {
      value: 238,
      text: 'Zambia',
      code: 'ZM',
      euVatNumber: false,
    },
    {
      value: 239,
      text: 'Zimbabwe',
      code: 'ZW',
      euVatNumber: false,
    },
    {
      value: 243,
      text: 'Kanári-szigetek',
      code: 'IC',
      euVatNumber: false,
    },
  ],
  en: [
    {
      value: 1,
      text: 'Afghanistan',
      code: 'AF',
      euVatNumber: false,
    },
    {
      value: 2,
      text: 'Albania',
      code: 'AL',
      euVatNumber: false,
    },
    {
      value: 3,
      text: 'Algeria',
      code: 'DZ',
      euVatNumber: false,
    },
    {
      value: 232,
      text: 'Virgin Islands (U.S.)',
      code: 'VI',
      euVatNumber: false,
    },
    {
      value: 223,
      text: 'United States',
      code: 'US',
      euVatNumber: false,
    },
    {
      value: 224,
      text: 'United States Minor Outlying Islands',
      code: 'UM',
      euVatNumber: false,
    },
    {
      value: 4,
      text: 'American Samoa',
      code: 'AS',
      euVatNumber: false,
    },
    {
      value: 5,
      text: 'Andorra',
      code: 'AD',
      euVatNumber: false,
    },
    {
      value: 6,
      text: 'Angola',
      code: 'AO',
      euVatNumber: false,
    },
    {
      value: 7,
      text: 'Anguilla',
      code: 'AI',
      euVatNumber: false,
    },
    {
      value: 8,
      text: 'Antarctica',
      code: 'AQ',
      euVatNumber: false,
    },
    {
      value: 9,
      text: 'Antigua and Barbuda',
      code: 'AG',
      euVatNumber: false,
    },
    {
      value: 10,
      text: 'Argentina',
      code: 'AR',
      euVatNumber: false,
    },
    {
      value: 12,
      text: 'Aruba',
      code: 'AW',
      euVatNumber: false,
    },
    {
      value: 13,
      text: 'Australia',
      code: 'AU',
      euVatNumber: false,
    },
    {
      value: 14,
      text: 'Austria',
      code: 'AT',
      euVatNumber: true,
    },
    {
      value: 15,
      text: 'Azerbaijan',
      code: 'AZ',
      euVatNumber: false,
    },
    {
      value: 16,
      text: 'Bahamas',
      code: 'BS',
      euVatNumber: false,
    },
    {
      value: 17,
      text: 'Bahrain',
      code: 'BH',
      euVatNumber: false,
    },
    {
      value: 18,
      text: 'Bangladesh',
      code: 'BD',
      euVatNumber: false,
    },
    {
      value: 19,
      text: 'Barbados',
      code: 'BB',
      euVatNumber: false,
    },
    {
      value: 21,
      text: 'Belgium',
      code: 'BE',
      euVatNumber: true,
    },
    {
      value: 22,
      text: 'Belize',
      code: 'BZ',
      euVatNumber: false,
    },
    {
      value: 20,
      text: 'Belarus',
      code: 'BY',
      euVatNumber: false,
    },
    {
      value: 23,
      text: 'Benin',
      code: 'BJ',
      euVatNumber: false,
    },
    {
      value: 24,
      text: 'Bermuda',
      code: 'BM',
      euVatNumber: false,
    },
    {
      value: 91,
      text: 'Guinea-bissau',
      code: 'GW',
      euVatNumber: false,
    },
    {
      value: 26,
      text: 'Bolívia',
      code: 'BO',
      euVatNumber: false,
    },
    {
      value: 27,
      text: 'Bosnia and Herzegowina',
      code: 'BA',
      euVatNumber: false,
    },
    {
      value: 28,
      text: 'Botswana',
      code: 'BW',
      euVatNumber: false,
    },
    {
      value: 29,
      text: 'Bouvet Island',
      code: 'BV',
      euVatNumber: false,
    },
    {
      value: 30,
      text: 'Brazil',
      code: 'BR',
      euVatNumber: false,
    },
    {
      value: 31,
      text: 'British Indian Ocean Territory',
      code: 'IO',
      euVatNumber: false,
    },
    {
      value: 231,
      text: 'Virgin Islands (British)',
      code: 'VG',
      euVatNumber: false,
    },
    {
      value: 32,
      text: 'Brunei Darussalam',
      code: 'BN',
      euVatNumber: false,
    },
    {
      value: 33,
      text: 'Bulgaria',
      code: 'BG',
      euVatNumber: true,
    },
    {
      value: 34,
      text: 'Burkina Faso',
      code: 'BF',
      euVatNumber: false,
    },
    {
      value: 35,
      text: 'Burundi',
      code: 'BI',
      euVatNumber: false,
    },
    {
      value: 25,
      text: 'Bhutan',
      code: 'BT',
      euVatNumber: false,
    },
    {
      value: 39,
      text: 'Cape Verde',
      code: 'CV',
      euVatNumber: false,
    },
    {
      value: 43,
      text: 'Chile',
      code: 'CL',
      euVatNumber: false,
    },
    {
      value: 55,
      text: 'Cyprus',
      code: 'CY',
      euVatNumber: true,
    },
    {
      value: 48,
      text: 'Comoros',
      code: 'KM',
      euVatNumber: false,
    },
    {
      value: 50,
      text: 'Cook Islands',
      code: 'CK',
      euVatNumber: false,
    },
    {
      value: 51,
      text: 'Costa Rica',
      code: 'CR',
      euVatNumber: false,
    },
    {
      value: 42,
      text: 'Chad',
      code: 'TD',
      euVatNumber: false,
    },
    {
      value: 56,
      text: 'Czech Republic',
      code: 'CZ',
      euVatNumber: true,
    },
    {
      value: 57,
      text: 'Denmark',
      code: 'DK',
      euVatNumber: true,
    },
    {
      value: 193,
      text: 'South Africa',
      code: 'ZA',
      euVatNumber: false,
    },
    {
      value: 194,
      text: 'South Georgia &amp; South Sandwich Islands',
      code: 'GS',
      euVatNumber: false,
    },
    {
      value: 113,
      text: 'Korea, Republic of',
      code: 'KR',
      euVatNumber: false,
    },
    {
      value: 77,
      text: 'French Southern Territories',
      code: 'TF',
      euVatNumber: false,
    },
    {
      value: 59,
      text: 'Dominica',
      code: 'DM',
      euVatNumber: false,
    },
    {
      value: 60,
      text: 'Dominican Republic',
      code: 'DO',
      euVatNumber: false,
    },
    {
      value: 58,
      text: 'Djibouti',
      code: 'DJ',
      euVatNumber: false,
    },
    {
      value: 62,
      text: 'Ecuador',
      code: 'EC',
      euVatNumber: false,
    },
    {
      value: 65,
      text: 'Equatorial Guinea',
      code: 'GQ',
      euVatNumber: false,
    },
    {
      value: 221,
      text: 'United Arab Emirates',
      code: 'AE',
      euVatNumber: false,
    },
    {
      value: 222,
      text: 'United Kingdom',
      code: 'GB',
      euVatNumber: false,
    },
    {
      value: 242,
      text: 'United Kingdom - Jersey',
      code: 'GJ',
      euVatNumber: false,
    },
    {
      value: 63,
      text: 'Egypt',
      code: 'EG',
      euVatNumber: false,
    },
    {
      value: 64,
      text: 'El Salvador',
      code: 'SV',
      euVatNumber: false,
    },
    {
      value: 52,
      text: "Cote D''Ivoire",
      code: 'CI',
      euVatNumber: false,
    },
    {
      value: 66,
      text: 'Eritrea',
      code: 'ER',
      euVatNumber: false,
    },
    {
      value: 112,
      text: 'North Korea',
      code: 'KP',
      euVatNumber: false,
    },
    {
      value: 159,
      text: 'Northern Mariana Islands',
      code: 'MP',
      euVatNumber: false,
    },
    {
      value: 67,
      text: 'Estonia',
      code: 'EE',
      euVatNumber: true,
    },
    {
      value: 68,
      text: 'Ethiopia',
      code: 'ET',
      euVatNumber: false,
    },
    {
      value: 69,
      text: 'Falkland Islands (Malvinas)',
      code: 'FK',
      euVatNumber: false,
    },
    {
      value: 70,
      text: 'Faroe Islands',
      code: 'FO',
      euVatNumber: false,
    },
    {
      value: 71,
      text: 'Fiji',
      code: 'FJ',
      euVatNumber: false,
    },
    {
      value: 72,
      text: 'Finland',
      code: 'FI',
      euVatNumber: true,
    },
    {
      value: 75,
      text: 'French Guiana',
      code: 'GF',
      euVatNumber: false,
    },
    {
      value: 76,
      text: 'French Polynesia',
      code: 'PF',
      euVatNumber: false,
    },
    {
      value: 73,
      text: 'France',
      code: 'FR',
      euVatNumber: true,
    },
    {
      value: 168,
      text: 'Philippines-szigetek',
      code: 'PH',
      euVatNumber: false,
    },
    {
      value: 78,
      text: 'Gabon',
      code: 'GA',
      euVatNumber: false,
    },
    {
      value: 79,
      text: 'Gambia',
      code: 'GM',
      euVatNumber: false,
    },
    {
      value: 82,
      text: 'Ghana',
      code: 'GH',
      euVatNumber: false,
    },
    {
      value: 83,
      text: 'Gibraltar',
      code: 'GI',
      euVatNumber: false,
    },
    {
      value: 84,
      text: 'Greece',
      code: 'GR',
      euVatNumber: true,
    },
    {
      value: 86,
      text: 'Grenada',
      code: 'GD',
      euVatNumber: false,
    },
    {
      value: 85,
      text: 'Greenland',
      code: 'GL',
      euVatNumber: false,
    },
    {
      value: 80,
      text: 'Georgia',
      code: 'GE',
      euVatNumber: false,
    },
    {
      value: 87,
      text: 'Guadeloupe',
      code: 'GP',
      euVatNumber: false,
    },
    {
      value: 88,
      text: 'Guam',
      code: 'GU',
      euVatNumber: false,
    },
    {
      value: 89,
      text: 'Guatemala',
      code: 'GT',
      euVatNumber: false,
    },
    {
      value: 90,
      text: 'Guinea',
      code: 'GN',
      euVatNumber: false,
    },
    {
      value: 92,
      text: 'Guyana',
      code: 'GY',
      euVatNumber: false,
    },
    {
      value: 93,
      text: 'Haiti',
      code: 'HT',
      euVatNumber: false,
    },
    {
      value: 94,
      text: 'Heard and Mc Donald Islands',
      code: 'HM',
      euVatNumber: false,
    },
    {
      value: 151,
      text: 'Netherlands Antilles',
      code: 'AN',
      euVatNumber: false,
    },
    {
      value: 150,
      text: 'Netherlands',
      code: 'NL',
      euVatNumber: true,
    },
    {
      value: 95,
      text: 'Honduras',
      code: 'HN',
      euVatNumber: false,
    },
    {
      value: 96,
      text: 'Hong Kong',
      code: 'HK',
      euVatNumber: false,
    },
    {
      value: 53,
      text: 'Croatia',
      code: 'HR',
      euVatNumber: true,
    },
    {
      value: 99,
      text: 'India',
      code: 'IN',
      euVatNumber: false,
    },
    {
      value: 100,
      text: 'Indonesia',
      code: 'ID',
      euVatNumber: false,
    },
    {
      value: 102,
      text: 'Iraq',
      code: 'IQ',
      euVatNumber: false,
    },
    {
      value: 101,
      text: 'Iran (Islamic Republic of)',
      code: 'IR',
      euVatNumber: false,
    },
    {
      value: 103,
      text: 'Ireland',
      code: 'IE',
      euVatNumber: true,
    },
    {
      value: 98,
      text: 'Iceland',
      code: 'IS',
      euVatNumber: false,
    },
    {
      value: 104,
      text: 'Israel',
      code: 'IL',
      euVatNumber: false,
    },
    {
      value: 106,
      text: 'Jamaica',
      code: 'JM',
      euVatNumber: false,
    },
    {
      value: 107,
      text: 'Japan',
      code: 'JP',
      euVatNumber: false,
    },
    {
      value: 235,
      text: 'Yemen',
      code: 'YE',
      euVatNumber: false,
    },
    {
      value: 108,
      text: 'Jordan',
      code: 'JO',
      euVatNumber: false,
    },
    {
      value: 40,
      text: 'Cayman Islands',
      code: 'KY',
      euVatNumber: false,
    },
    {
      value: 36,
      text: 'Cambodia',
      code: 'KH',
      euVatNumber: false,
    },
    {
      value: 37,
      text: 'Cameroon',
      code: 'CM',
      euVatNumber: false,
    },
    {
      value: 38,
      text: 'Canada',
      code: 'CA',
      euVatNumber: false,
    },
    {
      value: 45,
      text: 'Christmas Island',
      code: 'CX',
      euVatNumber: false,
    },
    {
      value: 173,
      text: 'Qatar',
      code: 'QA',
      euVatNumber: false,
    },
    {
      value: 109,
      text: 'Kazakhstan',
      code: 'KZ',
      euVatNumber: false,
    },
    {
      value: 61,
      text: 'East Timor',
      code: 'TP',
      euVatNumber: false,
    },
    {
      value: 110,
      text: 'Kenya',
      code: 'KE',
      euVatNumber: false,
    },
    {
      value: 44,
      text: 'China',
      code: 'CN',
      euVatNumber: false,
    },
    {
      value: 115,
      text: 'Kyrgyzstan',
      code: 'KG',
      euVatNumber: false,
    },
    {
      value: 111,
      text: 'Kiribati',
      code: 'KI',
      euVatNumber: false,
    },
    {
      value: 46,
      text: 'Cocos (Keeling) Islands',
      code: 'CC',
      euVatNumber: false,
    },
    {
      value: 47,
      text: 'Colombia',
      code: 'CO',
      euVatNumber: false,
    },
    {
      value: 49,
      text: 'Congo',
      code: 'CG',
      euVatNumber: false,
    },
    {
      value: 240,
      text: 'Kosovo',
      code: 'KO',
      euVatNumber: false,
    },
    {
      value: 41,
      text: 'Central African Republic',
      code: 'CF',
      euVatNumber: false,
    },
    {
      value: 54,
      text: 'Cuba',
      code: 'CU',
      euVatNumber: false,
    },
    {
      value: 114,
      text: 'Kuwait',
      code: 'KW',
      euVatNumber: false,
    },
    {
      value: 116,
      text: "Lao People's Democratic Republic",
      code: 'LA',
      euVatNumber: false,
    },
    {
      value: 170,
      text: 'Poland',
      code: 'PL',
      euVatNumber: true,
    },
    {
      value: 119,
      text: 'Lesotho',
      code: 'LS',
      euVatNumber: false,
    },
    {
      value: 117,
      text: 'Latvia',
      code: 'LV',
      euVatNumber: true,
    },
    {
      value: 118,
      text: 'Lebanon',
      code: 'LB',
      euVatNumber: false,
    },
    {
      value: 120,
      text: 'Liberia',
      code: 'LR',
      euVatNumber: false,
    },
    {
      value: 121,
      text: 'Libyan Arab Jamahiriya',
      code: 'LY',
      euVatNumber: false,
    },
    {
      value: 122,
      text: 'Liechtenstein',
      code: 'LI',
      euVatNumber: false,
    },
    {
      value: 123,
      text: 'Lithuania',
      code: 'LT',
      euVatNumber: true,
    },
    {
      value: 124,
      text: 'Luxembourg',
      code: 'LU',
      euVatNumber: true,
    },
    {
      value: 127,
      text: 'Madagascar',
      code: 'MG',
      euVatNumber: false,
    },
    {
      value: 97,
      text: 'Hungary',
      code: 'HU',
      euVatNumber: false,
    },
    {
      value: 125,
      text: 'Macau',
      code: 'MO',
      euVatNumber: false,
    },
    {
      value: 126,
      text: 'Macedonia',
      code: 'MK',
      euVatNumber: false,
    },
    {
      value: 129,
      text: 'Malaysia',
      code: 'MY',
      euVatNumber: false,
    },
    {
      value: 128,
      text: 'Malawi',
      code: 'MW',
      euVatNumber: false,
    },
    {
      value: 130,
      text: 'Maldives',
      code: 'MV',
      euVatNumber: false,
    },
    {
      value: 131,
      text: 'Mali',
      code: 'ML',
      euVatNumber: false,
    },
    {
      value: 132,
      text: 'Malta',
      code: 'MT',
      euVatNumber: true,
    },
    {
      value: 144,
      text: 'Morocco',
      code: 'MA',
      euVatNumber: false,
    },
    {
      value: 133,
      text: 'Marshall Islands',
      code: 'MH',
      euVatNumber: false,
    },
    {
      value: 134,
      text: 'Martinique',
      code: 'MQ',
      euVatNumber: false,
    },
    {
      value: 135,
      text: 'Mauritania',
      code: 'MR',
      euVatNumber: false,
    },
    {
      value: 136,
      text: 'Mauritius',
      code: 'MU',
      euVatNumber: false,
    },
    {
      value: 137,
      text: 'Mayotte',
      code: 'YT',
      euVatNumber: false,
    },
    {
      value: 138,
      text: 'Mexico',
      code: 'MX',
      euVatNumber: false,
    },
    {
      value: 146,
      text: 'Myanmar',
      code: 'MM',
      euVatNumber: false,
    },
    {
      value: 139,
      text: 'Micronesia, Federated States of',
      code: 'FM',
      euVatNumber: false,
    },
    {
      value: 140,
      text: 'Moldova, Republic of',
      code: 'MD',
      euVatNumber: false,
    },
    {
      value: 141,
      text: 'Monaco',
      code: 'MC',
      euVatNumber: false,
    },
    {
      value: 142,
      text: 'Mongolia',
      code: 'MN',
      euVatNumber: false,
    },
    {
      value: 241,
      text: 'Montenegro',
      code: 'ME',
      euVatNumber: false,
    },
    {
      value: 143,
      text: 'Montserrat',
      code: 'MS',
      euVatNumber: false,
    },
    {
      value: 145,
      text: 'Mozambique',
      code: 'MZ',
      euVatNumber: false,
    },
    {
      value: 147,
      text: 'Namibia',
      code: 'NA',
      euVatNumber: false,
    },
    {
      value: 148,
      text: 'Nauru',
      code: 'NR',
      euVatNumber: false,
    },
    {
      value: 81,
      text: 'Germany',
      code: 'DE',
      euVatNumber: true,
    },
    {
      value: 149,
      text: 'Nepal',
      code: 'NP',
      euVatNumber: false,
    },
    {
      value: 154,
      text: 'Nicaragua',
      code: 'NI',
      euVatNumber: false,
    },
    {
      value: 155,
      text: 'Niger',
      code: 'NE',
      euVatNumber: false,
    },
    {
      value: 156,
      text: 'Nigeria',
      code: 'NG',
      euVatNumber: false,
    },
    {
      value: 157,
      text: 'Niue',
      code: 'NU',
      euVatNumber: false,
    },
    {
      value: 158,
      text: 'Norfolk Island',
      code: 'NF',
      euVatNumber: false,
    },
    {
      value: 160,
      text: 'Norway',
      code: 'NO',
      euVatNumber: false,
    },
    {
      value: 234,
      text: 'Western Sahara',
      code: 'EH',
      euVatNumber: false,
    },
    {
      value: 105,
      text: 'Italy',
      code: 'IT',
      euVatNumber: true,
    },
    {
      value: 161,
      text: 'Oman',
      code: 'OM',
      euVatNumber: false,
    },
    {
      value: 11,
      text: 'Armenia',
      code: 'AM',
      euVatNumber: false,
    },
    {
      value: 176,
      text: 'Russian Federation',
      code: 'RU',
      euVatNumber: false,
    },
    {
      value: 162,
      text: 'Pakistan',
      code: 'PK',
      euVatNumber: false,
    },
    {
      value: 163,
      text: 'Palau',
      code: 'PW',
      euVatNumber: false,
    },
    {
      value: 164,
      text: 'Panama',
      code: 'PA',
      euVatNumber: false,
    },
    {
      value: 165,
      text: 'Papua New Guinea',
      code: 'PG',
      euVatNumber: false,
    },
    {
      value: 166,
      text: 'Paraguay',
      code: 'PY',
      euVatNumber: false,
    },
    {
      value: 167,
      text: 'Peru',
      code: 'PE',
      euVatNumber: false,
    },
    {
      value: 169,
      text: 'Pitcairn',
      code: 'PN',
      euVatNumber: false,
    },
    {
      value: 171,
      text: 'Portugal',
      code: 'PT',
      euVatNumber: true,
    },
    {
      value: 172,
      text: 'Puerto Rico',
      code: 'PR',
      euVatNumber: false,
    },
    {
      value: 174,
      text: 'Reunion',
      code: 'RE',
      euVatNumber: false,
    },
    {
      value: 175,
      text: 'Romania',
      code: 'RO',
      euVatNumber: true,
    },
    {
      value: 177,
      text: 'Rwanda',
      code: 'RW',
      euVatNumber: false,
    },
    {
      value: 178,
      text: 'Saint Kitts és Nevis',
      code: 'KN',
      euVatNumber: false,
    },
    {
      value: 179,
      text: 'Saint Lucia',
      code: 'LC',
      euVatNumber: false,
    },
    {
      value: 180,
      text: 'Saint Vincent and the Grenadines',
      code: 'VC',
      euVatNumber: false,
    },
    {
      value: 191,
      text: 'Salamon-island',
      code: 'SB',
      euVatNumber: false,
    },
    {
      value: 182,
      text: 'San Marino',
      code: 'SM',
      euVatNumber: false,
    },
    {
      value: 183,
      text: 'Sao Tome and Principe',
      code: 'ST',
      euVatNumber: false,
    },
    {
      value: 186,
      text: 'Seychelles',
      code: 'SC',
      euVatNumber: false,
    },
    {
      value: 187,
      text: 'Sierra Leone',
      code: 'SL',
      euVatNumber: false,
    },
    {
      value: 195,
      text: 'Spain',
      code: 'ES',
      euVatNumber: true,
    },
    {
      value: 196,
      text: 'Sri Lanka',
      code: 'LK',
      euVatNumber: false,
    },
    {
      value: 200,
      text: 'Suriname',
      code: 'SR',
      euVatNumber: false,
    },
    {
      value: 204,
      text: 'Switzerland',
      code: 'CH',
      euVatNumber: false,
    },
    {
      value: 201,
      text: 'Svalbard and Jan Mayen Islands',
      code: 'SJ',
      euVatNumber: false,
    },
    {
      value: 203,
      text: 'Sweden',
      code: 'SE',
      euVatNumber: true,
    },
    {
      value: 181,
      text: 'Samoa',
      code: 'WS',
      euVatNumber: false,
    },
    {
      value: 184,
      text: 'Szaud-Arabia',
      code: 'SA',
      euVatNumber: false,
    },
    {
      value: 185,
      text: 'Senegal',
      code: 'SN',
      euVatNumber: false,
    },
    {
      value: 197,
      text: 'St. Helena',
      code: 'SH',
      euVatNumber: false,
    },
    {
      value: 236,
      text: 'Serbia',
      code: 'RS',
      euVatNumber: false,
    },
    {
      value: 188,
      text: 'Singapore',
      code: 'SG',
      euVatNumber: false,
    },
    {
      value: 205,
      text: 'Syrian Arab Republic',
      code: 'SY',
      euVatNumber: false,
    },
    {
      value: 189,
      text: 'Slovak Republic',
      code: 'SK',
      euVatNumber: true,
    },
    {
      value: 190,
      text: 'Slovenia',
      code: 'SI',
      euVatNumber: true,
    },
    {
      value: 192,
      text: 'Somalia',
      code: 'SO',
      euVatNumber: false,
    },
    {
      value: 198,
      text: 'St. Pierre and Miquelon',
      code: 'PM',
      euVatNumber: false,
    },
    {
      value: 199,
      text: 'Sudan',
      code: 'SD',
      euVatNumber: false,
    },
    {
      value: 202,
      text: 'Swaziland',
      code: 'SZ',
      euVatNumber: false,
    },
    {
      value: 207,
      text: 'Tajikistan',
      code: 'TJ',
      euVatNumber: false,
    },
    {
      value: 206,
      text: 'Taiwan',
      code: 'TW',
      euVatNumber: false,
    },
    {
      value: 208,
      text: 'Tanzania, United Republic of',
      code: 'TZ',
      euVatNumber: false,
    },
    {
      value: 209,
      text: 'Thailand',
      code: 'TH',
      euVatNumber: false,
    },
    {
      value: 210,
      text: 'Togo',
      code: 'TG',
      euVatNumber: false,
    },
    {
      value: 211,
      text: 'Tokelau',
      code: 'TK',
      euVatNumber: false,
    },
    {
      value: 212,
      text: 'Tonga',
      code: 'TO',
      euVatNumber: false,
    },
    {
      value: 215,
      text: 'Turkey',
      code: 'TR',
      euVatNumber: false,
    },
    {
      value: 213,
      text: 'Trinidad and Tobago',
      code: 'TT',
      euVatNumber: false,
    },
    {
      value: 214,
      text: 'Tunisia',
      code: 'TN',
      euVatNumber: false,
    },
    {
      value: 216,
      text: 'Turkmenistan',
      code: 'TM',
      euVatNumber: false,
    },
    {
      value: 217,
      text: 'Turks and Caicos Islands',
      code: 'TC',
      euVatNumber: false,
    },
    {
      value: 218,
      text: 'Tuvalu',
      code: 'TV',
      euVatNumber: false,
    },
    {
      value: 219,
      text: 'Uganda',
      code: 'UG',
      euVatNumber: false,
    },
    {
      value: 152,
      text: 'New Caledonia',
      code: 'NC',
      euVatNumber: false,
    },
    {
      value: 153,
      text: 'New Zealand',
      code: 'NZ',
      euVatNumber: false,
    },
    {
      value: 220,
      text: 'Ukraine',
      code: 'UA',
      euVatNumber: false,
    },
    {
      value: 225,
      text: 'Uruguay',
      code: 'UY',
      euVatNumber: false,
    },
    {
      value: 226,
      text: 'Uzbekistan',
      code: 'UZ',
      euVatNumber: false,
    },
    {
      value: 227,
      text: 'Vanuatu',
      code: 'VU',
      euVatNumber: false,
    },
    {
      value: 228,
      text: 'Vatican City State (Holy See)',
      code: 'VA',
      euVatNumber: false,
    },
    {
      value: 229,
      text: 'Venezuela',
      code: 'VE',
      euVatNumber: false,
    },
    {
      value: 230,
      text: 'Vietnam',
      code: 'VN',
      euVatNumber: false,
    },
    {
      value: 233,
      text: 'Wallis and Futuna Islands',
      code: 'WF',
      euVatNumber: false,
    },
    {
      value: 237,
      text: 'Zaire',
      code: 'ZR',
      euVatNumber: false,
    },
    {
      value: 238,
      text: 'Zambia',
      code: 'ZM',
      euVatNumber: false,
    },
    {
      value: 239,
      text: 'Zimbabwe',
      code: 'ZW',
      euVatNumber: false,
    },
    {
      value: 243,
      text: 'Canary Islands',
      code: 'IC',
      euVatNumber: false,
    },
  ],
};

const getCountryByLocale = (locale) => {
  const normalizedLocale = locale.toLowerCase();
  const countriesByLocale = countries[normalizedLocale] ?? countries.en;
  return countriesByLocale.sort((country1, country2) => country1.text.localeCompare(country2.text));
};

export { getCountryByLocale };
