const tinycolor = require('tinycolor2');

const getPaletteColors = (main) => {
  const mainColor = tinycolor(main);
  const { h, s, v } = mainColor.toHsv();
  return [
    mainColor.toHexString(),
    tinycolor({ h, s, v: v - 0.5 }).toHexString(),
    tinycolor({ h, s, v: v - 0.4 }).toHexString(),
    tinycolor({ h, s, v: v - 0.3 }).toHexString(),
    tinycolor({ h, s, v: v - 0.2 }).toHexString(),
  ];
};

module.exports = { getPaletteColors, tinycolor };
