import { ssStore, ssRetrieve, ssRemove } from '@/helpers/sessionStorage';

const RESYNC_STORAGE_KEY = 'resync-status';
const RESYNC_POLL_TIMESTAMP_KEY = 'resync_poll_started';
const RESYNC_POLL_LOG_ID = 'resync_poll_log_id';

class FailedIntegrationResyncStorage {
  addToStore(values) {
    ssStore(RESYNC_STORAGE_KEY, values);
  }

  getExisting() {
    return ssRetrieve(RESYNC_STORAGE_KEY);
  }

  update(value) {
    ssStore(RESYNC_STORAGE_KEY, value);
  }

  deleteResync() {
    ssRemove(RESYNC_STORAGE_KEY);
  }

  getIntegrationType() {
    return ssRetrieve(RESYNC_STORAGE_KEY).integrationType;
  }

  getErrorCount() {
    return ssRetrieve(RESYNC_STORAGE_KEY).errorCount;
  }

  getErrorCode() {
    return ssRetrieve(RESYNC_STORAGE_KEY).errorCode;
  }

  getResyncPollTimestamp() {
    return ssRetrieve(RESYNC_POLL_TIMESTAMP_KEY);
  }

  getResyncPollLogId() {
    return ssRetrieve(RESYNC_POLL_LOG_ID);
  }

  updateResyncPollTimestamp() {
    ssStore(RESYNC_POLL_TIMESTAMP_KEY, Date.now());
  }

  updateResyncPollLogId(logId) {
    ssStore(RESYNC_POLL_LOG_ID, logId);
  }

  deleteResyncPollTimestamp() {
    ssRemove(RESYNC_POLL_TIMESTAMP_KEY);
  }
}

export default new FailedIntegrationResyncStorage();
