import { track } from '@/services/xray';
import { clearProperties, collectPropertiesFromElements, getContextProperties } from './properties';

const PROPERTIES = {
  location: null,
  component: null,
  setting: null,
  change: null,
  device: null,
};

const trackResize = (event) => {
  const properties = {
    ...PROPERTIES,
    ...getContextProperties()
  };
  collectPropertiesFromElements(event.target, properties, 'resize');
  if (!properties.component || !properties.location || !properties.setting) {
    return null;
  }
  clearProperties(properties);
  if (!properties) {
    return;
  }
  track('editor_click', properties);
};

const resizeTracker = {
  track: trackResize,
};

export { resizeTracker };
