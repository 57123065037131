import { track } from '@/services/xray';
import { clearProperties } from './properties';

const COLOR_PICKER_REFS = {
  'globalStyle.palette.mainColor': 'mainColor',
  'globalStyle.palette.themeColors': 'themeColors',
};

const PROPERTIES = {
  mainColor: {
    location: 'brandkit',
    component: 'brand-color',
    setting: null,
    change: 'brandkit.brand-color.main.color',
    value: null,
  },
  wizardMainColor: {
    location: 'wizardBrandkit',
    component: 'brand-color',
    setting: null,
    change: 'brandkit.brand-color.main.color',
    value: null,
  },
  themeColors: {
    location: 'brandkit',
    component: 'brand-color',
    setting: null,
    change: 'brandkit.brand-color.secondary.color',
    value: null,
  },
  wizardThemeColors: {
    location: 'wizardBrandkit',
    component: 'brand-color',
    setting: null,
    change: 'brandkit.brand-color.secondary.color',
    value: null,
  },
  font: {
    location: 'brandkit',
    component: 'brand-font',
    setting: null,
    change: 'brandkit.brand-font',
    value: null,
  },
  wizardFont: {
    location: 'wizardBrandkit',
    component: 'brand-font',
    setting: null,
    change: 'brandkit.brand-font',
    value: null,
  },
  rounding: {
    location: 'brandkit',
    component: 'brand-rounding',
    setting: null,
    change: 'brandkit.brand-rounding',
    value: null,
  },
  wizardRounding: {
    location: 'wizardBrandkit',
    component: 'brand-rounding',
    setting: null,
    change: 'brandkit.brand-rounding',
    value: null,
  },
};

const trackChange = (property, change) => {
  if (!Object.keys(PROPERTIES).includes(property)) {
    return;
  }
  const properties = {
    ...PROPERTIES[property],
  };
  properties.setting = 'change';
  properties.value = change.value;
  clearProperties(properties);
  track('editor_click', properties);
};

const _transformActivationData = (data) => {
  const result = {};
  for (const key of Object.keys(data)) {
    for (const field of Object.keys(data[key])) {
      result[`${key}_${field}`] = data[key][field];
    }
  }
  return result;
};

const trackInfoAtCampaignActivation = (campaignId, data) => {
  const properties = {
    campaignId,
    action: 'campaign-activation',
    ..._transformActivationData(data),
  };
  track('brandkit-summary', properties);
};

const trackBrandkitOnLoadOrSave = (type, properties) => {
  track(`${type}:campaign:brandkit`, properties);
};

const brandkitTracker = {
  COLOR_PICKER_REFS,
  trackChange,
  trackInfoAtCampaignActivation,
  trackBrandkitOnLoadOrSave,
};

export { brandkitTracker };
