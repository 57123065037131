import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from '@/translations/en.json';
import hu from '@/translations/hu.json';

Vue.use(VueI18n);

export default new VueI18n({
  locale: 'en', // set default locale
  messages: { en, hu }, // set locale messages
});
