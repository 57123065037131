export default class OmComponent {
  constructor(element, palette) {
    this.element = element;
    this.palette = palette;
  }

  createColorInstance(Type) {
    return new Type(this.clone(this.element), this.palette);
  }

  clone(value) {
    return JSON.parse(JSON.stringify(value));
  }
}
