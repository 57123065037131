import { lsStore, lsRetrieve } from '@/helpers/localStorage';

const PROPERTY_NAME = 'brandColors';

function deleteOldBrandColorsLocalStorage() {
  const brandColorsLs = lsRetrieve(PROPERTY_NAME);
  const now = new Date().getTime();
  if (brandColorsLs) {
    const campaignIds = Object.keys(brandColorsLs);
    campaignIds.forEach((campaignId) => {
      const diff = Math.floor((now - brandColorsLs[campaignId].ts) / (1000 * 60 * 60 * 24));
      if (diff > 30) {
        delete brandColorsLs[campaignId];
      }
    });
    lsStore(PROPERTY_NAME, brandColorsLs);
  }
}

export { deleteOldBrandColorsLocalStorage };
