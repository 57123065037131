import { websafeFontListing } from '@/editor/util';

const _getStore = () => {
  return window.parent.om.store;
};

const _workspaceStore = () => {
  return window.om.store;
};

const _getCustomThemeFonts = () => {
  return _getStore().getters.getCustomThemeKitValues.fonts;
};

const _isThemeKit = () => {
  return !!_workspaceStore().state.template?.themeKit;
};

export const translatedThemeKitFontOptions = (optionName, customFont1, customFont2) => {
  return [
    {
      value: 'om-font-1',
      [optionName]: window.om.i18n.t('themeKit.primaryFontForQuill', { font: customFont1 }),
    },
    {
      value: 'om-font-2',
      [optionName]: window.om.i18n.t('themeKit.secondaryFontForQuill', { font: customFont2 }),
    },
  ];
};

const getThemeKitFonts = () => {
  if (!_isThemeKit()) return [];

  const [customFont1, customFont2] = _getCustomThemeFonts();
  return translatedThemeKitFontOptions('label', customFont1, customFont2);
};

export const getMostUsedFonts = (userId, installedFonts) => {
  // all fonts
  const themeKitFonts = getThemeKitFonts();
  const allFonts = [...installedFonts, ...websafeFontListing];
  if (!userId) {
    const mappedAllFonts = allFonts.map(({ key, family }) => ({ value: key, label: family }));
    return [...themeKitFonts, ...mappedAllFonts];
  }
  // get data and sort
  const fontsData = localStorage.mostUsedFonts
    ? JSON.parse(localStorage.mostUsedFonts)[userId] || {}
    : {};
  const mostUsedFonts = Object.keys(fontsData).map((value) => ({
    value,
    count: fontsData[value],
  }));
  mostUsedFonts.sort((a, b) => {
    if (a.count === b.count) {
      return a.value < b.value ? -1 : a.value > b.value ? 1 : 0;
    }
    return b.count - a.count;
  });

  const mostUsedFontsValues = mostUsedFonts
    .map(({ value }) => value)
    .filter((value) => {
      // check if it is in all fonts
      return allFonts.filter((f) => f.key === value)[0];
    })
    .slice(0, 3);

  // most used formatted
  const mostUsedFontsFormatted = mostUsedFontsValues.map((value) => {
    return {
      value,
      label: allFonts.filter((f) => f.key === value)[0].family,
    };
  });

  // rest fonts
  const fonts = allFonts
    .filter(({ key }) => mostUsedFontsValues.indexOf(key) < 0)
    .map(({ key, family }) => ({ value: key, label: family }));

  return [...themeKitFonts, ...mostUsedFontsFormatted, ...fonts];
};
