import runtimeConfig from '@/config/runtime';

const reIsOptiMonk = /(\.optimonk\.(com|local|dev)|localhost|host\.docker\.internal|\.ngrok\.io)$/i;

export const isWhitelabelDomain = () => {
  const { hostname } = window.location;

  return !reIsOptiMonk.test(hostname);
};

export function backendBaseUrl(basePath = '/api') {
  if (runtimeConfig.VUE_APP_ALLOW_RELATIVE_API) {
    const { hostname, protocol } = window.location;

    if (isWhitelabelDomain()) {
      return `${protocol}//${hostname}${basePath}`;
    }
  }

  return runtimeConfig.VUE_APP_API.replace('/api', basePath);
}

export const baseUrl = backendBaseUrl('/');
export const apiBaseUrl = backendBaseUrl('/api');
export const graphqlUrl = backendBaseUrl('/graphql');
export const graphqlUrlIncreasedTimeout = backendBaseUrl('/graphql?heavy=1');
