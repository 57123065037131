const TRACK_TAG_DATA_ATTRIBUTE = 'data-track-property';

const clearProperties = (properties) => {
  for (const propName in properties) {
    if (properties[propName] === null || properties[propName] === undefined) {
      delete properties[propName];
    }
  }
};

const collectPropertiesFromAttribute = (attribute, properties, eventName) => {
  const tags = attribute.split('|');
  tags.forEach((tag) => {
    for (const p in properties) {
      if (!properties[p] && tag.indexOf(p) === 0) {
        properties[p] = tag.replace(`${p}:`, '').replace('{event}', eventName);
      }
    }
  });
};

const collectPropertiesFromElements = (targetElement, properties, eventName) => {
  let element = targetElement;
  while (element) {
    if (element && element.hasAttribute(TRACK_TAG_DATA_ATTRIBUTE)) {
      const attribute = element.getAttribute(TRACK_TAG_DATA_ATTRIBUTE);
      if (attribute) {
        collectPropertiesFromAttribute(attribute, properties, eventName);
      }
    }
    element = element.parentElement;
  }
};

const getContextProperties = () => {
  const pageName = window.om?.store?.state?.selectedPage?.data?.title ?? 'Unknown';
  let device = 'Unknown';

  if (window.om?.store?.state?.mobilePreview !== undefined) {
    device = window.om.store.state.mobilePreview ? 'mobile' : 'desktop';
  }

  return { pageName, device };
}

export {
  TRACK_TAG_DATA_ATTRIBUTE,
  clearProperties,
  collectPropertiesFromAttribute,
  collectPropertiesFromElements,
  getContextProperties,
};
