class StaticPriceFormatter {
  static getPrecision(currency = 'USD') {
    return currency === 'HUF' ? 0 : 2;
  }

  static toPrecision(value, currency, precision) {
    precision = precision ?? this.getPrecision(currency);
    const rounded = Number(value.toFixed(precision));
    return rounded === Number(-0) ? 0 : rounded;
  }
}

module.exports = { StaticPriceFormatter };
