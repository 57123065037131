export const DEVICES = {
  MOBILE: 'mobile',
  DESKTOP: 'desktop',
};

export const COLORING_TYPES = {
  TRANSPARENT: 'transparent',
  SOLID_FILL: 'solid-fill',
  GRADIENT: 'gradient',
  IMAGE: 'image',
};

export const DEFAULT_COLOR_PARAMS = {
  TYPE: 'type',
  COLOR: 'color',
  COLOR2: 'color2',
  LINEAR_DIRECTION: 'linearDirection',
  SELECTED_BORDER: 'selectedBorder',
  SHADOW_TYPE: 'type',
  IMAGE_ID: 'imageId',
  IMAGE_URL: 'imageUrl',
  IMAGE_POSITION: 'imagePosition',
  IMAGE_REPEAT: 'imageRepeat',
  IMAGE_ALIGN: 'imageAlign',
  IMAGE_HORIZONTAL_ALIGN: 'imageHorizontalAlign',
  IMAGE_VERTICAL_ALIGN: 'imageVerticalAlign',
  CROP: 'crop',
};

export const IMAGE_POSITIONS = {
  ORIGINAL: 'original',
  CONTAIN: 'contain',
  COVER: 'cover',
  STRETCH: 'stretch',
  CROP: 'crop',
  FULL: 'full',
};
