const { isNonPayingPlan } = require('../helpers/planDetails');
const { VALIDATIONS, VALIDATORS } = require('../tools/couponPropertyValidatorsForPlan');

class CouponValidationForPlan {
  /**
    @argument plan object { name: string, period: number, originalPrice: number, currency: string }
    @argument coupon object {
      _id: ObjectId,
      couponId: number,
      status: number,
      type: string,
      discount: number,
      dateStart: Date,
      dateEnd: Date,
      enabledForFreemium: boolean,
      enabledForNotFreemium: boolean,
      enabledForMonthly: boolean,
      enabledForAnnual: boolean,
      enabledForActiveAccount: boolean,
      enabledForInactiveAccount: boolean,
      enabledForShopifyPayAccount: boolean,
      activationTimeLimitInDays: number,
      typeSettings: {
        durationLimitInMonths: number,
        fixedPriceForFirstPlan: boolean
      }
    }
  */
  constructor(plan, coupon) {
    this.plan = plan;
    this.coupon = coupon;
  }

  get planName() {
    return this.plan?.name?.toUpperCase() || '';
  }

  isValid() {
    return (
      this.isPlanFit() &&
      VALIDATIONS.every((orValidations) =>
        orValidations.some((validator) => VALIDATORS[validator]?.(this.plan, this.coupon) ?? false),
      )
    );
  }

  isPlanFit() {
    const freePlan = isNonPayingPlan(this.planName);
    const isCustomPlan = /(MASTER|ENTERPRISE)/i.test(this.planName);
    return !freePlan && !isCustomPlan;
  }
}

module.exports = { CouponValidationForPlan };
