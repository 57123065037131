import OmComponent from './OmComponent';
import {
  DesktopBackground,
  MobileBackground,
  DesktopBorder,
  MobileBorder,
  DesktopShadow,
  MobileShadow,
} from '../coloring';

export default class OmText extends OmComponent {
  constructor(elements, property, palette) {
    super(elements, palette);

    switch (property) {
      case 'desktop.background.color':
      case 'desktop.background.color2':
        return this.createColorInstance(DesktopBackground);

      case 'mobile.background.color2':
      case 'mobile.background.color':
        return this.createColorInstance(MobileBackground);

      case 'desktop.border.color':
        return this.createColorInstance(DesktopBorder);

      case 'mobile.border.color':
        return this.createColorInstance(MobileBorder);

      case 'desktop.shadow.color':
        return this.createColorInstance(DesktopShadow);

      case 'mobile.shadow.color':
        return this.createColorInstance(MobileShadow);

      default:
        throw new Error(`OmText: unknown property ${property}`);
    }
  }
}
