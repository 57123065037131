import OmComponent from './OmComponent';
import { Base } from '../coloring';

export default class OmPickAPresent extends OmComponent {
  constructor(elements, property, palette) {
    super(elements, palette);

    switch (property) {
      case 'pickAPresent.boxColor':
        return this.createColorInstance(Base)
          .setBaseProperty('desktop.pickAPresent')
          .setColorParam('boxColor');

      case 'pickAPresent.ribbonColor':
        return this.createColorInstance(Base)
          .setBaseProperty('desktop.pickAPresent')
          .setColorParam('ribbonColor');

      case 'pickAPresent.shadowColor':
        return this.createColorInstance(Base)
          .setBaseProperty('desktop.pickAPresent')
          .setColorParam('shadowColor');

      case 'title.color':
        return this.createColorInstance(Base).setBaseProperty('desktop.title');

      case 'subtitle.color':
        return this.createColorInstance(Base).setBaseProperty('desktop.subtitle');

      default:
        throw new Error(`OmPickAPresent: unknown property ${property}`);
    }
  }
}
