import { steps } from '@/utils/mobileOnboarding';

const screens = {};

screens[steps.WELCOME.name] = () => import('./Welcome.vue');
screens[steps.INDUSTRY.name] = () => import('./Industry.vue');
screens[steps.GOALS.name] = () => import('./Goals.vue');
screens[steps.CONTACTS.name] = () => import('./ContactInfo.vue');

export default screens;
