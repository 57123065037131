export default {
  path: 'dc',
  name: 'new_campaign_flow_dc',
  component: () => import('@/views/NewCampaignFlow/children/URLInput.vue'),
  children: [
    {
      path: 'verification',
      name: 'new_campaign_flow_dc_verification',
      component: () => import('@/views/NewCampaignFlow/children/dc/Verification.vue'),
      meta: { showBack: false },
    },
  ],
};
