const { ELEMENTS } = require('../propertyHelper');
const { NO_TYPES } = require('../types/radiusTypes');

const _handleSingleRow = (row, reverseOrder) => {
  const exceptions = [];
  const leftColumnUid = row[0].uid;
  const rightColumnUid = row[row.length - 1].uid;

  const handleSingleRowCenterColumn = (row, device) => {
    row.forEach((column) => {
      if (leftColumnUid !== column.uid && rightColumnUid !== column.uid) {
        exceptions.push({
          radius: device === 'desktop' ? NO_TYPES.NONE : NO_TYPES.NONE_MOBILE,
          uid: column.uid,
          device,
        });
      }
    });
  };

  const handleSingleRowAtDesktop = (row) => {
    if (leftColumnUid === rightColumnUid) return;

    const device = 'desktop';

    exceptions.push({ radius: NO_TYPES.NO_RIGHT, uid: leftColumnUid, device });
    exceptions.push({ radius: NO_TYPES.NO_LEFT, uid: rightColumnUid, device });

    handleSingleRowCenterColumn(row, device);
  };

  const handleSingleRowAtMobile = (row) => {
    if (leftColumnUid === rightColumnUid) return;

    const device = 'mobile';

    if (reverseOrder) {
      exceptions.push({ radius: NO_TYPES.NO_BOTTOM_MOBILE, uid: rightColumnUid, device });
      exceptions.push({ radius: NO_TYPES.NO_TOP_MOBILE, uid: leftColumnUid, device });
    } else {
      exceptions.push({ radius: NO_TYPES.NO_BOTTOM_MOBILE, uid: leftColumnUid, device });
      exceptions.push({ radius: NO_TYPES.NO_TOP_MOBILE, uid: rightColumnUid, device });
    }

    handleSingleRowCenterColumn(row, device);
  };

  handleSingleRowAtDesktop(row);
  handleSingleRowAtMobile(row);
  return exceptions;
};

const _handleMultipleRows = (rows, reverseOrderOnTop, reverseOrderOnBottom) => {
  const exceptions = [];
  const topRow = rows[0];
  const bottomRow = rows[rows.length - 1];
  const topLeftColumnUid = topRow[0].uid;
  const topRightColumnUid = topRow[topRow.length - 1].uid;
  const bottomLeftColumnUid = bottomRow[0].uid;
  const bottomRightColumnUid = bottomRow[bottomRow.length - 1].uid;

  const handleMultipleRowsCenterColumns = (rows, device) => {
    rows.forEach((row) => {
      row.forEach((column) => {
        if (
          device === 'desktop' &&
          topLeftColumnUid !== column.uid &&
          topRightColumnUid !== column.uid &&
          bottomLeftColumnUid !== column.uid &&
          bottomRightColumnUid !== column.uid
        ) {
          exceptions.push({ radius: NO_TYPES.NONE, uid: column.uid, device });
        }

        if (device === 'mobile') {
          if (
            topLeftColumnUid !== column.uid &&
            topRightColumnUid !== column.uid &&
            bottomLeftColumnUid !== column.uid &&
            bottomRightColumnUid !== column.uid
          ) {
            exceptions.push({ radius: NO_TYPES.NONE_MOBILE, uid: column.uid, device });
          }
        }
      });
    });

    if (topLeftColumnUid !== topRightColumnUid) {
      if (reverseOrderOnTop) {
        exceptions.push({ radius: NO_TYPES.NONE_MOBILE, uid: topLeftColumnUid, device: 'mobile' });
      } else {
        exceptions.push({ radius: NO_TYPES.NONE_MOBILE, uid: topRightColumnUid, device: 'mobile' });
      }
    }

    if (bottomLeftColumnUid !== bottomRightColumnUid) {
      if (reverseOrderOnBottom) {
        exceptions.push({
          radius: NO_TYPES.NONE_MOBILE,
          uid: bottomRightColumnUid,
          device: 'mobile',
        });
      } else {
        exceptions.push({
          radius: NO_TYPES.NONE_MOBILE,
          uid: bottomLeftColumnUid,
          device: 'mobile',
        });
      }
    }
  };

  const handleMultipleRowsAtDesktop = (rows) => {
    const device = 'desktop';

    if (topLeftColumnUid === topRightColumnUid) {
      exceptions.push({ radius: NO_TYPES.NO_BOTTOM, uid: topLeftColumnUid, device });
    } else {
      exceptions.push({
        radius: NO_TYPES.NO_BOTTOM_AND_RIGHT,
        uid: topLeftColumnUid,
        device,
      });
      exceptions.push({
        radius: NO_TYPES.NO_BOTTOM_AND_LEFT,
        uid: topRightColumnUid,
        device,
      });
    }

    if (bottomLeftColumnUid === bottomRightColumnUid) {
      exceptions.push({ radius: NO_TYPES.NO_TOP, uid: bottomLeftColumnUid, device });
    } else {
      exceptions.push({
        radius: NO_TYPES.NO_TOP_AND_RIGHT,
        uid: bottomLeftColumnUid,
        device,
      });
      exceptions.push({
        radius: NO_TYPES.NO_TOP_AND_LEFT,
        uid: bottomRightColumnUid,
        device,
      });
    }

    handleMultipleRowsCenterColumns(rows, device);
  };

  const handleMultipleRowsAtMobile = (rows) => {
    const device = 'mobile';

    if (reverseOrderOnTop) {
      exceptions.push({ radius: NO_TYPES.NO_BOTTOM_MOBILE, uid: topRightColumnUid, device });
    } else {
      exceptions.push({ radius: NO_TYPES.NO_BOTTOM_MOBILE, uid: topLeftColumnUid, device });
    }

    if (reverseOrderOnBottom) {
      exceptions.push({ radius: NO_TYPES.NO_TOP_MOBILE, uid: bottomLeftColumnUid, device });
    } else {
      exceptions.push({ radius: NO_TYPES.NO_TOP_MOBILE, uid: bottomRightColumnUid, device });
    }

    handleMultipleRowsCenterColumns(rows, device);
  };

  handleMultipleRowsAtDesktop(rows);
  handleMultipleRowsAtMobile(rows);

  return exceptions;
};

const handleTemplateElementsExceptions = (elements) => {
  const pages = [];
  const colsByRow = {};
  const rowsByPage = {};
  const rows = {};

  elements.forEach((element) => {
    if (element.type === ELEMENTS.OmPage) {
      pages.push(element);
    } else if (element.type === ELEMENTS.OmRow) {
      rows[element.uid] = element;
      if (!rowsByPage[element.pageId]) rowsByPage[element.pageId] = [];
      rowsByPage[element.pageId].push(element);
    } else if (element.type === ELEMENTS.OmCol) {
      if (!colsByRow[element.rowId]) colsByRow[element.rowId] = [];
      colsByRow[element.rowId].push(element);
    }
  });
  const rowsArray = Object.values(rows);

  const _getTemplateRows = () => {
    return rowsArray.map((row) => {
      return colsByRow[row.uid];
    });
  };

  const _getRowsByPageId = (pageId) => {
    return _getTemplateRows().filter((row) => {
      return row[0].pageId === pageId;
    });
  };

  const _getReverseOrderOnMobileByRowId = (rowId) => {
    return rows[rowId].mobile.reverseOrder;
  };

  const exceptions = [];
  pages.forEach(({ uid }) => {
    const rows = _getRowsByPageId(uid);
    if (!rows || rows.length === 0) {
      return;
    }

    if (rows.length === 1) {
      const reverseOrder = _getReverseOrderOnMobileByRowId(rows[0][0].rowId);
      exceptions.push(..._handleSingleRow(rows[0], reverseOrder));
    } else {
      const reverseOrderOnTop = _getReverseOrderOnMobileByRowId(rows[0][0].rowId);
      const reverseOrderOnBottom = _getReverseOrderOnMobileByRowId(rows[rows.length - 1][0].rowId);
      exceptions.push(..._handleMultipleRows(rows, reverseOrderOnTop, reverseOrderOnBottom));
    }
  });
  return exceptions;
};

module.exports = { handleTemplateElementsExceptions };
