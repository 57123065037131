import store from '@/store';
import { getAccountIdFromCookie } from '@/util';

export const codeInsertPlatforms = [
  'custom',
  'other',
  'tagmanager',
  'bigcommerce',
  'magento',
  'shoprenter',
  'shopify',
  'wordpress',
  'woocommerce',
  'unas',
];

const getChildren = (base) => {
  return [
    {
      path: 'domain',
      name: `${base}-domain`,
      component: () => import('@/views/InsertCode/children/Domain.vue'),
      meta: { permission: ['normal', 'sub'] },
    },
    {
      path: 'manual',
      name: `${base}-manual`,
      component: () => import('@/views/InsertCode/children/Manual.vue'),
      meta: { permission: ['normal', 'sub'] },
    },
    {
      path: 'success',
      name: `${base}-success`,
      component: () => import('@/views/InsertCode/children/Success.vue'),
      meta: { permission: ['normal', 'sub'] },
      beforeEnter(to, from, next) {
        const { domain } = to.query;
        if (!(from.name || '').includes(`${base}-platform`) || !domain) {
          return next({ name: base });
        }
        return next();
      },
    },
    {
      path: ':platform',
      component: () => import('@/views/InsertCode/children/Platform.vue'),
      beforeEnter(to, from, next) {
        const { platform = 'custom' } = to?.params || {};
        if (!codeInsertPlatforms.includes(platform)) {
          return next({ name: base, params: { userId: getAccountIdFromCookie() } });
        }
        return next();
      },
      meta: { permission: ['normal', 'sub'] },
      children: [
        {
          path: '',
          name: `${base}-platform`,
          component: () => import('@/views/InsertCode/children/Platform.vue'),
          meta: { permission: ['normal', 'sub'] },
        },
        {
          path: 'setup',
          name: `${base}-platform-setup`,
          component: () => import('@/views/InsertCode/children/Platform.vue'),
          meta: { permission: ['normal', 'sub'] },
        },
      ],
    },
  ];
};

const getBase = (path, name) => ({
  path,
  meta: { permission: ['normal', 'sub'] },
  component: () => import('@/views/InsertCode/Layout.vue'),
  name,
  redirect(to) {
    const params = { userId: getAccountIdFromCookie() };
    const query = { ...to?.query };
    const domains = store.getters.domains || [];

    if (domains.length === 1) {
      query.domain = domains[0]?.domain;
    }
    return {
      name: `${name}-domain`,
      params,
      query,
    };
  },
  children: getChildren(name),
});

export default (path, name) => getBase(path, name);
