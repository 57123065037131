import OmComponent from './OmComponent';
import {
  Base,
  DesktopBackground,
  DesktopBorder,
  MobileBorder,
  DesktopShadow,
  MobileShadow,
} from '../coloring';

// OmCheckbox, OmRadio
export default class OmInputPicker extends OmComponent {
  constructor(elements, property, palette) {
    super(elements, palette);

    switch (property) {
      case 'desktop.color':
        return this.createColorInstance(Base).setBaseProperty('desktop');

      case 'desktop.background.color':
      case 'desktop.background.color2':
        return this.createColorInstance(DesktopBackground);

      case 'desktop.border.color':
        return this.createColorInstance(DesktopBorder);

      case 'mobile.border.color':
        return this.createColorInstance(MobileBorder);

      case 'desktop.shadow.color':
        return this.createColorInstance(DesktopShadow);

      case 'mobile.shadow.color':
        return this.createColorInstance(MobileShadow);

      case 'desktop.checkedColor':
        return this.createColorInstance(Base)
          .setBaseProperty('desktop')
          .setColorParam('checkedColor');

      default:
        throw new Error(`OmInputPicker: unknown property ${property}`);
    }
  }
}
