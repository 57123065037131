const getFreemiumPlanList = () => {
  return [
    {
      name: 'FREE',
      displayName: 'Free',
      details: {
        pageViews: '15,000',
        sites: 1,
        prioritySupport: '',
        unbranded: '',
      },
    },
    {
      name: 'ESSENTIAL',
      displayName: 'Essential',
      details: {
        pageViews: '30,000',
        sites: 2,
        prioritySupport: '',
        unbranded: 'yes',
      },
    },
    {
      name: 'GROWTH',
      displayName: 'Growth',
      details: {
        pageViews: '100,000',
        sites: 4,
        prioritySupport: '',
        unbranded: 'yes',
      },
    },
    {
      name: 'PREMIUM',
      displayName: 'Premium',
      details: {
        pageViews: '500,000',
        sites: 10,
        prioritySupport: '',
        unbranded: 'yes',
      },
    },
    {
      name: 'MASTER',
      displayName: 'Master',
      details: {
        pageViews: '500,000 +',
        sites: 'unlimited',
        prioritySupport: 'yes',
        unbranded: 'yes',
      },
    },
  ];
};
module.exports = { getFreemiumPlanList };
