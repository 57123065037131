module.exports = {
  EMAIL: '[[attribute:email]]',
  FIRST_NAME: '[[attribute:firstname]]',
  LAST_NAME: '[[attribute:lastname]]',
  CURR_MONTH_EN: '[[attribute:current_month_text_en]]',
  CURR_MONTH_HU: '[[attribute:current_month_text_hu]]',
  BROWSER_NAME: '[[attribute:browser_name]]',
  OS_NAME: '[[attribute:os_name]]',
  TZ_OFFSET: '[[attribute:tz_offset]]',
  COUNTRY_DE: '[[attribute:_country_de]]',
  COUNTRY_EN: '[[attribute:_country_en]]',
  COUNTRY_HU: '[[attribute:_country_hu]]',
  LANDING_PAGE: '[[attribute:first_landing_page]]',
  UTM_SOURCE: '[[attribute:utm_source]]',
  UTM_MEDIUM: '[[attribute:utm_medium]]',
  UTM_CAMPAIGN: '[[attribute:utm_campaign]]',
  UTM_CONTENT: '[[attribute:utm_content]]',
  CART_TOTAL: '[[cart:total]]',
  CART_NUM_ITEMS: '[[cart:number_of_items]]',
  CART_NUM_ITEM_KINDS: '[[cart:number_of_item_kinds]]',
  PRODUCT_NAME: '[[shop:product_name]]',
  PRODUCT_CATEGORY: '[[shop:category_name]]',
  CART_VALUE_COUNTDOWN: '[[cartValueCountdown:cart-value-countdown-value]]',
  SMART_PRODUCT_TAG: '[[smart_product_tag:variableName]]',
};
