import Quill from 'quill';
import emojiMap from '@/editor/util/Quill/emoji-map';

const PALETTE_HEIGHT = 257;
const PALETTE_WIDTH = 250;

const _setEmojiPaletteColor = () => {
  const COLOR = '#323438';
  const emojiTabAndPanelID = ['tab-toolbar', 'tab-panel'];

  emojiTabAndPanelID.forEach((eachID) => {
    const element = document.getElementById(eachID);
    element.style.background = COLOR;
  });
};

const _setEmojiTabIconColor = () => {
  const SELECTOR = '.emoji-tab';
  document.querySelectorAll(SELECTOR).forEach((tab) => {
    tab.style.filter = 'invert(1)';
  });
};

export const registerEmoji = (editor) => {
  const Inline = Quill.import('blots/inline');
  class EmojiBlot extends Inline {
    static create(value) {
      const node = super.create();
      if (typeof value === 'object') {
        EmojiBlot.buildSpan(value, node);
      } else if (typeof value === 'string') {
        const valueObj = emojiMap[value];

        if (valueObj) {
          EmojiBlot.buildSpan(valueObj, node);
        }
      }

      return node;
    }

    static value(node) {
      return node.dataset.name;
    }

    static buildSpan(value, node) {
      node.setAttribute('data-name', value.name);
      node.innerHTML = String.fromCodePoint(...EmojiBlot.parseUnicode(value.unicode));
      editor.focus();
      setTimeout(() => editor.setSelection(editor.getSelection().index + 1, 0), 10);
    }

    static parseUnicode(string) {
      return string.split('-').map((str) => parseInt(str, 16));
    }
  }

  EmojiBlot.blotName = 'emoji';

  Quill.register({ 'formats/emoji': EmojiBlot }, true);
};

const isNanobarAtBottom = () => {
  const overlayElement = document.querySelector('.om-overlay');
  return overlayElement.classList.contains('bottom');
};

const setEmojiForNanobar = (emojiPalette, isMobilePreview, isBelow, toolbarTop) => {
  if (isMobilePreview) {
    const workspaceContent = document.querySelector('#om-campaign-0').getBoundingClientRect();
    const { height: workspaceContHeight } = workspaceContent;

    emojiPalette.style.position = 'fixed';
    emojiPalette.style.left = '40px';

    let top = PALETTE_HEIGHT;
    if (!isBelow) {
      top = -(workspaceContHeight - toolbarTop + 100);
    }

    emojiPalette.style.top = `${top}px`;

    return;
  }

  const { width: workspaceContWidth } = document
    .querySelector('.om-workspace-content')
    .getBoundingClientRect();
  const editModeEle = document.querySelector('.ql-editor').closest('.om-element.selected');
  const { height: editModeHeight, right: editModeRight } = editModeEle.getBoundingClientRect();

  const overflowToRight = editModeRight + PALETTE_WIDTH > workspaceContWidth;

  const atTheBottom = isNanobarAtBottom();
  if (atTheBottom) {
    emojiPalette.style.setProperty('top', '-360px', 'important');
    emojiPalette.style.setProperty('left', 'unset', 'important');
    emojiPalette.style.position = `fixed`;
    if (overflowToRight) {
      emojiPalette.style.right = 0;
    }
  } else {
    let left = 0;
    if (overflowToRight) {
      left = `-${PALETTE_WIDTH}px`;
    }
    emojiPalette.style.left = left;
    emojiPalette.style.top = `${editModeHeight + 90}px`;
    emojiPalette.style.position = 'absolute';
  }
};

const setEmojiForSidebar = (emojiPalette, isMobilePreview, belowToolbar, toolbarTop) => {
  // mobile view ql-editor toolbarTop < 30 => top = PALETTE_HEIGHT - ql-editor.top
  if (isMobilePreview) {
    emojiPalette.style.position = `fixed`;
    if (toolbarTop < 30) {
      emojiPalette.style.top = `${PALETTE_HEIGHT - toolbarTop}px`;
    }
  } else {
    // sidebar positioned center or middle
    emojiPalette.style.position = 'absolute';
    emojiPalette.style.left = 0;
    if (belowToolbar) {
      emojiPalette.style.top = '-30px';
    } else {
      emojiPalette.style.top = '-390px';
    }
  }
};

export const repositionEmojiPalette = () => {
  const EMOJI_BUTTON_CLASS = '.ql-emoji';
  const EMOJI_PALETTE_ID = 'emoji-palette';
  const emojiButton = document.querySelector(EMOJI_BUTTON_CLASS);

  emojiButton.addEventListener('click', () => {
    const emojiPalette = document.getElementById(EMOJI_PALETTE_ID);

    if (!emojiPalette) return;

    const mobilePreview = window.om.store.state.mobilePreview;
    let below = false;
    const qlToolbarEle = document.querySelector('.ql-toolbar');
    const toolbarBounding = qlToolbarEle.getBoundingClientRect();
    const { top: toolbarTop, left: toolbarLeft } = toolbarBounding;

    const topCandidate = toolbarTop - PALETTE_HEIGHT;
    if (topCandidate < 60) {
      below = true;
    }
    if (mobilePreview && topCandidate > 0) {
      below = false;
    }

    const isSidebar = window.om.store.getters.isSidebar;
    const isNanobar = window.om.store.getters.isNano;

    _setEmojiPaletteColor();
    _setEmojiTabIconColor();
    emojiPalette.style.width = `${PALETTE_WIDTH}px`;

    if (!isNanobar && !isSidebar) {
      const { offsetTop, offsetHeight } = qlToolbarEle;
      emojiPalette.style.position = `fixed`;
      emojiPalette.style.left = `${toolbarLeft}px`;
      if (below) {
        emojiPalette.style.top = `${offsetTop + offsetHeight}px`;
      } else {
        emojiPalette.style.top = `${offsetTop - PALETTE_HEIGHT}px`;
      }
    } else if (isNanobar) {
      setEmojiForNanobar(emojiPalette, mobilePreview, below, toolbarTop);
    } else if (isSidebar) {
      setEmojiForSidebar(emojiPalette, mobilePreview, below, toolbarTop);
    }
  });
};
