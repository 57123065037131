import Background from './Background';

export default class OldTeaserBackground extends Background {
  constructor(element, palette) {
    super(element, palette);
    this.baseProperty = 'tab.background';
  }

  getValues() {
    const property = this.baseProperty;
    return [
      {
        prop: property,
        value: this.getValueByProperty(property),
      },
    ];
  }

  isGlobalStyle() {
    return true;
  }
}
