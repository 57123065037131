import Base from './Base';

export default class QuillOmColor extends Base {
  constructor(palette) {
    super({ omcolor: { value: 0 } }, palette);
    this.baseProperty = 'omcolor';
    this.colorParam = 'value';
  }

  isQuillOmColor() {
    return true;
  }

  isOmColorClass() {
    return Number.isInteger(this.getColor());
  }

  getValues() {
    const colorProperty = this.getColorProperty();

    return {
      property: this.baseProperty,
      value: this.getValueByProperty(colorProperty),
      isOmColorClass: this.isOmColorClass(),
    };
  }
}
