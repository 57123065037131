const ACCOUNT_TYPES = {
  NORMAL: 'normal',
  AGENCY: 'agency',
  SUB: 'sub',
};

const PARTNER_TYPES = {
  NORMAL: 'normal',
  AFFILIATE: 'affiliate',
  AGENCY: 'agency',
  RESELLER: 'reseller',
  SUBACCOUNT: 'sub',
  CUSTOM: 'custom',
  BROKEN: 'broken',
};

const SUPPORTED_CONVERTING = {
  NORMAL: [PARTNER_TYPES.AFFILIATE, PARTNER_TYPES.AGENCY, PARTNER_TYPES.RESELLER],
  AFFILIATE: [PARTNER_TYPES.NORMAL, PARTNER_TYPES.AGENCY],
  AGENCY: [PARTNER_TYPES.NORMAL, PARTNER_TYPES.AFFILIATE],
  RESELLER: [], // default
  CUSTOM: [], // default
  BROKEN: [],
  SUBACCOUNT: [],
};

module.exports = { ACCOUNT_TYPES, PARTNER_TYPES, SUPPORTED_CONVERTING };
