import Quill from 'quill';
import { ELEMENTS } from '@om/template-properties/src/propertyHelper';
import { smartTagsOptions } from '@/editor/quill_options';
import { DATA_ATTRIBUTES } from '@om/smart-tags/src/helper';
import { generateRandomStr } from '@/util';

export const registerSmartTag = () => {
  const Embed = Quill.import('blots/embed');
  class SmartTag extends Embed {
    static create(instance) {
      const node = super.create();
      const content = instance.getContent();

      node.setAttribute(DATA_ATTRIBUTES.TAG, instance.getAttributeWithDefaultValue());
      node.setAttribute(DATA_ATTRIBUTES.TAG_ID, instance.getUid());
      node.contentEditable = false;
      node.innerHTML = content;

      return node;
    }

    static value(domNode) {
      return domNode.dataset;
    }

    static formats(domNode) {
      return {
        ...super.formats(domNode),
        [DATA_ATTRIBUTES.TAG]: domNode.getAttribute(DATA_ATTRIBUTES.TAG) || true,
      };
    }

    format(name, value) {
      if (DATA_ATTRIBUTES.TAG === name && value) {
        this.domNode.setAttribute(name, value);
      } else {
        super.format(name, value);
      }
    }

    attach() {
      this.domNode.children[0].classList.add('smart-tag-content');
    }
  }
  SmartTag.blotName = 'smart-tag';
  SmartTag.className = 'smart-tag';
  SmartTag.tagName = 'SPAN';

  Quill.register(SmartTag, true);
};

const _isSmartProductTag = (smartTagElement) => {
  const { smartTag } = smartTagElement.dataset;
  return smartTag.includes('smart_product_tag');
};

export const handleSmartTagClick = (smartTagElement) => {
  if (_isSmartProductTag(smartTagElement)) return;

  window.parent.om.bus.$emit('smart-tag-modal', {
    edit: {
      smartTagElement,
    },
  });
};

export const cleanUpSmartTag = (input) => {
  if (!input) return;

  const parser = new DOMParser();
  const htmlDoc = parser.parseFromString(input.toString(), 'text/html');

  const smartTags = htmlDoc.getElementsByClassName('smart-tag');

  if (!smartTags.length) return;

  for (let i = 0; i < smartTags.length; i++) {
    let element = smartTags[i];

    while (element && element.lastChild && element.lastChild.nodeName === 'SPAN') {
      element = element.lastChild;
    }

    const output = element.textContent;

    while (element.nodeName === 'SPAN' && !element.classList.contains('smart-tag')) {
      const grandParentNode = element.parentNode;
      grandParentNode.removeChild(element);
      element = grandParentNode;
    }

    const child = document.createElement('span');
    child.contentEditable = false;
    child.textContent = output;

    element.appendChild(child);
  }

  const dtrContents = [...htmlDoc.getElementsByClassName('om-dtr-content')];
  const joined = dtrContents.map((content) => content.outerHTML).join('');
  return joined;
};

export const migrateExistingDTRAttribute = (elements) => {
  const DTR_ATTRIBUTES = smartTagsOptions.map((option) => option);
  const DTR_COMPATIBLE_ELEMENTS = [ELEMENTS.OmText, ELEMENTS.OmButton];
  const filteredElements = elements.filter(({ type }) => DTR_COMPATIBLE_ELEMENTS.includes(type));

  DTR_ATTRIBUTES.forEach(({ key, value }) => {
    filteredElements.forEach((element) => {
      const {
        data: { text },
      } = element;
      const hasAttribute = text.includes(value);
      const hasSmartTag = text.includes(DATA_ATTRIBUTES.TAG);

      if (!hasAttribute || hasSmartTag) return;

      const replaced = text.replace(
        value,
        `<span class="smart-tag" data-smart-tag="${value}" ${
          DATA_ATTRIBUTES.TAG_ID
        }="${generateRandomStr(7)}"><span contenteditable="false">${window.om.i18n.t(
          `smartTags.tags.${key}`,
        )}</span></span>`,
      );

      element.data.text = replaced;
    });
  });
};
