import { track } from '@/services/xray';
import { clearProperties, getContextProperties } from './properties';

const PROPERTIES = {
  'globalStyle.overlay.background.color': {
    location: 'display',
    component: 'website-overlay',
    setting: 'fill-change',
    change: 'website.overlay.fill.color.{type}',
    value: null,
  },
  'globalStyle.palette.mainColor': {
    location: 'display',
    component: 'theme-colors',
    setting: 'change',
    change: 'theme.colors.main.color',
    value: null,
  },
};

const _setChangeWithType = (properties, type) => {
  let change = properties.change;
  change = change.replace('{type}', type);
  properties.change = change;
};

const trackColorChange = (property, change) => {
  if (!Object.keys(PROPERTIES).includes(property)) {
    return;
  }

  const properties = {
    ...PROPERTIES[property],
    ...getContextProperties(),
  };
  _setChangeWithType(properties, change.type);
  properties.value = change.value;
  clearProperties(properties);
  track('editor_click', properties);
};

const colorChangeTracker = {
  track: trackColorChange,
};

export { colorChangeTracker };
