const { StaticPriceFormatter } = require('./StaticPriceFormatter');

class PriceCalculationByCoupon {
  /**
    @argument plan object {
      name: string,
      period: number,
      originalFullprice: number, // package full price
      originalPrice: number, // the price to which we want to apply the discount (this may be the upgrade or full price)
      currency: string
    }
    @argument coupon object {
      _id: ObjectId,
      couponId: number,
      status: number,
      type: string,
      discount: number,
      dateStart: Date,
      dateEnd: Date,
      enabledForFreemium: boolean,
      enabledForNotFreemium: boolean,
      enabledForMonthly: boolean,
      enabledForAnnual: boolean,
      enabledForActiveAccount: boolean,
      enabledForInactiveAccount: boolean,
      enabledForShopifyPayAccount: boolean,
      activationTimeLimitInDays: number,
      typeSettings: {
        durationLimitInMonths: number,
        fixedPriceForFirstPlan: boolean
      }
    }
  */
  constructor(plan, coupon, activatedCoupon = null) {
    this.plan = plan;
    this.coupon = coupon;
    this.activatedCoupon = activatedCoupon;
  }

  getDiscountedPrice() {
    try {
      return this.formatByCurrency(this.calculatePrice());
    } catch (e) {
      console.log('error', e);
    }

    return this.originalPrice;
  }

  getDiscountAmount() {
    const discountedPrice = this.getDiscountedPrice();
    const discountAmount = this.originalPrice - discountedPrice;
    if (discountAmount < 0) return 0;
    return this.formatByCurrency(discountAmount);
  }

  getDiscountInDays() {
    const packagePeriodInDays = this.period === 12 ? 365 : 30;
    const multiplier = this.period === 12 ? this.multiplier + 0.2 : this.multiplier;
    const discountInDays = packagePeriodInDays * (1 - multiplier);
    return discountInDays;
  }

  getDiscountInPercent() {
    return 100 - this.multiplier * 100;
  }

  get originalFullprice() {
    return this.plan?.originalFullprice;
  }

  get originalPrice() {
    return this.plan?.originalPrice;
  }

  get isFirstPlanFixed() {
    return (
      this.coupon?.typeSettings?.fixedPriceForFirstPlan &&
      this.activatedCoupon?.fixedPriceForFirstPlan?.price > 0
    );
  }

  get firstPlan() {
    if (this.isFirstPlanFixed) {
      return this.activatedCoupon?.fixedPriceForFirstPlan ?? null;
    }

    return null;
  }

  get currency() {
    return this.plan?.currency;
  }

  get multiplier() {
    const baseMultiplier = 1 - this.coupon.discount / 100;

    if (
      this.firstPlan?.name === this.plan?.name &&
      this.originalFullprice &&
      this.fixedFirstPlanPrice
    ) {
      return this.fixedFirstPlanPrice / this.originalFullprice;
    }

    return baseMultiplier;
  }

  get period() {
    return this.plan?.period;
  }

  get fixedFirstPlanPrice() {
    if (!this.firstPlan?.price) return null;
    return this._currencyExchange(this.firstPlan?.price, this.currency);
  }

  calculatePrice() {
    return this.originalPrice * this.multiplier;
  }

  formatByCurrency(value) {
    return StaticPriceFormatter.toPrecision(value, this.currency);
  }

  // IMPROVEMENT[Dzsi]
  _currencyExchange(value, toCurrency) {
    if (toCurrency === 'HUF') return value;
    return value / 250;
  }
}

module.exports = { PriceCalculationByCoupon };
