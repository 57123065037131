const { ACCOUNT_TYPES, PARTNER_TYPES } = require('./enums');

class PartnerTypeService {
  constructor({ accountType = null, partnerType = null, account = null }) {
    this.accountType = accountType;
    this.partnerType = partnerType;
    if (account) {
      this.accountType = account?.type;
      this.partnerType = account?.partnerType;
    }
  }

  hasPartnerType() {
    return !!this.partnerType;
  }

  getPartnerType() {
    return this.partnerType;
  }

  isPartner() {
    if (this.partnerType === PARTNER_TYPES.AGENCY) return true;
    if (this.partnerType === PARTNER_TYPES.RESELLER) return true;
    if (this.accountType === ACCOUNT_TYPES.AGENCY) return true;
    return false;
  }

  isAgencyPartnerType() {
    if (this.partnerType === PARTNER_TYPES.AGENCY) return true;
    return false;
  }

  isResellerPartnerType() {
    if (this.partnerType === PARTNER_TYPES.RESELLER) return true;
    return false;
  }

  isAffiliate() {
    if (this.partnerType === PARTNER_TYPES.AFFILIATE) return true;
    return false;
  }

  isCustom() {
    if (this.partnerType === PARTNER_TYPES.CUSTOM) return true;
    if (!this.partnerType) {
      return this.accountType === ACCOUNT_TYPES.AGENCY;
    }
    return false;
  }

  isNormal() {
    if (this.partnerType === PARTNER_TYPES.NORMAL) return true;
    if (!this.partnerType) {
      return this.accountType === ACCOUNT_TYPES.NORMAL;
    }
    return false;
  }

  isSub() {
    if (this.partnerType === PARTNER_TYPES.SUBACCOUNT) return true;
    return false;
  }
}

module.exports = { PartnerTypeService };
