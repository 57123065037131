import { DEFAULT_COLOR_PARAMS } from '../helper';
import Base from './Base';

export default class MobileShadow extends Base {
  constructor(element, palette) {
    super(element, palette);
    this.baseProperty = 'mobile.shadow';
  }

  getColorProperty() {
    return this.getDesktopPropertyIfMobileShadowNotSelected(DEFAULT_COLOR_PARAMS.COLOR);
  }

  getDesktopPropertyIfMobileShadowNotSelected(param) {
    const mobileTypeProperty = `${this.baseProperty}.${DEFAULT_COLOR_PARAMS.SHADOW_TYPE}`;
    const mobileTypeValue = this.getValueByProperty(mobileTypeProperty);

    if (['none', null].includes(mobileTypeValue)) {
      return `${this.getDesktopBaseProperty()}.${param}`;
    }
    return `${this.baseProperty}.${param}`;
  }
}
