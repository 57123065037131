import * as Cookies from 'js-cookie';
import { getAccountIdFromCookie, setAccountIdInCookie } from '@/util';
import store from '@/store';

const isRoot = (to) => {
  return to.fullPath === '/';
};

export const noLoginNeeded = (to) => {
  return to.matched.length && to.meta.permission === undefined && !isRoot(to);
};

export const redirectToHomeScreen = ({ to, store, params }) => {
  if (isRoot(to)) {
    switch (store.state.accountType) {
      case 'multiple':
        return { name: 'switch_account' };

      case 'agency':
        return { name: 'agency_dashboard', ...(params ? { params } : {}) };

      default:
        return { name: 'dashboard', ...(params ? { params } : {}) };
    }
  }
};
export const isMobileScreenRequired = () => {
  const {
    type,
    settings: { onboarding },
  } = store.state.account || {};
  const { finished } = onboarding || {};
  const isOnboardingRequired = !finished && !['sub', 'agency'].includes(type);
  return store.getters.isMobile && store.getters.isLoggedIn() && isOnboardingRequired;
};

export const redirectByName = ({ from, params }) => {
  const queryParams = JSON.parse(JSON.stringify(from.query));

  const redirectByName = Cookies.get('x-om-redirect');
  if (redirectByName) {
    Cookies.remove('x-om-redirect');
    delete queryParams.redirect;
    return { name: redirectByName, query: queryParams, replace: true, params };
  }
};

export const redirectToEditorParams = ({ to }) => {
  const regex = /(\d+)\/variant\/(\d+)\/(\w+)\/edit_beta/;
  const match = to.path.match(regex);
  const [, userId, campaignId, id] = match;

  return {
    name: 'variant_edit',
    params: { userId: parseInt(userId, 10), campaignId: parseInt(campaignId, 10), id },
  };
};

export const redirectByPath = ({ from, router, params }) => {
  const queryParams = JSON.parse(JSON.stringify(from.query));

  const redirectByPath = Cookies.get('x-om-redirect-path');
  if (redirectByPath) {
    Cookies.remove('x-om-redirect-path');
    try {
      const { resolved } = router.resolve({
        path: redirectByPath,
        query: queryParams,
        replace: true,
        params,
      });

      if (resolved) {
        delete queryParams.redirectPath;
        return {
          name: resolved.name,
          query: queryParams,
          replace: true,
          params: { ...resolved.params, ...params },
        };
      }
    } catch (e) {
      console.error('Failed to resolve path:', redirectByPath);
    }
  }
};

export const getParamsWithUserId = (to) => {
  const impersonateId = to?.query?.impersonateId;
  let userId;
  if (impersonateId) {
    userId = impersonateId;
    setAccountIdInCookie(userId);
  } else {
    userId = getAccountIdFromCookie();
  }

  return Object.assign(to.params, { userId });
};

export const duringGracePeriod = ({ to, store, params }) => {
  if (store.getters.isSuperAdmin) return;

  const exceptions = [
    'plan_settings',
    'checkout',
    'account_settings',
    'change_email',
    'invoice_history',
    'change_password',
    'billing_address',
    'spam_protection',
    'payment_method',
    'white_label_settings',
    'account_sharing',
    'domains',
    'insertcode_settings',
    'settings-code-insert',
    'apikey_settings',
    'experimental',
    'features',
    'user_experience_protector_settings',
  ];
  if (exceptions.includes(to.name)) return;

  const hasGracePeriodForPriceChange = store.getters['payment/hasGracePeriodForPriceChange'];
  const isGracePeriodExpired = store.getters['payment/isGracePeriodExpired'];

  if (hasGracePeriodForPriceChange && isGracePeriodExpired) {
    return { name: 'plan_settings', params };
  }
};
