import OmComponent from './OmComponent';
import {
  Base,
  DesktopBackground,
  DesktopBorder,
  MobileBorder,
  DesktopShadow,
  MobileShadow,
  MobileBackground,
} from '../coloring';

export default class OmFeedback extends OmComponent {
  constructor(elements, property, palette) {
    super(elements, palette);

    switch (property) {
      case 'desktop.background.color':
      case 'desktop.background.color2':
        return this.createColorInstance(DesktopBackground);

      case 'mobile.background.color':
      case 'mobile.background.color2':
        return this.createColorInstance(MobileBackground);

      case 'desktop.border.color':
        return this.createColorInstance(DesktopBorder);

      case 'mobile.border.color':
        return this.createColorInstance(MobileBorder);

      case 'desktop.shadow.color':
        return this.createColorInstance(DesktopShadow);

      case 'mobile.shadow.color':
        return this.createColorInstance(MobileShadow);

      case 'subElements.icon.desktop.color':
        return this.createColorInstance(Base).setBaseProperty('subElements.icon.desktop');

      default:
        throw new Error(`OmFeedback: unknown property ${property}`);
    }
  }
}
