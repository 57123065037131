import OmComponent from './OmComponent';
import { Base } from '../coloring';

export default class OmCountdown extends OmComponent {
  constructor(elements, property, palette) {
    super(elements, palette);

    switch (property) {
      case 'subElements.number.desktop.background.color':
        return this.createColorInstance(Base).setBaseProperty(
          'subElements.number.desktop.background',
        );

      case 'subElements.label.desktop.color':
        return this.createColorInstance(Base)
          .setBaseProperty('subElements.label.desktop')
          .setColorParam('color');

      case 'subElements.number.desktop.color':
        return this.createColorInstance(Base).setBaseProperty('subElements.number.desktop');

      case 'subElements.separator.desktop.color':
        return this.createColorInstance(Base).setBaseProperty('subElements.separator.desktop');

      case 'subElements.number.desktop.border.color':
        return this.createColorInstance(Base).setBaseProperty('subElements.number.desktop.border');

      case 'subElements.number.desktop.shadow.color':
        return this.createColorInstance(Base).setBaseProperty('subElements.number.desktop.shadow');

      case 'subElements.number.mobile.background.color':
        return this.createColorInstance(Base).setBaseProperty(
          'subElements.number.mobile.background',
        );

      case 'subElements.label.mobile.color':
        return this.createColorInstance(Base)
          .setBaseProperty('subElements.label.mobile')
          .setColorParam('color');

      case 'subElements.number.mobile.color':
        return this.createColorInstance(Base).setBaseProperty('subElements.number.mobile');

      case 'subElements.separator.mobile.color':
        return this.createColorInstance(Base).setBaseProperty('subElements.separator.mobile');

      case 'subElements.number.mobile.border.color':
        return this.createColorInstance(Base).setBaseProperty('subElements.number.mobile.border');

      case 'subElements.number.mobile.shadow.color':
        return this.createColorInstance(Base).setBaseProperty('subElements.number.mobile.shadow');

      default:
        throw new Error(`OmCountdown: unknown property ${property}`);
    }
  }
}
