import { get as _get, mergeWith } from 'lodash';
import { ELEMENTS } from '@om/template-properties/src/propertyHelper';

const GRID_COLUMN_COUNT = 12;

export const inputElements = [
  ELEMENTS.OmTextarea,
  ELEMENTS.OmInput,
  ELEMENTS.OmRadio,
  ELEMENTS.OmDropdown,
  ELEMENTS.OmCheckbox,
  ELEMENTS.OmFeedback,
  ELEMENTS.OmSurvey,
];
export const inputTypes = ['number', 'email', 'phoneNumber', 'url', 'text', 'date'];
export const dropdownTypes = ['dropdown', 'country'];

const getTranslationPathLastElement = (path) => {
  const pieces = path.split('.');
  return pieces[pieces.length - 1];
};

export const translate = (path, isSSR) => {
  if (isSSR || typeof isSSR === 'undefined') return getTranslationPathLastElement(path);

  const { locale, messages } = window.parent.om.i18n;
  const fullPath = `${locale}.${path}`;
  return _get(messages, fullPath, fullPath);
};

export const _isObject = (value) => {
  const type = typeof value;
  return value != null && (type === 'object' || type === 'function');
};

export const nilMerge = (a, b) => (a == null ? b : a);

export const nilMergeDeep = (a, b) =>
  _isObject(a) && !Array.isArray(a) ? mergeWith({}, a, b, nilMergeDeep) : nilMerge(a, b);

export const isDefined = (data) => typeof data !== 'undefined' && data !== null;

export const isNano = (mode) => mode === 'nano';

export const mapPopupPositionToTeaser = (position) => {
  if ([1, 2].includes(position)) return 'top-left';
  if (position === 3) return 'top-right';
  if ([4, 5, 7, 8].includes(position)) return 'bottom-left';
  if ([6, 9].includes(position)) return 'bottom-right';
};

const getQueryVariable = (variable) => {
  if (typeof window === 'undefined') return null;
  const query = window.location.search.substring(1); // remove ?
  const params = query.split('&');
  for (let i = 0; i < params.length; i++) {
    const pair = params[i].split('=');
    if (pair[0] === variable) return pair[1];
  }
  return null;
};

const inIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

const inDocker = () => !!getQueryVariable('docker');

const frameStore = () => {
  if (global.__vue_store__) return global.__vue_store__;

  const workspace = document.getElementById('workspaceFrame');
  return workspace.contentWindow.om.store;
};

const getState = () => {
  let state;
  if (inIframe() || inDocker()) {
    state = _get(window, 'om.store.state');
  } else {
    state = frameStore().state;
  }
  return state;
};

const getHorizontalPadding = (padding) => {
  const left = parseInt(_get(padding, 'left'), 10) || 0;
  const right = parseInt(_get(padding, 'right'), 10) || 0;
  return left + right;
};

const getPageForElement = (element, template) => {
  const page = template.elements.find((e) => e.uid === element.pageId);

  return page;
};

const getCanvasWidth = (element, template, device) => {
  const page = getPageForElement(element, template);
  let pageWidth = _get(page, `${device}.width`);

  if (device === 'mobile') {
    pageWidth = Math.round((360 * parseInt(pageWidth, 10)) / 100);
  }

  return pageWidth || 600;
};

const calcColumnWidth = ({ row, col, element, template, device }) => {
  if (!row || !col) return;

  const columnsOfRow = template.elements.filter(
    ({ rowId, type }) => type === 'OmCol' && rowId === row.uid,
  );
  const y = columnsOfRow.findIndex(({ uid }) => uid === _get(col, 'uid'));
  const maxWidth = _get(col, `${device}.maxWidth`);

  if (maxWidth) {
    let doc = document;

    if (!inIframe()) {
      const wrkspc = document.getElementById('workspaceFrame');
      doc = wrkspc.contentWindow.document;
    }

    const colEl = doc.getElementById(col.uid);

    if (colEl) {
      return colEl.clientWidth;
    }
    return 1;
  }
  const canvasWidth = getCanvasWidth(element, template, device);

  if (!isDefined(y) || device === 'mobile') return canvasWidth;
  return (canvasWidth / GRID_COLUMN_COUNT) * row.data.columnSplit[y];
};

export const containerWidthForElement = (element, template = undefined, device = 'desktop') => {
  const state = getState();
  template = template || state.template;

  const row = template.elements.find(({ uid }) => uid === element.rowId);
  const col = template.elements.find(({ uid }) => uid === element.colId);

  if (!row || !col) return 0;

  const rowPadding = parseInt(getHorizontalPadding(_get(row, `${device}.padding`)), 10);
  const columnPadding = parseInt(getHorizontalPadding(_get(col, `${device}.padding`)), 10);
  const horizontalPadding = rowPadding + columnPadding;

  const colWidth = calcColumnWidth({ row, col, element, template, device });

  const containerWidth = colWidth - horizontalPadding;

  return containerWidth;
};
