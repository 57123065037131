import OmComponent from './OmComponent';
import { Base } from '../coloring';

export default class OmLuckyWheel extends OmComponent {
  constructor(elements, property, palette) {
    super(elements, palette);

    switch (property) {
      case 'luckyWheel.backgroundFillColor':
        return this.createColorInstance(Base)
          .setBaseProperty('desktop.luckyWheel')
          .setColorParam('backgroundFillColor');

      case 'luckyWheel.sliceColor1':
        return this.createColorInstance(Base)
          .setBaseProperty('desktop.luckyWheel')
          .setColorParam('sliceColor1');

      case 'luckyWheel.sliceColor2':
        return this.createColorInstance(Base)
          .setBaseProperty('desktop.luckyWheel')
          .setColorParam('sliceColor2');

      case 'luckyWheel.sliceColor3':
        return this.createColorInstance(Base)
          .setBaseProperty('desktop.luckyWheel')
          .setColorParam('sliceColor3');

      case 'luckyWheel.sliceColor4':
        return this.createColorInstance(Base)
          .setBaseProperty('desktop.luckyWheel')
          .setColorParam('sliceColor4');

      case 'luckyWheel.labelTextColor':
        return this.createColorInstance(Base)
          .setBaseProperty('desktop.luckyWheel')
          .setColorParam('labelTextColor');

      case 'luckyWheel.dots':
        return this.createColorInstance(Base)
          .setBaseProperty('desktop.luckyWheel')
          .setColorParam('dots');

      case 'luckyWheel.sticks':
        return this.createColorInstance(Base)
          .setBaseProperty('desktop.luckyWheel')
          .setColorParam('sticks');

      case 'luckyWheel.radius':
        return this.createColorInstance(Base)
          .setBaseProperty('desktop.luckyWheel')
          .setColorParam('radius');

      case 'luckyWheel.ticker':
        return this.createColorInstance(Base)
          .setBaseProperty('desktop.luckyWheel')
          .setColorParam('ticker');

      default:
        throw new Error(`OmLuckyWheel: unknown property ${property}`);
    }
  }
}
