import { nilMergeDeep } from '../../utils';

const initMobileStyles = (element, keys = ['manualMargin']) => {
  let mobile = {};
  const all = {
    manualMargin: {
      allSides: null,
      top: null,
      bottom: null,
      right: null,
      left: null,
    },
    manualPadding: {
      allSides: null,
      top: null,
      left: null,
      bottom: null,
      right: null,
    },
    size: null,
    globalWidth: null,
    globalHeight: null,
    border: {
      selectedBorder: null,
      type: null,
      width: null,
      radius: [null, null, null, null],
      radiusChained: true,
      color: null,
    },
    shadow: {
      type: null,
      color: '#000',
    },
    background: {
      color: null,
      color2: null,
      type: null,
      gradientType: null,
      gradientCenter: null,
      linearDirection: null,
      transparency: null,
      imageId: null,
      imagePosition: 'cover',
      imageRepeat: null,
      imageHorizontalAlign: null,
      imageAlign: null,
      imageVerticalAlign: null,
      resizeType: null,
    },
    height: null,
    align: 'center',
  };

  if (keys && keys.length) {
    for (const key of Object.keys(all)) {
      if (keys.includes(key)) {
        mobile[key] = all[key];
      }
    }
  } else {
    mobile = all;
  }

  element.style = nilMergeDeep(element.style, {
    mobile: {
      ...mobile,
    },
  });
};

export { initMobileStyles };
