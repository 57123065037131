import OmComponent from './OmComponent';
import { Base, DesktopBackground, MobileBackground } from '../coloring';

export default class OmSurvey extends OmComponent {
  constructor(elements, property, palette) {
    super(elements, palette);

    switch (property) {
      case 'subElements.option.desktop.color':
        return this.createColorInstance(Base).setBaseProperty('subElements.option.desktop');

      case 'subElements.option.desktop.background.color':
        return this.createColorInstance(DesktopBackground).setBaseProperty(
          'subElements.option.desktop.background',
        );

      case 'subElements.option.desktop.border.color':
        return this.createColorInstance(Base).setBaseProperty('subElements.option.desktop.border');

      case 'subElements.option.desktop.shadow.color':
        return this.createColorInstance(Base).setBaseProperty('subElements.option.desktop.shadow');

      case 'subElements.option.mobile.color':
        return this.createColorInstance(Base).setBaseProperty('subElements.option.mobile');

      case 'subElements.option.mobile.background.color':
        return this.createColorInstance(MobileBackground).setBaseProperty(
          'subElements.option.mobile.background',
        );

      case 'subElements.option.mobile.border.color':
        return this.createColorInstance(Base).setBaseProperty('subElements.option.mobile.border');

      case 'subElements.option.mobile.shadow.color':
        return this.createColorInstance(Base).setBaseProperty('subElements.option.mobile.shadow');

      default:
        throw new Error(`OmSurvey: unknown property ${property}`);
    }
  }
}
