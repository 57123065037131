export default {
  props: {
    designSystem: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    classes() {
      return {
        'design-system': this.designSystem,
      };
    },
  },
};
