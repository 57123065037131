import { track } from '@/services/xray';
import { clearProperties, collectPropertiesFromElements, getContextProperties } from './properties';

const PROPERTIES = {
  eventName: null,
  location: null,
  component: null,
  setting: null,
  change: null,
  item: null,
};

const _addListener = (document, event, handler) => {
  document.addEventListener(event, handler, true);
};

const _clickEventHandler = (event) => {
  let properties = {
    ...PROPERTIES,
  };
  collectPropertiesFromElements(event.target, properties, 'click');
  if (!properties.component || !properties.location || !properties.setting) {
    return null;
  }
  const eventName = properties.eventName || 'editor_click';
  delete properties.eventName;

  if (eventName === 'editor_click') {
    const context = getContextProperties()
    properties = { ...properties, ...context }
  }

  clearProperties(properties);
  if (!properties) {
    return;
  }
  track(eventName, properties);
};

const _initClickEventTracker = (document) => {
  _addListener(document, 'click', _clickEventHandler);
};

const clickEventTracker = {
  init: _initClickEventTracker,
};

export { clickEventTracker };
