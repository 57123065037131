import sab from './sab';
import dc from './dc';

export default {
  path: '/:userId/new-campaign',
  component: () => import('@/views/NewCampaignFlow/Wrapper.vue'),
  meta: { permission: ['normal'], hideNav: true },
  children: [
    {
      path: '',
      name: 'new_campaign_flow',
      component: () => import('@/views/NewCampaignFlow/children/Branching.vue'),
    },
    {
      path: 'optimize',
      name: 'new_campaign_flow_optimize',
      component: () => import('@/views/NewCampaignFlow/children/WebsiteOptimization.vue'),
      children: [sab, dc],
    },
  ],
};
