const { PARTNER_TYPES, SUPPORTED_CONVERTING } = require('./enums');

const _getSupportedConverting = (type) => {
  const key = Object.keys(PARTNER_TYPES).find((key) => PARTNER_TYPES[key] === type);
  return SUPPORTED_CONVERTING[key];
};

const _hasAffiliateLink = (account) => {
  const settings = account?.settings?.general;
  if (!settings) return false;
  const affiliateSetting = settings.find((e) => e.key === 'affiliate');
  return (
    affiliateSetting &&
    (affiliateSetting.value === '1' ||
      affiliateSetting.value === 'true' ||
      affiliateSetting.value === true)
  );
};

class PartnerTypeData {
  constructor() {
    this.partnerDatabaseId = null;
    this.accountType = null; // mongo.account.type
    this.partnerType = null;
    this.subAccounts = [];

    this.partnerData = {
      account: null,
      customer: null,
      affiliatePartner: null,
      affiliateCommission: null,
    };
    this.firstSubData = { account: null };

    this.flags = {};
    this.flags.hasAffiliateLink = false; // Affiliate link, mongo.account.settings.general.affiliate
    this.flags.hasInAffiliatePartners = false; // mysql.aaffiliate_partner
    this.flags.hasAffiliateCommissionType = false; // mysql.aaffiliate_commission_to_partner
    this.flags.hasCustomer = false; // mysql.customers
    this.flags.hasSubAccounts = false;
  }

  setPartnerAccount(data) {
    this.partnerData.account = data;
  }

  setPartnerCustomer(data) {
    this.partnerData.customer = data;
  }

  setPartnerAffiliatePartner(data) {
    this.partnerData.affiliatePartner = data;
  }

  setPartnerAffiliateCommission(data) {
    this.partnerData.affiliateCommission = data;
  }

  setFirstSubAccount(data) {
    this.firstSubData.account = data;
  }

  initFlags() {
    this.partnerDatabaseId = this.partnerData.account.databaseId;
    this.accountType = this.partnerData.account.type;
    this.partnerType = this.partnerData.account.partnerType;
    this.subAccounts = this.partnerData.account.subAccounts || [];

    this.flags.hasSubAccounts = !!this.partnerData.account.subAccounts?.length;
    this.flags.hasCustomer = !!this.partnerData.customer;
    this.flags.hasAffiliateLink = _hasAffiliateLink(this.partnerData.account);
    this.flags.hasInAffiliatePartners = !!this.partnerData.affiliatePartner;
    this.flags.hasAffiliateCommissionType = !!this.partnerData.affiliateCommission;
  }

  getDatabaseId() {
    return this.partnerDatabaseId;
  }

  getCustomerId() {
    return this.partnerData.customer.customer_id ?? null;
  }

  getAccountType() {
    return this.accountType;
  }

  getPartnerType() {
    return this.partnerType;
  }

  getFlags() {
    return this.flags;
  }

  getNofSubaccounts() {
    return this.subAccounts.length;
  }

  hasAffiliateLink() {
    return this.flags.hasAffiliateLink;
  }

  hasInAffiliatePartners() {
    return this.flags.hasInAffiliatePartners;
  }

  hasAffiliateCommissionType() {
    return this.flags.hasAffiliateCommissionType;
  }

  hasCustomer() {
    return this.flags.hasCustomer;
  }

  hasSubaccounts() {
    return this.flags.hasSubAccounts;
  }

  getPartnerAccountData() {
    return this.partnerData;
  }

  getFirstSubAccountData() {
    return this.firstSubData;
  }

  getSupportedConverting() {
    if (!this.partnerType) {
      return [];
    }

    if (this.partnerType === PARTNER_TYPES.RESELLER && this.getNofSubaccounts() <= 1) {
      return [PARTNER_TYPES.AGENCY, PARTNER_TYPES.NORMAL];
    }

    return !Object.values(PARTNER_TYPES).includes(this.partnerType)
      ? []
      : _getSupportedConverting(this.partnerType);
  }

  isValidPartnerType() {
    if (!Object.values(PARTNER_TYPES).includes(this.partnerType)) {
      return false;
    }

    if (this.partnerType === PARTNER_TYPES.AGENCY && this.hasSubaccounts()) {
      return false;
    }

    if (this.partnerType === PARTNER_TYPES.RESELLER && this.hasAffiliateLink()) {
      return false;
    }

    return true;
  }
}

module.exports = { PartnerTypeData };
