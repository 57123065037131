import Vue from 'vue';
import VueApollo from 'vue-apollo';
import store from '@/store';
import { ApolloClient } from 'apollo-client';
import { ApolloLink, concat } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { graphqlUrl, graphqlUrlIncreasedTimeout } from './config/url';

const graphqlApi = graphqlUrl;

const showGraphqlDebugMsg = process.env.VUE_APP_GRAPHQL_DEBUG_MSG === 'true';
console.log('showGraphqlDebugMsg', showGraphqlDebugMsg);
console.log('api', graphqlApi);
const httpLink = new HttpLink({
  uri: graphqlApi,
  credentials: 'include',
});

const httpLinkIncreasedTimeout = new HttpLink({
  uri: graphqlUrlIncreasedTimeout,
  credentials: 'include',
});

const afterwareLink = new ApolloLink((operation, forward) => {
  console.log(`starting request for ${operation.operationName}`);
  return forward(operation).map((data) => {
    console.log(`ending request for ${operation.operationName}`);
    return data;
  });
});

const errorLink = onError(({ networkError }) => {
  if (networkError && networkError.statusCode === 401) {
    store.commit('logout');
  }
});

const cache = new InMemoryCache({
  // dataIdFromObject: object => object._id
  addTypename: false,
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

const apolloClient = new ApolloClient({
  link:
    showGraphqlDebugMsg === true
      ? concat(afterwareLink.concat(errorLink), httpLink)
      : concat(errorLink, httpLink),
  cache,
  connectToDevTools: true,
  defaultOptions,
});

const apolloClientIncreasedTimeout = new ApolloClient({
  link:
    showGraphqlDebugMsg === true
      ? concat(afterwareLink.concat(errorLink), httpLinkIncreasedTimeout)
      : concat(errorLink, httpLinkIncreasedTimeout),
  cache,
  connectToDevTools: true,
  defaultOptions,
});

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
  clients: {
    increasedTimeout: apolloClientIncreasedTimeout,
  },
  watchLoading(isLoading) {
    store.state.isLoading = isLoading;
  },
});

export { apolloClient, apolloProvider };

Vue.use(VueApollo);
