const RUNTIME_CONFIG_ID = 'OMRuntimeConfig';

const parseRuntimeConfig = () => {
  const ele = document.getElementById(RUNTIME_CONFIG_ID);

  if (ele) {
    try {
      const { innerText } = ele;

      if (innerText) {
        return JSON.parse(JSON.parse(innerText));
      }
    } catch (err) {
      console.error('Failed to parse runtime configuration');
      console.log(err);
    } finally {
      ele.remove();
    }
  }

  return {};
};

export default {
  ...process.env,
  ...parseRuntimeConfig(),
};
