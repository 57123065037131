import moment from 'moment';

const USER_INFO_K_REVENUE_GOAL_KEY = 'revenueGoalAchieved';
const USER_INFO_K_LEADS_GOAL_KEY = 'leadsGoalAchieved';

const USER_INFO_OLDEST_INSTALL = 'shopifyOldestAppInstall';
const USER_INFO_PROFILE_KEYS = [
  'firstFiveConversion',
  USER_INFO_K_REVENUE_GOAL_KEY,
  USER_INFO_K_LEADS_GOAL_KEY,
];

const USER_INFO_OBJ_KEY = {
  [USER_INFO_K_REVENUE_GOAL_KEY]: 'date',
  [USER_INFO_K_LEADS_GOAL_KEY]: 'date',
};

const findOldest = (settings) => {
  if (!settings.length) return null;
  let oldestDate = moment(settings[0].date_created);
  settings.slice(1).forEach((setting) => {
    const currentDate = moment(setting.date_created);
    if (currentDate.isBefore(oldestDate)) {
      oldestDate = currentDate;
    }
  });
  return oldestDate;
};

let omAttrs = [];
let adapterInterval;

const setCheckInterval = () => {
  adapterInterval = setInterval(() => {
    if (!window.OptiMonk.Visitor) return;
    const adapter = window.OptiMonk.Visitor.createAdapter();
    omAttrs.forEach(({ key, value }) => {
      adapter.attr(key, value);
    });
    omAttrs = [];
    clearInterval(adapterInterval);
  }, 350);
};

const setVisitorAttribute = (key, value) => {
  if (typeof window.OptiMonk !== 'undefined') {
    if (!window.OptiMonk.Visitor) {
      omAttrs.push({ key, value });
      if (!adapterInterval) setCheckInterval();
      return;
    }
    if (adapterInterval) clearInterval(adapterInterval);
    const adapter = window.OptiMonk.Visitor.createAdapter();
    adapter.attr(key, value);
  }
};

export const addAppInstalledAgo = (shopifyAppSettings) => {
  const oldestDate = findOldest(shopifyAppSettings);
  if (!oldestDate) {
    return setVisitorAttribute(USER_INFO_OLDEST_INSTALL, '');
  }
  const now = moment();
  const days = now.diff(oldestDate, 'days');
  setVisitorAttribute(USER_INFO_OLDEST_INSTALL, days);
};

export const addProfileValues = (accountProfile) => {
  USER_INFO_PROFILE_KEYS.forEach((key) => {
    const value = accountProfile ? accountProfile[key] : '';
    const dataKey = USER_INFO_OBJ_KEY[key];
    const realValue = typeof value === 'object' ? value[dataKey] || value : value;
    setVisitorAttribute(key, realValue);
  });
};
