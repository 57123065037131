import { track } from '@/services/xray';
import { collectPropertiesFromElements, clearProperties } from './properties';

const PROPERTIES = {
  location: null,
  component: null,
  setting: null,
  change: null,
  group: null,
};

const _setChangeWithElementGroup = (properties) => {
  let change = properties.change;
  change = change.replace('{group}', properties.group);
  properties.change = change;
};

const _trackDragEvent = (targetElement, event) => {
  const properties = {
    ...PROPERTIES,
  };
  collectPropertiesFromElements(targetElement, properties, event);
  if (!properties.component || !properties.location || !properties.setting) {
    return null;
  }
  _setChangeWithElementGroup(properties);
  clearProperties(properties);
  track('drag', properties);
};

const _initState = (state, key, element) => {
  state.key = key;
  state.element = element;
  state.dropped = false;
};

const _changeStatus = (state, status) => {
  state.status = status;
  if (state.status === 'drag') {
    _trackDragEvent(state.element, 'drag');
  }
  if (state.status === 'end' && state.dropped) {
    _trackDragEvent(state.element, 'drop');
  }
  if (state.status === 'end' && !state.dropped) {
    _trackDragEvent(state.element, 'miss');
  }
};

const createDragAndDropTracker = () => {
  const state = { key: null, element: null, status: null, dropped: null };

  const start = (key, element) => {
    _initState(state, key, element);
    _changeStatus(state, 'init');
  };

  const setDrag = () => {
    _changeStatus(state, 'drag');
  };

  const setDrop = () => {
    state.dropped = true;
    _changeStatus(state, 'drop');
  };

  const end = () => {
    _changeStatus(state, 'end');
  };

  return {
    start,
    setDrag,
    setDrop,
    end,
  };
};

const dragAndDropTracker = {
  create: createDragAndDropTracker,
};

export { dragAndDropTracker };
