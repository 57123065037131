import OmComponent from './OmComponent';
import { DesktopBorder, DesktopShadow } from '../coloring';

export default class OmDivider extends OmComponent {
  constructor(elements, property, palette) {
    super(elements, palette);

    switch (property) {
      case 'desktop.border.color':
        return this.createColorInstance(DesktopBorder);
      case 'desktop.shadow.color':
        return this.createColorInstance(DesktopShadow);
      default:
        throw new Error(`OmDivider: unknown property ${property}`);
    }
  }
}
