<template lang="pug">
.om-mock-body(@click="deselectAll" :class="{ mobile: mobilePreview }")
  .om-mock-header
  .om-mock-main(:class="layout" :style="{ height }")
    .om-mock-col.om-mock-col-side(v-show="!mobilePreview")
    .om-mock-col.om-mock-col-center(@click="stop")
      slot
    .om-mock-col.om-mock-col-side(v-show="!mobilePreview")
  .om-mock-footer
    .om-mock-footer-col(v-show="!mobilePreview")
    .om-mock-footer-col.om-mock-footer-col-center
    .om-mock-footer-col(v-show="!mobilePreview")
</template>
<script>
  import { mapMutations } from 'vuex';

  const LAYOUT_WIDE_BANNER = 'layout-wide-banner';

  export const MockLayouts = {
    LAYOUT_WIDE_BANNER,
  };
  export const DefaultLayout = LAYOUT_WIDE_BANNER;

  export default {
    props: {
      layout: { type: String, required: true },
      mobilePreview: { type: Boolean, required: false },
      height: { type: String },
    },
    data() {
      return {
        MockLayouts,
      };
    },
    methods: {
      ...mapMutations(['deselectAll']),
      stop(e) {
        e.stopPropagation();
      },
    },
  };
</script>
<style lang="sass">
  .template-prev
    .om-mock-body
      &.mobile
        width: calc(100% - 36px)
        height: calc(100% - 120px)
        border-radius: 15px
        overflow-x: hidden
        position: absolute
        top: 60px
        left: 16px
        right: 20px
        bottom: 0
        padding: 0 0.875em
        .om-mock-main
          .om-mock-col
            height: 100%
  .template-prev-mobile .om-mock-body iframe
    height: inherit
    width: inherit
</style>
