const frameStore = () => {
  const workspace = document.getElementById('workspaceFrame');
  if (!workspace) {
    return;
  }
  return workspace.contentWindow.om?.store;
};

export default {
  namespaced: true,
  getters: {
    template: () => {
      const store = frameStore();
      if (!store) return false;

      return store.state.template;
    },
    isGamificationTemplate: (_, getters) => {
      const template = getters.template;

      if (!template) {
        return false;
      }
      const gamificationTypes = ['OmLuckyWheel', 'OmPickAPresent', 'OmScratchCard']; // TODO replace with the libraries/template-helper constants

      return template.elements.some(({ type }) => gamificationTypes.includes(type));
    },
  },
};
