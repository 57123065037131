const isOnePager = (path) => ['login', 'registration'].includes(path.name);
const isVariantEdit = (path) => path.name === 'variant_edit';
const isDomains = (path) => path.name === 'domains';
const isCampaignsPage = (path) => path.name === 'campaigns';
const isNewCampaignsPage = (path) => path.name === 'new_campaign';
const isLeadsPage = (path) => path.name === 'subscribers';
const isReportsPage = (path) => path.name === 'reports';
const isDashboardPage = (path) => path.name === 'dashboard';
const isOAuthLoginPage = (path) => path.name === 'oauth_login';
const isOnboarding = (path) => path.name?.includes('onboarding');
const isNonWizard = (path) => path.name?.includes('non-wizard');
const isAgencyBenefits = (path) => path.name?.includes('benefits');
const isInsertCode = (path) => path.name?.includes('insert_code');
const isTemplateManager = (path) => path.name?.includes('template_manager');
const isMobileRedirect = (path) => path.name?.includes('mobile_redirect');
const isStillOnMobile = (path) => path.name?.includes('still_on_mobile');
const isBiggerOnboarding = (path) => ['onboarding', 'onboarding_goal'].includes(path.name);

const routeClassesAndStyles = [
  { condition: isOnePager, className: 'auth-page', style: ['body', 'overflow', 'auto'] },
  { condition: isCampaignsPage, className: 'campaigns-page' },
  { condition: isNewCampaignsPage, className: 'new-campaigns-page' },
  { condition: isLeadsPage, className: 'leads-page' },
  { condition: isReportsPage, className: 'reports-page' },
  { condition: isDashboardPage, className: 'dashboard-page' },
  { condition: isVariantEdit, style: ['html', 'overflow', 'hidden'] },
  { condition: isDomains, className: 'brand-domains-subpage' },
  { condition: isOAuthLoginPage, className: 'oauth-login-page' },
  { condition: isOnboarding, className: 'om-onboarding-page' },
  { condition: isAgencyBenefits, className: 'om-onboarding-page' },
  { condition: isNonWizard, className: 'om-onboarding-page' },
  { condition: isBiggerOnboarding, className: 'om-onboarding-page-bigger' },
  { condition: isInsertCode, className: 'om-insert-code-page' },
  { condition: isTemplateManager, className: 'brand-template-manager-page' },
  { condition: isMobileRedirect, className: 'brand-mobile-redirect' },
  { condition: isStillOnMobile, className: 'brand-still-on-mobile' },
];

const addClassToHtml = (className) => {
  document.querySelector('html').classList.add(className);
};

const removeClassFromHtml = (className) => {
  document.querySelector('html').classList.remove(className);
};

const setStyleOn = (element, property, value) => {
  document.querySelector(element).style[property] = value;
};

const removeStyleFrom = (element, property) => {
  document.querySelector(element).style.removeProperty(property);
};

const addRouteClassesAndStyles = (toPath) => {
  for (const rule of routeClassesAndStyles) {
    if (rule.condition(toPath)) {
      if (rule.className) {
        addClassToHtml(rule.className);
      }
      if (rule.style) {
        const [element, property, value] = rule.style;
        setStyleOn(element, property, value);
      }
    }
  }
};

const removeRouteClassesAndStyles = (toPath) => {
  for (const rule of routeClassesAndStyles) {
    if (!rule.condition(toPath)) {
      if (rule.className) {
        removeClassFromHtml(rule.className);
      }
      if (rule.style) {
        const [element, property] = rule.style;
        removeStyleFrom(element, property);
      }
    }
  }
};

export { addRouteClassesAndStyles, removeRouteClassesAndStyles };
