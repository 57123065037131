export const WIZARD_NAME = 'smart-wizard-brandkit';

export const LS_WIZARD_PATH = (databaseId) => `wizard-saved-path-${databaseId}`;

export const multiColorThemes = [
  'Colorful',
  'Monochrome',
  'Clean',
  'Photographic',
  'Minimal',
  'Modern',
  'Prestige',
  'Energetic',
];
