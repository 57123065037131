const ELEMENTS = {
  OmPage: 'OmPage',
  OmRow: 'OmRow',
  OmCol: 'OmCol',
  OmText: 'OmText',
  OmButton: 'OmButton',
  OmInput: 'OmInput',
  OmRadio: 'OmRadio',
  OmCheckbox: 'OmCheckbox',
  OmDropdown: 'OmDropdown',
  OmFeedback: 'OmFeedback',
  OmTextarea: 'OmTextarea',
  OmSurvey: 'OmSurvey',
  OmSpacer: 'OmSpacer',
  OmDivider: 'OmDivider',
  OmCountdown: 'OmCountdown',
  OmCoupon: 'OmCoupon',
  OmProduct: 'OmProduct',
  OmSocial: 'OmSocial',
  OmVideo: 'OmVideo',
  OmPickAPresent: 'OmPickAPresent',
  OmLuckyWheel: 'OmLuckyWheel',
  OmScratchCard: 'OmScratchCard',
  OmCloseButton: 'OmCloseButton',
  OmFloatingImage: 'OmFloatingImage',
  OmImage: 'OmImage',
};

const QUILL_EXCEPTION = 'data.text';

const DEVICES = { DESKTOP: 'desktop', MOBILE: 'mobile' };
const SUB_ELEMENTS = `subElements`;
const baseProperties = [DEVICES.DESKTOP, DEVICES.MOBILE];

const FONT_FAMILY_PROPERTY = 'fontFamily';
const BORDER_PROPERTY = 'border.radius';

const _fontPropertyGenerator = (font = '$device') => {
  if (Array.isArray(font)) {
    return {
      font: font.map((f) => `${f}.${FONT_FAMILY_PROPERTY}`),
    };
  }

  return {
    font: `${font}.${FONT_FAMILY_PROPERTY}`,
  };
};

const _radiusPropertyGenerator = (radius = '$device') => {
  if (Array.isArray(radius)) {
    return radius.map((r) => `${r}.${BORDER_PROPERTY}`);
  }
  return `${radius}.${BORDER_PROPERTY}`;
};

const radiusProperties = (elementType) => {
  if (
    ![ELEMENTS.OmSurvey, ELEMENTS.OmCountdown, ELEMENTS.OmCoupon, ELEMENTS.OmProduct].includes(
      elementType,
    )
  ) {
    return _radiusPropertyGenerator();
  }

  const elements = {
    [ELEMENTS.OmSurvey]: _radiusPropertyGenerator(`${SUB_ELEMENTS}.option.$device`),
    [ELEMENTS.OmCountdown]: _radiusPropertyGenerator(`${SUB_ELEMENTS}.number.$device`),
    [ELEMENTS.OmCoupon]: _radiusPropertyGenerator(),
    [ELEMENTS.OmProduct]: _radiusPropertyGenerator([
      `${SUB_ELEMENTS}.button.$device`,
      `${SUB_ELEMENTS}.image.$device`,
    ]),
  };

  return elements[elementType] ?? null;
};
const fontProperties = (elementType) => {
  if (
    ![
      ELEMENTS.OmSurvey,
      ELEMENTS.OmCountdown,
      ELEMENTS.OmCoupon,
      ELEMENTS.OmProduct,
      ELEMENTS.OmSocial,
      ELEMENTS.OmPickAPresent,
    ].includes(elementType)
  ) {
    return _fontPropertyGenerator();
  }

  const elements = {
    [ELEMENTS.OmSurvey]: _fontPropertyGenerator(`${SUB_ELEMENTS}.option.$device`),
    [ELEMENTS.OmCountdown]: _fontPropertyGenerator(`${SUB_ELEMENTS}.shared.$device`),
    [ELEMENTS.OmCoupon]: _fontPropertyGenerator(`${SUB_ELEMENTS}.couponText.$device`),
    [ELEMENTS.OmProduct]: _fontPropertyGenerator([
      `${SUB_ELEMENTS}.buttonText.$device`,
      `${SUB_ELEMENTS}.nameText.$device`,
      `${SUB_ELEMENTS}.oldPriceText.$device`,
      `${SUB_ELEMENTS}.priceText.$device`,
      `${SUB_ELEMENTS}.skuText.$device`,
    ]),
    [ELEMENTS.OmSocial]: _fontPropertyGenerator('$device.link'),
    [ELEMENTS.OmPickAPresent]: _fontPropertyGenerator(['$device.subtitle', '$device.title']),
  };

  return elements[elementType] ?? null;
};

const getStylePropertyFromType = (type) => {
  const elements = {
    [ELEMENTS.OmText]: [...baseProperties, QUILL_EXCEPTION],
    [ELEMENTS.OmButton]: [...baseProperties, QUILL_EXCEPTION],
    [ELEMENTS.OmInput]: [...baseProperties],
    [ELEMENTS.OmRadio]: [
      ...baseProperties,
      `${SUB_ELEMENTS}.question.${DEVICES.DESKTOP}`,
      `${SUB_ELEMENTS}.question.${DEVICES.MOBILE}`,
    ],
    [ELEMENTS.OmCheckbox]: [
      ...baseProperties,
      `${SUB_ELEMENTS}.question.${DEVICES.DESKTOP}`,
      `${SUB_ELEMENTS}.question.${DEVICES.MOBILE}`,
    ],
    [ELEMENTS.OmDropdown]: [...baseProperties],
    [ELEMENTS.OmFeedback]: [
      ...baseProperties,
      `${SUB_ELEMENTS}.icon.${DEVICES.DESKTOP}`,
      `${SUB_ELEMENTS}.icon.${DEVICES.MOBILE}`,
      `${SUB_ELEMENTS}.question.${DEVICES.DESKTOP}`,
      `${SUB_ELEMENTS}.question.${DEVICES.MOBILE}`,
    ],
    [ELEMENTS.OmTextarea]: [...baseProperties],
    [ELEMENTS.OmSurvey]: [
      ...baseProperties,
      `${SUB_ELEMENTS}.option.${DEVICES.DESKTOP}`,
      `${SUB_ELEMENTS}.option.${DEVICES.MOBILE}`,
    ],
    [ELEMENTS.OmSpacer]: [...baseProperties],
    [ELEMENTS.OmDivider]: [...baseProperties],
    [ELEMENTS.OmCountdown]: [
      ...baseProperties,
      `${SUB_ELEMENTS}.label.${DEVICES.DESKTOP}`,
      `${SUB_ELEMENTS}.number.${DEVICES.DESKTOP}`,
      `${SUB_ELEMENTS}.separator.${DEVICES.DESKTOP}`,
      `${SUB_ELEMENTS}.shared.${DEVICES.DESKTOP}`,
    ],
    [ELEMENTS.OmCoupon]: [...baseProperties, `${SUB_ELEMENTS}.couponText.${DEVICES.DESKTOP}`],
    [ELEMENTS.OmProduct]: [
      ...baseProperties,
      `${SUB_ELEMENTS}.button.${DEVICES.DESKTOP}`,
      `${SUB_ELEMENTS}.button.${DEVICES.MOBILE}`,
      `${SUB_ELEMENTS}.buttonText.${DEVICES.DESKTOP}`,
      `${SUB_ELEMENTS}.image.${DEVICES.DESKTOP}`,
      `${SUB_ELEMENTS}.image.${DEVICES.MOBILE}`,
      `${SUB_ELEMENTS}.name.${DEVICES.DESKTOP}`,
      `${SUB_ELEMENTS}.name.${DEVICES.MOBILE}`,
      `${SUB_ELEMENTS}.nameText.${DEVICES.DESKTOP}`,
      `${SUB_ELEMENTS}.oldPrice.${DEVICES.DESKTOP}`,
      `${SUB_ELEMENTS}.oldPrice.${DEVICES.MOBILE}`,
      `${SUB_ELEMENTS}.oldPriceText.${DEVICES.DESKTOP}`,
      `${SUB_ELEMENTS}.price.${DEVICES.DESKTOP}`,
      `${SUB_ELEMENTS}.price.${DEVICES.MOBILE}`,
      `${SUB_ELEMENTS}.priceText.${DEVICES.DESKTOP}`,
      `${SUB_ELEMENTS}.sku.${DEVICES.DESKTOP}`,
      `${SUB_ELEMENTS}.sku.${DEVICES.MOBILE}`,
      `${SUB_ELEMENTS}.skuText.${DEVICES.DESKTOP}`,
    ],
    [ELEMENTS.OmSocial]: [...baseProperties, 'data.socialType', 'data.socialAlignment'],
    [ELEMENTS.OmVideo]: [...baseProperties],
  };
  return elements[type] ?? [];
};

module.exports = {
  ELEMENTS,
  QUILL_EXCEPTION,
  radiusProperties,
  fontProperties,
  getStylePropertyFromType,
};
