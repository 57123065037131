/**

STORE/RETRIEVE DATA IN/FROM BROWSER STORAGE

store
  store data in a browser storage

  arguments:
    - type: type of storage, required [localStorage, sessionStorage]
    - name: key in storage, required
    - value: any valid JS value, required
    - stringify: need to stringify the given value, default: true

retrieve
  get data from a browser storage,
  if it was stringified then the parsed value will be returned,
  otherwise string or null if the key not exists.

  arguments:
    - type: type of storage, required [localStorage, sessionStorage]
    - name: key in storage, required
*/

const JSON_FLAG = '#stringified';
const STORAGE_LOCAL = 'localStorage';
const STORAGE_SESSION = 'sessionStorage';

const _getStorage = (type) => {
  const storage = window && window[type];

  if (storage && storage.getItem && storage.setItem && storage.removeItem) {
    return storage;
  }

  return false;
};

const _stringify = (value) => {
  return `${JSON.stringify(value)}${JSON_FLAG}`;
};

const _parse = (value) => {
  if (value) {
    return value.indexOf(JSON_FLAG) > -1 ? JSON.parse(value.replace(JSON_FLAG, '')) : value;
  }

  return null;
};

const store = (type, name, value, stringify = true) => {
  const storage = _getStorage(type);

  if (storage) {
    const data = stringify ? _stringify(value) : value;

    storage.setItem(name, data);

    return data;
  }

  return false;
};

const retrieve = (type, name) => {
  const storage = _getStorage(type);

  if (storage) {
    const value = storage.getItem(name);

    return _parse(value);
  }

  return undefined;
};

const remove = (type, name) => {
  const storage = _getStorage(type);

  if (storage) {
    storage.removeItem(name);
  }
};

export { store, retrieve, remove, STORAGE_LOCAL, STORAGE_SESSION };
