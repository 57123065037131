import { track } from '@/services/xray';
import { clearProperties, getContextProperties } from './properties';

const PROPERTIES = {
  fromWorkspace: {
    location: 'workspace',
    component: null,
    setting: 'image-position-type-change',
    change: 'x.elements.{component}.image.positionType',
    value: null,
  },
  fromSidebar: {
    location: 'sidebar',
    component: null,
    setting: 'image-position-type-change',
    change: 'x.elements.{component}.image.positionType',
    value: null,
  },
};

const _setComponent = (properties, component) => {
  let change = properties.change;
  change = change.replace('{component}', component);
  properties.change = change;
  properties.component = component;
};

const trackPositionTypeChange = (type, component, value) => {
  if (!Object.keys(PROPERTIES).includes(type)) {
    return;
  }
  const properties = {
    ...PROPERTIES[type],
    ...getContextProperties()
  };
  _setComponent(properties, component);
  properties.value = value;
  clearProperties(properties);
  track('editor_click', properties);
};

const imagePositionTracker = {
  trackPositionTypeChange,
};

export { imagePositionTracker };
