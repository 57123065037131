import { translate, isDefined } from '../../../utils';
import { initMobileStyles } from '../util';

const _c = (v) => JSON.parse(JSON.stringify(v));

const initFontStyle = (name, style, global) => {
  style[name] = style[name] || {};

  style[name].lineHeight = isDefined(style[name].lineHeight) ? style[name].lineHeight : 1;
  style[name].fontWeight = isDefined(style[name].fontWeight)
    ? style[name].fontWeight
    : style[name].textWeight
    ? 700
    : 300;

  if (style[name].fontFamily) return;
  style[name].fontFamily = isDefined(style[name].fontFamily)
    ? style[name].fontFamily
    : global.canvas.fontFamily;
  style[name].fontSize = isDefined(style[name].fontSize)
    ? style[name].fontSize
    : global.text.fontSize;
  style[name].textWeight = isDefined(style[name].textWeight) ? style[name].textWeight : false;
  style[name].fontItalic = isDefined(style[name].fontItalic) ? style[name].fontItalic : false;
  style[name].textDecoration = isDefined(style[name].textDecoration)
    ? style[name].textDecoration
    : false;
  style[name].color = isDefined(style[name].color) ? style[name].color : global.text.color;
  style[name].textAlign = isDefined(style[name].textAlign) ? style[name].textAlign : 'center';
};

const initBackground = (style, global) => {
  if (global && !style.background) {
    style.background = _c(global);
  } else if (!style.background || !style.background.color) {
    style.background = {};
    style.background.color = -1;
    style.background.color2 = '#fff';
    style.background.type = 'transparent';
    style.background.gradientType = 'linear';
    style.background.linearDirection = 45;
    style.background.imagePosition = 'cover';
    style.background.imageAlign = 'center';
    style.background.imageHorizontalAlign = 'center';
    style.background.imageRepeat = 'no-repeat';
  }
};

const initBorderAndShadow = (style, global) => {
  if (global && !style.shadow) {
    style.shadow = _c(global.shadow);
  } else if (!style.shadow || !style.shadow.type) {
    style.shadow = {};
    style.shadow.type = 'none';
    style.shadow.color = '#000';
  }

  if (global && !style.border) {
    style.border = _c(global.border);
  } else if (!style.border || !style.border.selectedBorder) {
    style.border = {};
    style.border.selectedBorder = 'no';
    style.border.type = 'solid';
    style.border.color = 0;
    style.border.width = 2;
    style.border.radius = [null, null, null, null];
    style.border.radiusChained = true;
  }
};

const initMargin = (style, global) => {
  if (global && !style.manualMargin) {
    style.manualMargin = _c(global);
  } else if (!style.manualMargin || !style.manualMargin.top) {
    style.manualMargin = {};
    style.manualMargin.top = 0;
    style.manualMargin.left = 0;
    style.manualMargin.right = 0;
    style.manualMargin.bottom = 0;
  }
};

const initPadding = (style, global) => {
  if (global && !style.manualPadding) {
    style.manualMargin = _c(global);
  } else if (!style.manualPadding || !style.manualPadding.top) {
    style.manualPadding = {};
    style.manualPadding.allSides = true;
    style.manualPadding.top = 0;
    style.manualPadding.left = 0;
    style.manualPadding.right = 0;
    style.manualPadding.bottom = 0;
  }
};

const initIconStyle = (style) => {
  style.color = style.color || '#FFA700';
  style.size = style.size || 40;
  style.horizontalSpacing = style.horizontalSpacing || 20;
};

const initStyle = (style, global) => {
  initBackground(style);
  initBorderAndShadow(style);
  initMargin(style);
  initPadding(style);
  initFontStyle('question', style.feedback, global);
  initIconStyle(style.feedback.icon);

  return style;
};

const initStarOptions = () => {
  const options = [];
  for (let rating = 1; rating <= 5; rating++) {
    options.push({
      key: translate(`feedbackOptions.${rating}`),
      value: rating,
      action: 'none',
      jumpTo: null,
      image: {},
    });
  }

  return options;
};

const initYesNoOptions = () => [
  {
    key: translate(`feedbackOptions.yes`),
    value: 'yes',
    action: 'none',
    jumpTo: null,
    image: {},
  },
  {
    key: translate(`feedbackOptions.no`),
    value: 'no',
    action: 'none',
    jumpTo: null,
    image: {},
  },
];

const initSmileyOptions = () => [
  {
    key: translate(`feedbackOptions.good`),
    value: 'good',
    action: 'none',
    jumpTo: null,
    image: {},
  },
  {
    key: translate(`feedbackOptions.neutral`),
    value: 'neutral',
    action: 'none',
    jumpTo: null,
    image: {},
  },
  {
    key: translate(`feedbackOptions.bad`),
    value: 'bad',
    action: 'none',
    jumpTo: null,
    image: {},
  },
];

export const initOptions = (displayType) => {
  if (displayType === 'stars') {
    return initStarOptions();
  }
  if (displayType === 'yesno') {
    return initYesNoOptions();
  }
  if (displayType === 'smiley') {
    return initSmileyOptions();
  }
};
const initData = (data) => {
  data.feedback = data.feedback || {};
  data.feedback.question = data.feedback.question || '';
  data.displayType = data.displayType || 'stars';
  data.onClick = data.onClick || 'none';
  data.choosePageBasedOn = data.choosePageBasedOn || 'specificPage';
  data.jumpTo = data.jumpTo || 'none';
  data.iconType = data.iconType || 'vector';

  data.form = data.form || {};
  data.form.customSettings = data.form.customSettings || {};
  if (!data.form.customSettings.options || data.form.customSettings.options.length === 0) {
    data.form.customSettings.options = initOptions(data.displayType);
  }

  return data;
};
export default (element, template) => {
  element.data = element.data || {};
  element.data.type = 'feedback';
  element.data = initData(element.data);
  element.style = element.style || {};
  element.style.feedback = element.style.feedback || {};
  element.style.feedback.icon = element.style.feedback.icon || {};
  element.style = initStyle(element.style, template.style);

  if (element.data.feedback.question.length) {
    element.style.feedback.question.verticalSpacing = isDefined(
      element.style.feedback.question.verticalSpacing,
    )
      ? element.style.feedback.question.verticalSpacing
      : 15;
  } else {
    element.style.feedback.question.verticalSpacing = 0;
  }

  initMobileStyles(element, ['manualMargin', 'manualPadding']);

  return element;
};
