const { PartnerTypeByOldLogics } = require('./partnerTypeByOldLogics');
const { PARTNER_TYPES } = require('./enums');

class PartnerTypeDetails {
  constructor(partnerTypeData) {
    this.partnerTypeData = partnerTypeData;
  }

  getDetails() {
    const data = this.partnerTypeData;
    const oldLogic = new PartnerTypeByOldLogics(data);
    const partnerTypeByOldLogic = oldLogic.getPartnerType();
    const partnerType = data.getPartnerType();

    const supportedConverting =
      partnerType && partnerType !== PARTNER_TYPES.CUSTOM
        ? data.getSupportedConverting()
        : this._getSupportedConvertingByOldLogics(oldLogic);

    return {
      accountType: data.getAccountType(),
      partnerType,
      partnerTypeByOldLogic,
      flags: data.getFlags(),
      nofSubaccounts: data.getNofSubaccounts(),
      supportedConverting,
    };
  }

  isValidPartnerType() {
    const data = this.partnerTypeData;
    return data.isValidPartnerType();
  }

  _getSupportedConvertingByOldLogics(oldLogic) {
    const partnerTypeByOldLogic = oldLogic.getPartnerType();
    const partnerType = this.partnerTypeData.getPartnerType();
    return partnerTypeByOldLogic !== partnerType ? [partnerTypeByOldLogic] : [];
  }
}

module.exports = { PartnerTypeDetails };
