import { createSmartTagOptions } from '@om/smart-tags/src/helper';
import { groups, groupWithSPPO } from '@om/smart-tags/src/groups';

const fontSizeOptions = [];
for (let i = 8; i <= 120; i++) {
  if (i <= 70 || i % 5 === 0) {
    fontSizeOptions.push({ value: i, label: `${i}px`, px: i });
  }
}

const round = (digit) => parseFloat(digit.toPrecision(3));
const lineHeightOptions = [];
for (let i = 0.8; i < 1.5; i += 0.05) {
  lineHeightOptions.push({ value: round(i), label: round(i) });
}

for (let i = 1.5; i <= 6; i += 0.5) {
  lineHeightOptions.push({ value: round(i), label: round(i) });
}

const fontFormatOptions = [
  { value: 'abril-fatface', label: 'Abril Fatface' },
  { value: 'amatic-sc', label: 'Amatic SC' },
  { value: 'alegreya-sans-sc', label: 'Alegreya Sans SC' },
  { value: 'allura', label: 'Allura' },
  { value: 'archivo-narrow', label: 'Archivo Narrow' },
  { value: 'baloo', label: 'Baloo 2' },
  { value: 'barlow', label: 'Barlow' },
  { value: 'barlow-condensed', label: 'Barlow Condensed' },
  { value: 'berkshire-swash', label: 'Berkshire Swash' },
  { value: 'biorhyme', label: 'BioRhyme' },
  { value: 'dancing-script', label: 'Dancing Script' },
  { value: 'engagement', label: 'Engagement' },
  { value: 'great-vibes', label: 'Great Vibes' },
  { value: 'gruppo', label: 'Gruppo' },
  { value: 'josefin-sans', label: 'Josefin Sans' },
  { value: 'kanit', label: 'Kanit' },
  { value: 'lato', label: 'Lato' },
  { value: 'lora', label: 'Lora' },
  { value: 'luckiest-guy', label: 'Luckiest Guy' },
  { value: 'meddon', label: 'Meddon' },
  { value: 'merriweather', label: 'Merriweather' },
  { value: 'mirza', label: 'Mirza' },
  { value: 'montserrat', label: 'Montserrat' },
  { value: 'muli', label: 'Muli' },
  { value: 'mr-dafoe', label: 'Mr Dafoe' },
  { value: 'noto-sans', label: 'Noto Sans' },
  { value: 'old-standard', label: 'Old Standard TT' },
  { value: 'open-sans', label: 'Open Sans' },
  { value: 'oswald', label: 'Oswald' },
  { value: 'quicksand', label: 'Quicksand' },
  { value: 'pacifico', label: 'Pacifico' },
  { value: 'playfair-display', label: 'Playfair Display' },
  { value: 'poppins', label: 'Poppins' },
  { value: 'raleway', label: 'Raleway' },
  { value: 'ranchers', label: 'Ranchers' },
  { value: 'roboto', label: 'Roboto' },
  { value: 'roboto-slab', label: 'Roboto Slab' },
  { value: 'rubik', label: 'Rubik' },
  { value: 'sarina', label: 'Sarina' },
  { value: 'sorts-mill-goudy', label: 'Sorts Mill Goudy' },
  { value: 'source-sans-pro', label: 'Source Sans Pro' },
  { value: 'stalemate', label: 'Stalemate' },
  { value: 'titillium-web', label: 'Titillium Web' },
  { value: 'ubuntu', label: 'Ubuntu' },
  { value: 'work-sans', label: 'Work Sans' },
  { value: 'yesteryear', label: 'Yesteryear' },
];

const fontSpacingOptions = [];
for (let i = 2; i <= 20; i += 2) {
  fontSpacingOptions.push({ value: `${i}px`, label: i });
}

const fontWeightOptions = [
  { value: '300', label: 'Light' },
  { value: '400', label: 'Normal' },
  { value: '500', label: 'Medium' },
  { value: '600', label: 'Semi Bold' },
  { value: '700', label: 'Bold' },
  { value: '800', label: 'Extra Bold' },
  { value: '900', label: 'Black' },
];

const smartTagsOptions = createSmartTagOptions();
const smartTagsGroups = (ppoVariableNames) => {
  if (!ppoVariableNames?.length) return groups;
  return groupWithSPPO;
};

const groupedOptionsInSmartTags = (ppoVariableNames, isEmbedded, isShopifyOrShoprenter) => {
  const result = {};
  const groups = smartTagsGroups(ppoVariableNames);
  for (const groupName in groups) {
    if (groupName === 'smart_product_tags' && isEmbedded) continue;
    if (groupName === 'product_and_cart' && !isShopifyOrShoprenter) continue;

    const groupFields = groups[groupName];
    const groupOptions = [];
    groupFields.forEach((field) => {
      const option = smartTagsOptions.find((o) => o.key === field);
      if (option) {
        groupOptions.push(option);
      }
    });
    groupOptions.sort((a, b) => a.key.localeCompare(b.key));
    result[groupName] = groupOptions;

    if (groupName === 'smart_product_tags') {
      ppoVariableNames.forEach((name) => {
        result[groupName].push({ key: `[[smart_product_tag:${name}]]`, value: name });
      });
    }
  }

  return result;
};

export {
  fontFormatOptions,
  fontSizeOptions,
  fontSpacingOptions,
  fontWeightOptions,
  lineHeightOptions,
  smartTagsOptions,
  groupedOptionsInSmartTags,
};
