const moment = require('moment');

const freemiumPackages = ['free', 'essential', 'growth', 'premium'];
const notFreemiumPackages = ['trial', 'bronze', 'silver', 'gold', 'platinum', 'diamond'];

const fromString = (sku) => {
  // eslint-disable-next-line no-unused-vars
  const [_, language, name, periodStr] = sku.split('-');

  return {
    language,
    name,
    period: Number(periodStr),
  };
};

const isEnterpriseOrMaster = (planName) => {
  return planName.match(/MASTER|ENTERPRISE/) !== null;
};

const toString = ({ name, language, period }) => {
  return `Package-${language}-${name}-${period}`;
};

const _isCustomWithRegex = (name) => {
  return name.match(/MASTER|ENTERPRISE|AGENCY\_/) !== null;
};

const _isNonPayingWithRegex = (name) => {
  if (_isCustomWithRegex(name)) return false;
  return name.match(/FREE|TRIAL|DEMO|BELSO/) !== null;
};

const isNonPayingPackage = (packageSKU) => {
  return _isNonPayingWithRegex(packageSKU);
};

const isNonPayingPlan = (plan) => {
  const planName = (plan || '').toUpperCase();
  return _isNonPayingWithRegex(planName);
};

const isFreemiumPackage = (packageName) => {
  packageName = (packageName || '').toLowerCase();
  return (
    freemiumPackages.includes(packageName) ||
    packageName.includes('master') ||
    packageName.includes('demo')
  );
};

const isFreemiumSKU = (packageSKU) => {
  const details = fromString(packageSKU);

  return isFreemiumPackage(details.name);
};

const isNotFreemiumPackage = (packageName, paying = false) => {
  packageName = (packageName || '').toLowerCase();
  const packages = paying ? notFreemiumPackages.slice(1) : notFreemiumPackages;
  return packages.includes(packageName) || packageName.includes('belso');
};

const isCustomPackage = (packageName) => {
  packageName = (packageName || '').toUpperCase();
  return _isCustomWithRegex(packageName);
};

const _getPeriodIntoName = (language, from, till) => {
  const format = language === 'HU' ? 'YYYY. MM. DD.' : 'DD/MM/YYYY';
  return `${moment.utc(from).format(format)} - ${moment.utc(till).format(format)}`;
};

const getProductNameBySku = (sku) => {
  const { name: packageName } = fromString(sku);
  return packageName
    .toUpperCase()
    .replace('_', ' ')
    .replace(/([^\d]+)\d+/, '$1')
    .replace(/^(ENTERPRISE|MASTER)(.*)+/, '$1');
};

const getOrderProductName = ({
  sku,
  from = null,
  till = null,
  quantity = 1,
  defaultName = null,
}) => {
  if (!sku.includes('Package-')) {
    return defaultName ?? sku;
  }

  const { name, period, language } = fromString(sku);
  const plan = name
    .toUpperCase()
    .replace('_', ' ')
    .replace(/([^\d]+)\d+/, '$1')
    .replace(/^(ENTERPRISE|MASTER|AGENCY)(.*)+/, '$1');

  let periodString = ' ';

  if (period === 12) {
    periodString += language === 'HU' ? `(${1 * quantity} év)` : `(${1 * quantity} year)`;
  } else {
    periodString +=
      language === 'HU'
        ? `(${period * quantity} hónap)`
        : `(${period * quantity} ${period * quantity > 1 ? 'months' : 'month'})`;
  }

  if (from && till) {
    periodString += ` (${_getPeriodIntoName(language, from, till)})`;
  }

  if (isCustomPackage(name)) {
    const customSuffix = name
      .toUpperCase()
      .replace(/(ENTERPRISE|MASTER|AGENCY)_/, '')
      .replace(/_/g, '-');
    return language === 'HU'
      ? `${plan} csomag (${customSuffix})${periodString}`
      : `${plan} plan (${customSuffix})${periodString}`;
  }

  return language === 'HU' ? `${plan} csomag${periodString}` : `${plan} plan${periodString}`;
};

module.exports = {
  freemiumPackages,
  notFreemiumPackages,
  fromString,
  toString,
  isNonPayingPackage,
  isNonPayingPlan,
  isFreemiumPackage,
  isFreemiumSKU,
  isNotFreemiumPackage,
  isCustomPackage,
  isEnterpriseOrMaster,
  getProductNameBySku,
  getOrderProductName,
};
