const enums = require('./enums');
const partnerTypeByOldLogics = require('./partnerTypeByOldLogics');
const partnerTypeData = require('./partnerTypeData');
const partnerTypeDetails = require('./partnerTypeDetails');
const partnerTypeService = require('./partnerTypeService');

module.exports = {
  ...enums,
  ...partnerTypeData,
  ...partnerTypeByOldLogics,
  ...partnerTypeDetails,
  ...partnerTypeService,
};
