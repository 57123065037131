/**

STORE/RETRIEVE DATA IN/FROM LOCALSTORAGE

lsStore
  store data in localstorage

  arguments:
    - name: key in storage, required
    - value: any valid JS value, required
    - stringify: need to stringify the given value, default: true

lsRetrieve
  get data from localstorage,
  if it was stringified then the parsed value will be returned,
  otherwise string or null if the key not exists.

  arguments:
    -name: key in storage, required
*/

import { store, retrieve, remove, STORAGE_LOCAL } from './browserStorage';

const lsStore = (name, value, stringify = true) => store(STORAGE_LOCAL, name, value, stringify);

const lsRetrieve = (name) => retrieve(STORAGE_LOCAL, name);

const lsRemove = (name) => remove(STORAGE_LOCAL, name);

export { lsStore, lsRetrieve, lsRemove };
