const { ELEMENTS } = require('../propertyHelper');
const { NO_TYPES } = require('../types/radiusTypes');

const getRows = (templateElements) => {
  return templateElements.filter((e) => e.type === ELEMENTS.OmRow);
};

const getColumnsOfRow = (templateElements, row) => {
  return templateElements.filter((e) => e.type === ELEMENTS.OmCol && e.rowId === row.uid);
};

const getElementsOfColumn = (templateElements, column) => {
  return templateElements.filter((e) => e.colId === column.uid);
};

const getPages = (templateElements) => {
  return templateElements.filter((el) => el.type === ELEMENTS.OmPage && !el.isTeaser);
};

const getFilteredElement = (templateElements, type, pageId) => {
  return templateElements.filter((element) => element.type === type && element.pageId === pageId);
};

const getFilteredElementWithRowId = (templateElements, type, pageId, rowId) => {
  return templateElements.filter(
    (element) => element.type === type && element.pageId === pageId && element.rowId === rowId,
  );
};

const getFirstColumnIdOfPages = (templateElements) => {
  const pages = getPages(templateElements);
  return pages.map((page) => {
    const { uid } = page;
    const rows = getFilteredElement(templateElements, ELEMENTS.OmRow, uid);
    if (!rows.length) return '';
    const [firstRow] = rows;
    const columns = getFilteredElementWithRowId(
      templateElements,
      ELEMENTS.OmCol,
      uid,
      firstRow.uid,
    );
    const [first] = columns;
    return first.uid;
  });
};

const getLastColumnIdOfPages = (templateElements) => {
  const pages = getPages(templateElements);
  return pages.map((page) => {
    const { uid } = page;
    const rows = getFilteredElement(templateElements, ELEMENTS.OmRow, uid);
    if (!rows.length) return '';
    const row = rows[rows.length - 1];
    const columns = getFilteredElementWithRowId(templateElements, ELEMENTS.OmCol, uid, row.uid);
    const last = columns[columns.length - 1];
    return last.uid;
  });
};

const getElementByRowId = (templateElements, rowId) => {
  return templateElements.filter((e) => e.rowId === rowId);
};

const getTwoOrThreeBlock = (templateElements) => {
  const rows = templateElements.filter(
    (row) =>
      row.type === ELEMENTS.OmRow &&
      Array.isArray(row.data.columnSplit) &&
      row.data.columnSplit.length > 1,
  );
  return rows;
};

const getElements = (templateElements) => {
  const elements = [];
  const rows = getRows(templateElements);
  rows.forEach((row) => {
    const columns = getColumnsOfRow(templateElements, row);
    columns.forEach((column) => {
      elements.push(getElementsOfColumn(templateElements, column));
    });
  });
  return elements;
};

const handleImageExceptions = (templateElements) => {
  const exceptions = [];
  const device = 'desktop';
  const elements = getElements(templateElements);

  elements.forEach((images) => {
    if (images.length !== 1 || images[0].type !== ELEMENTS.OmImage) return;
    images.forEach((image) => {
      const { rowId, colId, pageId, uid } = image;
      const elementsInRow = getRows(templateElements).filter((row) => row.pageId === pageId);

      // Top first image
      if (getFirstColumnIdOfPages(templateElements).includes(colId) && elementsInRow.length > 1) {
        exceptions.push({ radius: NO_TYPES.NO_BOTTOM, uid, device });
      }

      // Bottom last image
      if (getLastColumnIdOfPages(templateElements).includes(colId) && elementsInRow.length > 1) {
        exceptions.push({ radius: NO_TYPES.NO_TOP, uid, device });
      }

      if (elementsInRow.length > 1) {
        const rows = getTwoOrThreeBlock(templateElements);
        rows.forEach((row) => {
          const elements = getElementByRowId(templateElements, row.uid);
          const imageIndex = elements.findIndex((el) => el.type === ELEMENTS.OmImage);

          if (rowId === row.uid && imageIndex < 5) {
            exceptions.push({ radius: NO_TYPES.NO_LEFT, uid, device });
          }

          if (rowId === row.uid && imageIndex > 5) {
            exceptions.push({ radius: NO_TYPES.NO_RIGHT, uid, device });
          }
        });
      }
    });
  });
  return exceptions;
};

module.exports = { handleImageExceptions };
