import store from '@/store';
import { accountSetupRouteNames } from '@/router/routes/accountSetupRoutes';
import { getAccountIdFromCookie } from '@/util';

export const redirectToOnboardingBranching = (query, business = false) => {
  const businessType = business || store.state.account?.settings?.qualifications?.business;
  const { getLocale: locale } = store.getters;
  const userId = getAccountIdFromCookie();

  if (businessType === 'agency' && locale !== 'hu') {
    return {
      name: accountSetupRouteNames.AGENCY,
      params: {
        type: 'agency',
        step: 'detail',
        userId,
      },
      ...(query ? { query } : {}),
    };
  }

  return {
    name: accountSetupRouteNames.BRANCHING,
    ...(query ? { query } : {}),
    params: { userId },
  };
};

export const deletedRoutes = [
  {
    path: '/:userId/campaigns/new/:templateType?',
    name: 'new_campaign',
    redirect(to) {
      const query = { ...to.query };
      if (to.params?.templateType) {
        query.types = JSON.stringify([to.params?.templateType]);
      }
      return {
        name: 'templates',
        query,
      };
    },
    meta: { permission: ['normal', 'sub'] },
  },
  {
    path: '/:userId/brandkit-setup/:step',
    name: 'brandkit_setup',
    redirect(to) {
      return {
        name: 'onboarding_wizard',
        params: { ...to.params },
        query: { ...to.query },
      };
    },
    meta: { permission: ['normal', 'sub'], hideNav: true },
  },
];
