const { ACCOUNT_TYPES, PARTNER_TYPES } = require('./enums');

class PartnerTypeByOldLogics {
  constructor(partnerTypeData) {
    this.partnerTypeData = partnerTypeData;
  }

  getPartnerType() {
    const data = this.partnerTypeData;

    if (!data.getAccountType()) {
      return null;
    }

    if (
      data.getAccountType() === ACCOUNT_TYPES.NORMAL &&
      !data.hasAffiliateLink() &&
      data.hasCustomer()
    ) {
      return PARTNER_TYPES.NORMAL;
    }

    if (
      data.getAccountType() === ACCOUNT_TYPES.NORMAL &&
      this._hasAllAffiliateSettings() &&
      data.hasCustomer()
    ) {
      return PARTNER_TYPES.AFFILIATE;
    }

    if (
      data.getAccountType() === ACCOUNT_TYPES.AGENCY &&
      this._hasAllAffiliateSettings() &&
      data.getNofSubaccounts() <= 1 &&
      data.hasCustomer()
    ) {
      return PARTNER_TYPES.AGENCY;
    }

    if (
      data.getAccountType() === ACCOUNT_TYPES.AGENCY &&
      !data.hasAffiliateLink() &&
      data.hasCustomer()
    ) {
      return PARTNER_TYPES.RESELLER;
    }

    if (data.getAccountType() === ACCOUNT_TYPES.SUB) {
      return PARTNER_TYPES.SUBACCOUNT;
    }

    if (!data.hasCustomer()) {
      return PARTNER_TYPES.BROKEN;
    }

    return PARTNER_TYPES.CUSTOM;
  }

  _hasAllAffiliateSettings() {
    const data = this.partnerTypeData;
    if (!data.hasAffiliateLink()) return false;
    if (!data.hasInAffiliatePartners()) return false;
    // if (!data.hasAffiliateCommissionType()) return false; // it's use default commission
    return true;
  }
}

module.exports = { PartnerTypeByOldLogics };
