export default {
  path: 'sab',
  name: 'new_campaign_flow_sab',
  component: () => import('@/views/NewCampaignFlow/children/URLInput.vue'),
  children: [
    {
      path: 'plan',
      name: 'new_campaign_flow_sab_branching',
      component: () => import('@/views/NewCampaignFlow/children/sab/Branching.vue'),
      meta: { showBack: false },
    },
    {
      path: 'recommendations',
      name: 'new_campaign_flow_sab_recommendations',
      component: () => import('@/views/NewCampaignFlow/children/sab/Recommendations.vue'),
      meta: { showBack: false },
    },
  ],
};
