export const steps = {
  WELCOME: {
    path: 'welcome',
    name: 'onboarding_mobile_welcome',
  },
  USAGE: {
    path: 'interested-feature',
    name: 'onboarding_mobile_interested_feature',
  },
  INDUSTRY: {
    path: 'industry',
    name: 'onboarding_mobile_industry',
  },
  HEAR: {
    path: 'hear',
    name: 'onboarding_mobile_hear',
  },
  // INCOME: {
  //   path: 'income',
  //   name: 'onboarding_mobile_income'
  // },
  GOALS: {
    path: 'select-goal',
    name: 'onboarding_mobile_goals',
  },
  CONTACTS: {
    path: 'contact-info',
    name: 'onboarding_mobile_contact',
  },
};

const _flow = [steps.WELCOME.name, steps.USAGE.name, steps.INDUSTRY.name, steps.HEAR.name];

export const flow = (type) => {
  if (type === 'midLevel') {
    return [..._flow, steps.CONTACTS.name, steps.GOALS.name];
  }
  if (type === 'agency' || type === 'shopifyAgency') {
    return [..._flow, steps.CONTACTS.name];
  }
  return [..._flow, steps.GOALS.name];
};

export const industries = ['ecommerce', 'saas', 'blog', 'agency'];
export const stages = ['started', 'grow', 'scaling', 'established'];
export const goalsByIndustry = {
  ecommerce: ['reduce_cart_abandonment', 'capture_email', 'collect_feedback', 'recommend_products'],
  blog: ['capture_email', 'faciliate_social_sharing', 'promote_special_offers'],
  saas: ['capture_email', 'collect_feedback', 'promote_special_offers'],
};
