import { nanoid } from 'nanoid';
import { get as _get } from 'lodash-es';
import { inputElements } from '@om/editor-ssr-shared/src/utils';

const NANOBAR_MAX_IMAGE_HEIGHT = 150;
const GRID_COLUMN_COUNT = 12;

const findAncestor = (el, cls) => {
  while (el && !el.classList.contains(cls)) {
    el = el.parentElement;
  }
  return el;
};
export const _i18n = () =>
  typeof window === 'undefined'
    ? { t: () => '' }
    : window.parent && window.parent !== window
    ? window.parent.om.i18n
    : window.om.i18n;

const getQueryVariable = (variable) => {
  if (typeof window === 'undefined') return null;
  const query = window.location.search.substring(1); // remove ?
  const params = query.split('&');
  for (let i = 0; i < params.length; i++) {
    const pair = params[i].split('=');
    if (pair[0] === variable) return pair[1];
  }
  return null;
};

const inIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};
const inDocker = () => !!getQueryVariable('docker');

const frameStore = () => {
  if (global.__vue_store__) return global.__vue_store__;

  const workspace = document.getElementById('workspaceFrame');
  return workspace.contentWindow.om.store;
};

const getState = () => {
  let state;
  if (inIframe() || inDocker()) {
    state = _get(window, 'om.store.state');
  } else {
    state = frameStore().state;
  }
  return state;
};

const selectedImage = (state = undefined) => {
  state = state || getState();

  let imageId;

  const mobileImageId = _get(state, 'selectedElement.mobile.background.imageId');
  const desktopImageId = _get(state, 'selectedElement.desktop.background.imageId');

  if (state.mobilePreview) {
    imageId = mobileImageId || desktopImageId;
  } else {
    imageId = desktopImageId;
  }

  let image = null;
  if (imageId) {
    const images = state.images || state.template.images;
    image = images.find((r) => r._id.toString() === imageId);
  }
  return image;
};

const getPageForElement = (element, template) => {
  const page = template.elements.find((e) => e.uid === element.pageId);

  return page;
};

const getCanvasWidth = (element, template, device) => {
  const page = getPageForElement(element, template);
  let pageWidth = _get(page, `${device}.width`);

  if (device === 'mobile') {
    pageWidth = Math.round((360 * parseInt(pageWidth, 10)) / 100);
  }

  return pageWidth || 600;
};

export const isDefined = (data) => typeof data !== 'undefined' && data !== null;

const calcColumnWidth = ({ row, col, element, template, device }) => {
  if (!row || !col) return;

  const columnsOfRow = template.elements.filter(
    ({ rowId, type }) => type === 'OmCol' && rowId === row.uid,
  );
  const y = columnsOfRow.findIndex(({ uid }) => uid === _get(col, 'uid'));
  const maxWidth = _get(col, `${device}.maxWidth`);

  if (maxWidth) {
    let doc = document;

    if (!inIframe()) {
      const wrkspc = document.getElementById('workspaceFrame');
      doc = wrkspc.contentWindow.document;
    }

    const colEl = doc.getElementById(col.uid);

    if (colEl) {
      return colEl.clientWidth;
    }
    return 1;
  }
  const canvasWidth = getCanvasWidth(element, template, device);

  if (!isDefined(y) || device === 'mobile') return canvasWidth;
  return (canvasWidth / GRID_COLUMN_COUNT) * row.data.columnSplit[y];
};

const getHorizontalPadding = (padding) => {
  const left = parseInt(_get(padding, 'left'), 10) || 0;
  const right = parseInt(_get(padding, 'right'), 10) || 0;
  return left + right;
};

const containerWidthForElement = (element, template = undefined, device = 'desktop') => {
  const state = getState();
  template = template || state.template;

  const row = template.elements.find(({ uid }) => uid === element.rowId);
  const col = template.elements.find(({ uid }) => uid === element.colId);

  if (!row || !col) return 0;

  const rowPadding = parseInt(getHorizontalPadding(_get(row, `${device}.padding`)), 10);
  const columnPadding = parseInt(getHorizontalPadding(_get(col, `${device}.padding`)), 10);
  const horizontalPadding = rowPadding + columnPadding;

  const colWidth = calcColumnWidth({ row, col, element, template, device });

  const containerWidth = colWidth - horizontalPadding;

  return containerWidth;
};

const calcImageInitialWidth = (element) => {
  const image = selectedImage();
  const imageWidth = _get(image, 'width');
  const containerWidth = containerWidthForElement(element);

  if (!containerWidth) return parseInt(imageWidth, 10);

  const width = Math.round(Math.min(imageWidth, containerWidth));

  return width;
};

const getSelectedImageHeight = () => {
  const image = selectedImage();
  return _get(image, 'height');
};

const calcImageDimensions = ({ height, width, element, isNano }) => {
  const containerWidth = containerWidthForElement(element);

  const adjustedWidth = containerWidth;
  const widthRation = adjustedWidth / width;
  let adjustedHeight = Math.ceil(height * widthRation);
  if (isNano && adjustedHeight > NANOBAR_MAX_IMAGE_HEIGHT) {
    adjustedHeight = NANOBAR_MAX_IMAGE_HEIGHT;
  }

  return { adjustedHeight, adjustedWidth };
};

const disableLinkClicks = () => {
  // console.log('preventing defaults')
  const links = document.querySelectorAll('.om-element a');
  links.forEach((link) => {
    link.addEventListener('click', (e) => {
      e.preventDefault();
    });
  });
};

const addInputDefaultOptions = (inputs, selectedElement) => {
  const selectedElementOptions = _get(selectedElement, 'data.form.customSettings.options', []);

  return inputs.map((input) => {
    if (_i18n().locale === 'hu') {
      if (input.customId === 'firstname') {
        input.name = 'Keresztnév';
      } else if (input.customId === 'lastname') {
        input.name = 'Vezetéknév';
      }
    }

    return Object.assign(input, {
      options: selectedElementOptions,
    });
  });
};

const appendScript = (toEl, content) => {
  const script = document.createElement('script');
  if (script) {
    script.appendChild(document.createTextNode(content));
    if (toEl) {
      toEl.appendChild(script);
    }
  }
};

const isUrl = (url) => {
  try {
    // eslint-disable-next-line
    new URL(url);

    return true;
  } catch (e) {
    return false;
  }
};

const hasPath = (url) => {
  if (!isUrl(url)) return false;
  const parser = document.createElement('a');
  parser.href = url;
  return parser.pathname.length > 1;
};

const getBackgroundImageId = (element, backgroundPath) => {
  const background = _get(element, backgroundPath);
  if (['image', 'transparent'].includes(background?.type)) {
    return background?.imageId;
  }

  return null;
};

const collectElementsAllPage = (state) => {
  const inputs = [];
  const images = new Set();
  let imageId;
  let mobileImageId;

  imageId = getBackgroundImageId(state, 'template.style.tab.background');
  if (imageId) images.add(imageId);

  imageId = getBackgroundImageId(state, 'template.style.overlay.background');
  if (imageId) images.add(imageId);

  for (const e of state.template.elements) {
    imageId = getBackgroundImageId(e, 'desktop.background');
    if (imageId) images.add(imageId);

    mobileImageId = getBackgroundImageId(e, 'mobile.background');
    if (mobileImageId) images.add(mobileImageId);

    if (inputElements.includes(e.type) || e.type === 'OmInputs') {
      const customId = _get(e, 'data.form.customSettings.customId');
      const type = _get(e, 'data.form.customSettings.type');
      if (customId) {
        inputs.push({ customId, type });
      }
    }

    if (e.type === 'OmFeedback') {
      const options = _get(e, 'data.form.customSettings.options', []);
      options.forEach((option) => {
        const imageId = _get(option, 'image.imageId');
        if (imageId) {
          images.add(imageId);
        }
      });
    }

    if (e.type === 'OmProduct') {
      const imageId = getBackgroundImageId(e, 'subElements.button.desktop.background');
      if (imageId) {
        images.add(imageId);
      }
    }

    if (e.subElements) {
      // eslint-disable-next-line
      Object.keys(e.subElements).forEach((s) => {
        imageId = getBackgroundImageId(e.subElements[s], 'desktop.background');
        if (imageId) images.add(imageId);

        mobileImageId = getBackgroundImageId(e.subElements[s], 'mobile.background');
        if (mobileImageId) images.add(mobileImageId);
      });
    }
  }

  return { inputs, images: Array.from(images) };
};

export const translate = (path) => {
  const { locale, messages } = window.parent.om.i18n;
  const fullPath = `${locale}.${path}`;
  return _get(messages, fullPath, fullPath);
};

const getPaneByElementType = (element) => {
  const panesByElementType = {
    OmImage: 'ImagePane',
    OmFloatingImage: 'ImagePane',
    OmCoupon: 'CouponPane',
    OmDivider: 'DividerPane',
    OmCountdown: 'CountdownPane',
    OmLuckyWheel: 'LuckyWheelPane',
    OmButton: 'ButtonPane',
    OmVideo: 'VideoPane',
    OmInputs: 'InputsPane',
    OmInput: 'InputPane',
    OmDropdown: 'InputPane',
    OmTextarea: 'InputPane',
    OmRadio: 'InputPickerPane',
    OmCheckbox: 'InputPickerPane',
    OmSocial: 'SocialPane',
    OmScratchCard: 'ScratchCardPane',
    OmProduct: 'ProductPane',
    OmFeedback: 'FeedbackPane',
    OmCustomHTML: 'CustomHTMLPane',
    OmPickAPresent: 'PickAPresentPane',
    OmSpacer: 'SpacerPane',
    OmPage: 'PagePane',
    OmRow: 'RowPane',
    OmBlock: 'RowPane',
    OmCol: 'ColPane',
    OmSurvey: 'SurveyPane',
  };

  if (panesByElementType.hasOwnProperty(element.type)) {
    if (element.type === 'OmPage' && element.isTeaser) {
      return 'TeaserPane';
    }

    return panesByElementType[element.type];
  }
  return 'TextPane';
};

const getPageName = (pageIndex) => {
  return `${_i18n().t('page')} ${pageIndex}`;
};

const isInputType = (type) =>
  ['inputs', 'radio', 'checkbox', 'textarea', 'input', 'dropdown'].includes(type);

const getMobileFallbackValue = (property, globalProperty, nestedAccess) => {
  if (property.includes('.style.mobile')) {
    const desktopProperty = property.includes('.style.mobilePadding.')
      ? property.replace('mobilePadding', 'manualPadding')
      : property.replace('.mobile', '');
    const desktopValue = nestedAccess(desktopProperty);
    if (isDefined(desktopValue)) {
      globalProperty = desktopProperty;
    } else {
      globalProperty = property.includes('.style.mobilePadding.')
        ? globalProperty.replace('mobilePadding', 'manualPadding')
        : globalProperty.replace('.mobile', '');
    }
  }

  return globalProperty;
};

const websafeFonts = [{ family: 'Verdana, Geneva, sans-serif', key: 'verdana' }];

/* eslint-disable */
// prettier-ignore
const _slicedToArray = (function () { function sliceIterator (arr, i) { var _arr = []; var _n = true; var _d = false; var _e = undefined; try { for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break } } catch (err) { _d = true; _e = err } finally { try { if (!_n && _i.return) _i.return() } finally { if (_d) throw _e } } return _arr } return function (arr, i) { if (Array.isArray(arr)) { return arr } else if (Symbol.iterator in Object(arr)) { return sliceIterator(arr, i) } else { throw new TypeError('Invalid attempt to destructure non-iterable instance') } } }())
/* eslint-enable */

export const websafeFontListing = websafeFonts.map(({ family, key }) => ({
  key,
  family: family.split(',')[0],
}));

export const checkMultipleElementInRow = (selector, rowID) => {
  const rows = document.querySelectorAll(rowID || '[id^="row_"]');
  const result = [];
  if (rows && rows.length) {
    rows.forEach((row) => {
      const elements = row.querySelectorAll(selector);
      if (elements && elements.length) result.push(...elements);
    });
  }
  return result;
};

export const hasElWheel = (el) => {
  if (!window.om || !window.om.store.state.template || !window.om.store.state.template.elements)
    return false;
  const idProp = el.type === 'OmPage' ? 'pageId' : 'colId';
  const wheel = window.om.store.state.template.elements.find(
    (e) => e[idProp] === el.uid && e.type === 'OmLuckyWheel',
  );
  return !!wheel;
};

export const mapPopupPositionToTeaser = (position) => {
  if ([1, 2].includes(position)) return 'top-left';
  if (position === 3) return 'top-right';
  if ([4, 5, 7, 8].includes(position)) return 'bottom-left';
  if ([6, 9].includes(position)) return 'bottom-right';
};

export const isElementHiddenInView = (element, view) => {
  return _get(element, `${view}.hidden`, false);
};

export const generatePageId = () => `pge_${nanoid(9)}`;
export const generateRowId = () => `row_${nanoid(9)}`;
export const generateColId = () => `col_${nanoid(9)}`;
export const generateEleId = () => `ele_${nanoid(9)}`;
export const generateId = (type) => {
  switch (type) {
    case 'OmPage':
      return generatePageId();
    case 'OmRow':
      return generateRowId();
    case 'OmCol':
      return generateColId();
    default:
      return generateEleId();
  }
};

export const TEMPLATE_FEATURES = { NEW_TEASER: 'NEW_TEASER' };
export const isSvgUrl = (url) => url.indexOf('.svg') !== -1;

export const replaceSvgWithPaletteColors = ({ svg, palette }) => {
  return svg.replace(/(\.om_color_[1-5])[\s]{0,}{.*:(currentColor)(;)?/g, (matched) => {
    const number = parseInt(matched.replace(/.om_color_([1-5]).*/, '$1'), 10);
    const color = palette[number - 1];
    return matched.replace('currentColor', color);
  });
};

export const getInlineSvgBackgroundUrl = ({ svg, palette }) => {
  return `data:image/svg+xml,${encodeURIComponent(replaceSvgWithPaletteColors({ svg, palette }))}`;
};

export const generateStylesheetContent = (palette, scope) => {
  const content = [];
  palette.forEach((color, i) => {
    content.push(`${scope}{--om-color-${i}:${color}}\n`);
    // if (!i) content.textContent += `${scope} .om-color-palette-main{color:${color}}\n`
    content.push(`${scope} .om_color_${i + 1}{color:${color}}\n`);
  });
  return content.length ? content.join('') : '';
};

export const generatePalette = (styleElement, palette, scope) => {
  if (styleElement) {
    const content = document.createTextNode(generateStylesheetContent(palette, scope));
    if (styleElement.firstChild) {
      styleElement.replaceChild(content, styleElement.firstChild);
    } else {
      styleElement.appendChild(content);
    }
  }
};

export const createPaletteStyleElement = (wrapperEl, id) => {
  let styleEl;

  if (wrapperEl) {
    styleEl = wrapperEl.querySelector(`#${id}`);
    if (!styleEl) {
      styleEl = document.createElement('style');
      styleEl.id = id;
      wrapperEl.appendChild(styleEl);
    }
  }

  return styleEl;
};

export const isNano = (mode) => mode === 'nano';

export const getCustomJsByEvent = (customJsByEvents) => {
  return Object.keys(customJsByEvents).filter((event) => customJsByEvents[event].length);
};

export const getSelectedColor = ({ style }, element) => {
  const { mainColor, secondaryColors, themeColors } = style.palette;
  const selectedColorIndex = element.desktop.color;
  const paletteColors = [
    mainColor,
    ...secondaryColors,
    ...(Array.isArray(themeColors) ? themeColors : []),
  ];
  return (
    (paletteColors[selectedColorIndex]?.themeColor || paletteColors[selectedColorIndex]) ??
    selectedColorIndex
  );
};

export {
  GRID_COLUMN_COUNT,
  addInputDefaultOptions,
  calcColumnWidth,
  containerWidthForElement,
  calcImageDimensions,
  disableLinkClicks,
  getCanvasWidth,
  getQueryVariable,
  findAncestor,
  hasPath,
  isUrl,
  appendScript,
  collectElementsAllPage,
  getPaneByElementType,
  getPageName,
  isInputType,
  getMobileFallbackValue,
  websafeFonts,
  _slicedToArray,
  selectedImage,
  calcImageInitialWidth,
  getHorizontalPadding,
  getSelectedImageHeight,
};
