import OmComponent from './OmComponent';
import {
  Base,
  DesktopBackground,
  DesktopBorder,
  MobileBorder,
  DesktopShadow,
  MobileShadow,
} from '../coloring';

export default class OmSocial extends OmComponent {
  constructor(elements, property, palette) {
    super(elements, palette);

    switch (property) {
      case 'desktop.background.color':
      case 'desktop.background.color2':
        return this.createColorInstance(DesktopBackground);

      case 'desktop.border.color':
        return this.createColorInstance(DesktopBorder);

      case 'mobile.border.color':
        return this.createColorInstance(MobileBorder);

      case 'desktop.shadow.color':
        return this.createColorInstance(DesktopShadow);

      case 'mobile.shadow.color':
        return this.createColorInstance(MobileShadow);

      case 'link.color':
        return this.createColorInstance(Base).setBaseProperty('desktop.link');

      default:
        throw new Error(`OmSocial: unknown property ${property}`);
    }
  }
}
