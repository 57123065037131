/**

STORE/RETRIEVE DATA IN/FROM SESSIONSTORAGE

ssStore
  store data in sessionStorage

  arguments:
    - name: key in storage, required
    - value: any valid JS value, required
    - stringify: need to stringify the given value, default: true

ssRetrieve
  get data from sessionStorage,
  if it was stringified then the parsed value will be returned,
  otherwise string or null if the key not exists.

  arguments:
    - name: key in storage, required
*/

import { store, retrieve, remove, STORAGE_SESSION } from './browserStorage';

const ssStore = (name, value, stringify = true) => store(STORAGE_SESSION, name, value, stringify);

const ssRetrieve = (name) => retrieve(STORAGE_SESSION, name);

const ssRemove = (name) => remove(STORAGE_SESSION, name);

export { ssStore, ssRetrieve, ssRemove };
