import { DEFAULT_COLOR_PARAMS } from '../helper';
import Base from './Base';

export default class MobileBorder extends Base {
  constructor(element, palette) {
    super(element, palette);
    this.baseProperty = 'mobile.border';
  }

  getColorProperty() {
    return this.getDesktopPropertyIfMobileBorderNotSelected(DEFAULT_COLOR_PARAMS.COLOR);
  }

  getDesktopPropertyIfMobileBorderNotSelected(param) {
    const mobileTypeProperty = `${this.baseProperty}.${DEFAULT_COLOR_PARAMS.SELECTED_BORDER}`;
    const mobileTypeValue = this.getValueByProperty(mobileTypeProperty);

    if (['no', null].includes(mobileTypeValue)) {
      return `${this.getDesktopBaseProperty()}.${param}`;
    }
    return `${this.baseProperty}.${param}`;
  }
}
