import Background from '@/utils/color-components/coloring/Background';
import OmComponent from './OmComponent';
import {
  Base,
  DesktopBackground,
  DesktopBorder,
  DesktopShadow,
  MobileBackground,
  MobileBorder,
  MobileShadow,
} from '../coloring';

export default class OmProduct extends OmComponent {
  constructor(elements, property, palette) {
    super(elements, palette);

    switch (property) {
      case 'desktop.background.color':
      case 'desktop.background.color2':
        return this.createColorInstance(DesktopBackground);

      case 'mobile.background.color':
      case 'mobile.background.color2':
        return this.createColorInstance(MobileBackground);

      case 'desktop.border.color':
        return this.createColorInstance(DesktopBorder);

      case 'mobile.border.color':
        return this.createColorInstance(MobileBorder);

      case 'desktop.shadow.color':
        return this.createColorInstance(DesktopShadow);

      case 'mobile.shadow.color':
        return this.createColorInstance(MobileShadow);

      case 'subElements.image.desktop.border.color':
        return this.createColorInstance(DesktopBorder).setBaseProperty(
          'subElements.image.desktop.border',
        );

      case 'subElements.image.mobile.border.color':
        return this.createColorInstance(MobileBorder).setBaseProperty(
          'subElements.image.mobile.border',
        );

      case 'subElements.image.desktop.shadow.color':
        return this.createColorInstance(DesktopBorder).setBaseProperty(
          'subElements.image.desktop.shadow',
        );

      case 'subElements.image.mobile.shadow.color':
        return this.createColorInstance(MobileBorder).setBaseProperty(
          'subElements.image.mobile.shadow',
        );

      case 'subElements.button.desktop.border.color':
        return this.createColorInstance(DesktopBorder).setBaseProperty(
          'subElements.button.desktop.border',
        );

      case 'subElements.button.mobile.border.color':
        return this.createColorInstance(MobileBorder).setBaseProperty(
          'subElements.button.mobile.border',
        );

      case 'subElements.button.desktop.hover.fontColor':
        return this.createColorInstance(Base)
          .setBaseProperty('subElements.button.desktop.hover')
          .setColorParam('fontColor');

      case 'subElements.button.desktop.hover.borderColor':
        return this.createColorInstance(Base)
          .setBaseProperty('subElements.button.desktop.hover')
          .setColorParam('borderColor');

      case 'subElements.button.desktop.hover.background.color':
        return this.createColorInstance(Background).setBaseProperty(
          'subElements.button.desktop.hover.background',
        );

      case 'subElements.button.desktop.background.color':
        return this.createColorInstance(DesktopBackground).setBaseProperty(
          'subElements.button.desktop.background',
        );

      case 'subElements.button.mobile.background.color':
        return this.createColorInstance(MobileBackground).setBaseProperty(
          'subElements.button.mobile.background',
        );

      case 'subElements.button.desktop.shadow.color':
      case 'subElements.button.mobile.shadow.color':
      case 'subElements.buttonText.desktop.color':
      case 'subElements.buttonText.mobile.color':
      case 'subElements.nameText.desktop.color':
      case 'subElements.nameText.mobile.color':
      case 'subElements.skuText.desktop.color':
      case 'subElements.skuText.mobile.color':
      case 'subElements.priceText.desktop.color':
      case 'subElements.priceText.mobile.color':
      case 'subElements.oldPriceText.desktop.color':
      case 'subElements.oldPriceText.mobile.color':
        // eslint-disable-next-line no-case-declarations
        const baseProperty = property.split('.').slice(0, -1).join('.');
        return this.createColorInstance(Base).setBaseProperty(baseProperty);

      default:
        throw new Error(`OmProduct: unknown property ${property}`);
    }
  }
}
