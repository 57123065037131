import runtimeConfig from './config/runtime';

const assetPrefix = runtimeConfig.ASSET_PREFIX;

/* eslint-disable camelcase,no-undef */
if (assetPrefix) {
  __webpack_public_path__ = assetPrefix;
} else if (__webpack_public_path__.startsWith('{{ASSET_PREFIX}}')) {
  __webpack_public_path__ = '/';
}
/* eslint-enable camelcase,no-undef */
