let pickerEl;

export const destroyPickerFontSearch = () => {
  if (!pickerEl) return;
  const optionsEl = pickerEl.querySelector('.ql-picker-options');
  if (!optionsEl) return;
  const inputEl = optionsEl.querySelector('.ql-picker-font-search input');
  const emptyEl = pickerEl.querySelector('.ql-picker-font-search-empty');
  const itemEls = pickerEl.querySelectorAll('.ql-picker-item');
  if (emptyEl) {
    optionsEl.removeChild(emptyEl);
  }
  if (inputEl) {
    inputEl.value = '';
  }
  for (let i = 0; i < itemEls.length; i += 1) {
    const itemEl = itemEls[i];
    itemEl.style.display = '';
    itemEl.removeAttribute('hidden');
  }
  optionsEl.style.height = '';
};

export const createPickerFontSearch = (el) => {
  if (pickerEl) destroyPickerFontSearch();
  pickerEl = el;
  if (!el) return;
  const optionsEl = pickerEl.querySelector('.ql-picker-options');
  if (!optionsEl) return;

  const labelEl = optionsEl.querySelector('.ql-picker-item[data-label]:not([data-value])');
  if (labelEl) {
    labelEl.parentNode.removeChild(labelEl);
  }

  const fontSearchEl = optionsEl.querySelector('.ql-picker-font-search');
  let inputEl = optionsEl.querySelector('.ql-picker-font-search input');

  if (fontSearchEl) {
    if (inputEl) inputEl.focus();
    return;
  }
  if (optionsEl.classList.contains('ql-picker-options-top')) {
    optionsEl.style.height = `${optionsEl.clientHeight}px`;
  }

  optionsEl.insertAdjacentHTML(
    'afterbegin',
    /* html */ `
    <span class="ql-picker-font-search">
      <input type="text" placeholder="Search font" />
    </span>
  `,
  );

  inputEl = optionsEl.querySelector('.ql-picker-font-search input');

  const filterItems = (query) => {
    const itemEls = pickerEl.querySelectorAll('.ql-picker-item');
    for (let i = 0; i < itemEls.length; i += 1) {
      const itemEl = itemEls[i];
      if (!query.trim().length) {
        itemEl.style.display = '';
        itemEl.removeAttribute('hidden');
        continue;
      }
      const label = itemEl.getAttribute('data-label');
      const value = itemEl.getAttribute('data-value');
      const labelInQuery = label.toLowerCase().indexOf(query.toLowerCase()) >= 0;
      if (!value || !label || !labelInQuery) {
        itemEl.style.display = 'none';
        itemEl.setAttribute('hidden', '');
      } else if (labelInQuery) {
        itemEl.style.display = '';
        itemEl.removeAttribute('hidden');
      }
    }
    const hiddenItemEls = pickerEl.querySelectorAll('.ql-picker-item[hidden]');
    const emptyEl = pickerEl.querySelector('.ql-picker-font-search-empty');
    if (emptyEl) {
      optionsEl.removeChild(emptyEl);
    }
    if (hiddenItemEls.length === itemEls.length) {
      // all hidden
      optionsEl.insertAdjacentHTML(
        'beforeend',
        /* html */ `
        <span tabindex="-1" class="ql-picker-font-search-empty">No fonts found</span>
      `,
      );
    }
  };

  const onInputClick = (e) => {
    e.stopPropagation();
  };
  const onInput = (e) => {
    filterItems(e.target.value);
  };
  inputEl.addEventListener('input', onInput);
  inputEl.addEventListener('click', onInputClick);
  inputEl.focus();
};
