export const groups = {
  personal_data: ['email', 'first_name', 'last_name'],
  product_and_cart: [
    'cart_total',
    'cart_num_items',
    'cart_num_item_kinds',
    'product_name',
    'product_category',
    'cart_value_countdown',
  ],
  location_and_time: [
    'tz_offset',
    'country_de',
    'country_en',
    'country_hu',
    'curr_month_en',
    'curr_month_hu',
  ],
  system: ['browser_name', 'os_name'],
  history: ['landing_page', 'utm_source', 'utm_medium', 'utm_campaign', 'utm_content'],
};

export const groupWithSPPO = {
  ...groups,
  smart_product_tags: [],
};
