import runtimeConfig from '@/config/runtime';
import axios from 'axios';

const SALES_DOMAIN = runtimeConfig.VUE_APP_SALES_DOMAIN || '';

class SalesAdapter {
  constructor(authData) {
    this.authData = authData;
  }

  convertOrderData(data) {
    let query = [
      `payment[lastname]=${data.firstName}`,
      `payment[firstname]=${data.lastName}`,
      `payment[country_id]=${data.country}`,
      `payment[company]=${data.billingName}`,
      `payment[city]=${data.city}`,
      `payment[address_1]=${data.address}`,
      `payment[postcode]=${data.postalCode}`,
      `payment[eu_vat_number]=${data.euVatNumber}`,
      `payment_method=${data.paymentMethod}`,
      `selected_package=${data.plan.toUpperCase()}`,
      `selected_period=${data.period}`,
      'form_type=guest',
    ];

    if (data.paymentNonce) {
      query.push(`payment_method_nonce=${data.paymentNonce}`);
    }

    query = query.map((element) => {
      return encodeURI(element);
    });

    return query.join('&');
  }

  sendCreateOrder(data) {
    const url = this.buildCheckoutUrl('setOrder');
    return SalesAdapter.send(url, this.convertOrderData(data));
  }

  getSelectedPlanAndPeriod() {
    const url = this.buildCheckoutUrl('getPackageAndPeriod');

    return SalesAdapter.get(url);
  }

  buildCheckoutUrl(resource) {
    return `${SALES_DOMAIN}/index.php?route=checkout/checkout2/${resource}&id=${this.authData.id}&hash=${this.authData.hash}`;
  }

  sendConfirmOrder(paymentMethod) {
    const url = this.confirmUrl(paymentMethod);
    return SalesAdapter.send(url, {});
  }

  cancelSubscription(reason) {
    const url = this.braintreeUrl('cancel2');

    return SalesAdapter.send(url, encodeURI(`reason=${reason}`));
  }

  keepSubscription(reason, solution) {
    const url = this.braintreeUrl('keepSubscription');

    return SalesAdapter.send(url, encodeURI(`reason=${reason}&solution=${solution}`));
  }

  // TODO[Dzsi][coupon] Ez használva van valahol?
  sendShopifyPay(plan, period) {
    const url = this.confirmUrl('shopify');

    return SalesAdapter.send(
      url,
      encodeURI(`selected_package=${plan.toUpperCase()}&selected_period=${period}`),
    );
  }

  sendShopifyReturn(chargeId) {
    const url = `${SALES_DOMAIN}/index.php?route=payment/shopify/return2&charge_id=${chargeId}&id=${this.authData.id}&hash=${this.authData.hash}`;
    return SalesAdapter.send(url);
  }

  sendCustomShopifyConfirm(type) {
    const url = `${SALES_DOMAIN}/index.php?route=account/shopifypayment/confirm2&type=${type}&id=${this.authData.id}&hash=${this.authData.hash}`;
    return SalesAdapter.send(url);
  }

  sendCustomShopifyReturn(type, chargeId) {
    const url = `${SALES_DOMAIN}/index.php?route=account/shopifypayment/return2&charge_id=${chargeId}&type=${type}&id=${this.authData.id}&hash=${this.authData.hash}`;
    return SalesAdapter.send(url);
  }

  sendShopifyOneTimeReturn(type, chargeId, cphash) {
    const url = `${SALES_DOMAIN}/index.php?route=account/shopifypayment/returnOneTime&charge_id=${chargeId}&type=${type}&id=${this.authData.id}&hash=${this.authData.hash}&cphash=${cphash}`;
    return SalesAdapter.send(url);
  }

  getAffiliateData() {
    const url = this.affiliateUrl();
    return SalesAdapter.get(url);
  }

  confirmUrl(payment) {
    return `${SALES_DOMAIN}/index.php?route=payment/${payment}/confirm2&id=${this.authData.id}&hash=${this.authData.hash}`;
  }

  braintreeUrl(resource) {
    return `${SALES_DOMAIN}/index.php?route=payment/braintree${resource ? `/${resource}` : ''}&id=${
      this.authData.id
    }&hash=${this.authData.hash}`;
  }

  getInvoiceUrl(orderId) {
    return `${SALES_DOMAIN}/index.php?route=account/history/receipt2&order_id=${orderId}&id=${this.authData.id}&hash=${this.authData.hash}`;
  }

  affiliateUrl() {
    return `${SALES_DOMAIN}/index.php?route=account/aaffiliate2&id=${this.authData.id}&hash=${this.authData.hash}`;
  }

  static sendLogout() {
    const url = SalesAdapter.accountUrl('logoutjson&jsonCallback');

    return SalesAdapter.send(url, {});
  }

  static accountUrl(resource) {
    return `${SALES_DOMAIN}/index.php?route=account/${resource}`;
  }

  static get(url, options = {}) {
    if (!SALES_DOMAIN) {
      let data = {};
      if (url.indexOf('account/aafiliate2')) {
        data = {
          main: {
            referred_orders: 0,
            referred_subscribers: 0,
            referred_customers: 0,
            referred_visitors: 0,
            month_referred_orders: 0,
            month_referred_subscribers: 0,
            month_referred_customers: 0,
            month_referred_visitors: 0,
            week_referred_orders: 0,
            week_referred_subscribers: 0,
            week_referred_customers: 0,
            week_referred_visitors: 0,
            referred_commissions: '$ 0.00',
            month_referred_commissions: '$ 0.00',
            week_referred_commissions: '$ 0.00',
            total_all: '$ 0.00',
            total_all_payout: '$ 0.00',
            total_actual_all: '$ 0.00',
            total_actual_depend: '$ 0.00',
            total_actual_can_payout: '$ 0.00',
            total_actual_do_payout: '$ 0.00',
            payout_max_total: 0,
          },
          partner_data: {
            name: ' ',
            email: 'normalreg13@gmail.com',
            telephone: '',
            aname: ' ',
            address: 'asdasdd adasdsad ',
            city: 'New York?',
            postcode: '1232',
            code: 68219,
          },
          commissions: [
            {
              commission_id: '1',
              name: '15% os alapértelmezett jutalék',
              source_type: 'rendelés',
              total_type: 'százalékos',
              product_count: 'összes',
              auto: 'alapértelmezett',
              total: '15%',
              selected: false,
              action: [],
              front_product_list_action: '',
            },
          ],
          subscribers: null,
          tools: [
            {
              tool_id: '1',
              name: 'HU-OptiMonk affiliate link',
              type: 'link',
              url: 'http://www.optimonk.hu',
              auto: 'egyedi',
              selected: false,
              action: [],
              preview:
                '<a href="http://www.optimonk.hu/?p_id=68219" title="" target="_blank">[link]</a>',
              code: '<a href="http://www.optimonk.hu/?p_id=68219" title="" target="_blank">[link]</a>',
              link: 'http://www.optimonk.hu/?p_id=68219',
            },
          ],
        };
      }

      return new Promise((resolve) => {
        console.log(`Sales adapter debug: url:${url}`);
        resolve({ data });
      });
    }

    return axios.get(url, { withCredentials: true, ...options });
  }

  static send(url, data) {
    if (!SALES_DOMAIN) {
      return new Promise((resolve) => {
        console.log(`Sales adapter debug: url:${url}`, 'data:', data);
        resolve();
      });
    }

    return axios.post(url, data, {
      headers: {
        'content-type': 'application/x-www-form-urlencoded',
      },
      withCredentials: true,
    });
  }
}

export { SalesAdapter };
