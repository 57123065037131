const NO_TYPES = {
  NO_TOP: 'NO_TOP',
  NO_BOTTOM: 'NO_BOTTOM',
  NO_LEFT: 'NO_LEFT',
  NO_RIGHT: 'NO_RIGHT',
  NONE: 'NONE',
  NO_TOP_AND_LEFT: 'NO_TOP_AND_LEFT',
  NO_TOP_AND_RIGHT: 'NO_TOP_AND_RIGHT',
  NO_BOTTOM_AND_LEFT: 'NO_BOTTOM_AND_LEFT',
  NO_BOTTOM_AND_RIGHT: 'NO_BOTTOM_AND_RIGHT',
  NO_TOP_MOBILE: 'NO_TOP_MOBILE',
  NO_BOTTOM_MOBILE: 'NO_BOTTOM_MOBILE',
  NO_LEFT_MOBILE: 'NO_LEFT_MOBILE',
  NO_RIGHT_MOBILE: 'NO_RIGHT_MOBILE',
  NONE_MOBILE: 'NONE_MOBILE',
};

const GROUP_NAMES = {
  NONE: 'none',
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
};

const GROUPS = {
  [GROUP_NAMES.SMALL]: 8,
  [GROUP_NAMES.MEDIUM]: 16,
  [GROUP_NAMES.LARGE]: 32,
};

const EXCEPTIONS = {
  [NO_TYPES.NO_TOP]: [null, null, true, true],
  [NO_TYPES.NO_BOTTOM]: [true, true, null, null],
  [NO_TYPES.NO_LEFT]: [null, true, true, null],
  [NO_TYPES.NO_RIGHT]: [true, null, null, true],
  [NO_TYPES.NONE]: [null, null, null, null],
  [NO_TYPES.NO_TOP_AND_LEFT]: [null, null, true, null],
  [NO_TYPES.NO_TOP_AND_RIGHT]: [null, null, null, true],
  [NO_TYPES.NO_BOTTOM_AND_LEFT]: [null, true, null, null],
  [NO_TYPES.NO_BOTTOM_AND_RIGHT]: [true, null, null, null],
  [NO_TYPES.NO_TOP_MOBILE]: [null, null, true, true],
  [NO_TYPES.NO_BOTTOM_MOBILE]: [true, true, null, null],
  [NO_TYPES.NO_LEFT_MOBILE]: [null, true, true, null],
  [NO_TYPES.NO_RIGHT_MOBILE]: [true, null, null, true],
  [NO_TYPES.NONE_MOBILE]: [null, null, null, null],
};

module.exports = { NO_TYPES, GROUP_NAMES, GROUPS, EXCEPTIONS };
