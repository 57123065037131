import { apolloClient } from '@/apollo';
import STORE_PRODUCT_TOUR_STATUS from '@/graphql/StoreProductTourStatus.gql';
import { track } from '@/services/xray';
import { init as initEditorFlow, next as nextInEditorFlow } from '@/utils/productTours/editor';

const baseDriverConfig = {
  allowClose: false,
  showButtons: false,
  scrollIntoViewOptions: { block: 'end' },
};

export default {
  namespaced: true,
  state: {
    driver: null,
    isNewTeaser: false,
    teaserPosition: 'top-left',
    current: 1,
    inEditor: false,
    name: null,
  },
  mutations: {
    reset(state, isUniversal) {
      if (isUniversal && state.inEditor) {
        window.om.bus.$emit('show-left-sidebar-content', {
          buttonIndex: 0,
          withoutCloseCase: true,
        });
      }
      if (state.driver) state.driver.reset();
    },
    next(state) {
      state.driver.moveNext();
      state.current++;
    },
    previous(state) {
      state.driver.movePrevious();
      state.current--;
    },
    setName(state, name) {
      state.name = name;
    },
  },
  getters: {
    isUniversal: (_, __, rootState) => {
      return rootState.universal;
    },
  },
  actions: {
    async init(
      { state, commit, getters, dispatch, rootGetters },
      { name = 'unnamed', driverConfigs = {} } = {},
    ) {
      const [{ default: Driver }] = await Promise.all([
        import('driver.js'),
        import('driver.js/dist/driver.min.css'),
        import('@/utils/LottiePlayer'),
      ]);
      const driverConfig = {
        ...baseDriverConfig,
        ...driverConfigs,
      };
      const driver = new Driver(driverConfig);
      state.driver = driver;
      state.inEditor = window.location.href.includes('/edit');

      if (name) state.name = name;

      if (state.inEditor) {
        initEditorFlow({ commit, state, dispatch, getters, rootGetters });
      }
    },
    reset({ commit, state, rootState }) {
      const { databaseId } = rootState.account;
      const highlightedElement = state.driver.getHighlightedElement();
      track('ProductTour', {
        action: 'close',
        databaseId,
        experiment: state.experiment,
        step: highlightedElement.popover.options.className,
      });
      if (state.inEditor) {
        document.querySelector('.sidebar').classList.remove('driver-highlighted-element');
      }
      commit('reset', rootState.universal);
    },
    next({ commit, dispatch, state, getters, rootState, rootGetters }) {
      const context = { state, dispatch, getters, rootState, rootGetters };
      if (state.inEditor) {
        nextInEditorFlow(context);
      }
      commit('next');
    },
    previous({ commit }) {
      commit('previous');
    },
    close({ state, commit, dispatch, rootState }) {
      if (state.driver && state.name) {
        dispatch('storeStatus', { status: 'closed' });
      }
      commit('reset', rootState.universal);
    },
    finish({ commit, dispatch, rootState }) {
      dispatch('storeStatus', { status: 'finished' });
      commit('reset', rootState.universal);
    },
    hide({ commit, rootState }) {
      commit('reset', rootState.universal);
    },
    storeStatus({ state }, { status, tour }) {
      const tourName = tour || state.name;
      if (status && tourName) {
        apolloClient.mutate({
          mutation: STORE_PRODUCT_TOUR_STATUS,
          variables: { status, tour: tourName },
        });
      }
    },
    defineSteps({ state }, steps) {
      state.driver.defineSteps(steps);
    },
    start({ state, dispatch }) {
      if (state.driver) {
        dispatch('storeStatus', { status: 'started' });
        state.driver.start();
      }
    },
  },
};
