import { mapState, mapGetters } from 'vuex';
import { TEMPLATE_FEATURES } from '@/editor/util';
import itemMixin from '@/editor/mixins/item';

export default {
  mixins: [itemMixin],
  computed: {
    ...mapState(['teaserPreview']),
    ...mapGetters(['hasTemplateFeature', 'teaserPage']),
    isNewTeaser() {
      return this.hasTemplateFeature(TEMPLATE_FEATURES.NEW_TEASER) && !this.teaserPreview;
    },
    isTeaserEnabled() {
      const { before, after } = this.isNewTeaser
        ? this.teaserPage.data.display
        : this.getValueOf('globalStyle.tab.display');

      return before || after;
    },
  },
  methods: {
    transformedTeaser(viewPosition) {
      return viewPosition === 'left-center' || viewPosition === 'right-center';
    },
    calculatePageCoordinate(e, viewPosition) {
      return this.transformedTeaser(viewPosition) ? e.pageX : e.pageY;
    },
  },
};
