import getUniconName from '@/components/Elements/mixins/getUniconName';

export default {
  props: {
    icon: {
      type: String,
      default: null,
    },
    iconSize: {
      type: String,
      default: '1.715em',
    },
  },
  computed: {
    iconComponent() {
      return getUniconName(this.icon);
    },
  },
};
