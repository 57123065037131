import router from '@/router/index';
import store from '@/store';
import { getAccountIdFromCookie } from '@/util';
import { redirectToOnboardingBranching } from '@/router/routes/deletedroutes';
import { accountSetupRouteNames } from '@/router/routes/accountSetupRoutes';

const isMobileStage = (stage) => {
  if (stage === '') {
    return false;
  }
  return (stage || '').includes('/mobile-onboarding/');
};

function transpileMobileRouteToDesktop(userId, stage) {
  if (stage.includes('/welcome')) {
    return { name: accountSetupRouteNames.WELCOME, params: { userId } };
  }
  if (/\d\/mobile-onboarding\/(interested-feature|hear)/.test(stage)) {
    return redirectToOnboardingBranching({});
  }
  if (stage.includes('/industry') || stage.includes('contact-info')) {
    return { name: accountSetupRouteNames.QUALIFICATIONS, params: { userId } };
  }
  return { name: 'templates', params: { userId } };
}

/**
 *
 * Therefore, in this case the startLocation should be checked.
 * @returns {Promise<Route> | undefined}
 */
async function showUnfinishedOnboarding() {
  const userId = getAccountIdFromCookie();
  const {
    account: {
      type,
      settings: { onboarding = {} },
    },
    impersonate,
  } = store.state;
  const { stage, finished } = onboarding;
  if (['agency', 'sub'].includes(type) || impersonate || finished) {
    return false;
  }

  const realStage = !stage ? `/${userId}/onboarding/welcome` : stage;
  const isStageMobile = isMobileStage(stage);

  // if the device is desktop
  if (!store.getters.isMobile) {
    if (router.currentRoute.path.includes(`/${userId}/onboarding/`)) {
      return true;
    }
    if (isStageMobile) {
      // get desktop route based on the mobile
      const to = transpileMobileRouteToDesktop(userId, realStage);
      return router.replace(to);
    }
    if (realStage.includes('/onboarding/wizard')) {
      return router.replace({ path: realStage });
    }
    return router.replace({ name: 'onboarding_check', params: { userId } });
  }
  // if the device is mobile
  const currentRoute = router.history.current;
  const fromDesktopRedirectEmail =
    currentRoute.name === 'new_campaign' &&
    currentRoute.query.templatePreview &&
    currentRoute.query.templatePreview.length;
  if (fromDesktopRedirectEmail) {
    return router.replace({ name: 'still_on_mobile' });
  }
  if (currentRoute.name !== 'mobile_redirect') {
    return router.replace({ name: 'mobile_redirect' });
  }
}

export { showUnfinishedOnboarding };
